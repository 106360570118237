<template>
  <label class="dropdown-selector">
    <div>
      <div>
        <plain-button
          class="dropdown-single-select"
          :class="{'small': small}"
          @click="toggleDropdown">
          <div
            v-if="dropdownOpen || !selectedOption"
            class="selected-option">
            {{ placeholder }}
          </div>
          <slot
            v-else
            name="selectedOptionLabel"
            :selected-option="selectedOption.value">
            <div class="selected-option">
              {{ selectedOption.text }}
            </div>
          </slot>
          <input
            ref="selectedOption"
            :name="inputName"
            :value="selectedOption"
            type="hidden">
          <svg-chevron-down
            v-if="!dropdownOpen"
            class="ms-2"
            height="20"/>
          <div v-else>
            <div v-if="$slots['openDropdownHeader']">
              <slot
                name="openDropdownHeader"
                class="small-text text-pewter"/>
            </div>
            <svg-chevron-up
              v-else
              class="ms-2"
              height="20"/>
          </div>
        </plain-button>
      </div>
      <ul
        v-if="dropdownOpen"
        ref="dropdownList"
        tabindex="-1"
        :class="{ 'small': small }"
        class="dropdown-list">
        <li
          v-for="(dropdownOption, idx) in dropdownOptions"
          :key="idx"
          :ref="`dropdownListItems`"
          tabindex="-1"
          class="dropdown-list-item"
          :class="{
            'disabled': dropdownOption.disabled,
            'hovered': pointer === idx,
            'bordered': idx < dropdownOptions.length - 1,
            'small': small
          }"
          @keydown.enter="setSelectedIndex()"
          @blur="handleClickOutside($event)"
          @click.prevent="setSelectedIndex(idx)">
          <!-- Parent can pass custom rendering of option, or we default to passed label -->
          <slot
            name="dropdownOptionLabel"
            :dropdown-option="dropdownOption.value">
            {{ dropdownOption.text }}
          </slot>
        </li>

      </ul>
      <plain-button
        v-if="dropdownOpen && canClear && modelValue !== null"
        :class="{
          'hovered': pointer === dropdownOptions.length,
          'small': small
        }"
        class="dropdown-list-item clear-button fine-print"
        @click="clearSelection">
        Clear selection
      </plain-button>
    </div>
  </label>
</template>

<script>
import PlainButton from './PlainButton'
import SvgChevronDown from '@/components/global/svg/SvgChevronDown.vue'
import SvgChevronUp from '@/components/global/svg/SvgChevronUp.vue'

export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  },
  components: {
    PlainButton,
    SvgChevronDown,
    SvgChevronUp
  },
  props: {
    modelValue: {
      type: [String, Number, Object, Boolean, null],
      default: null
    },
    inputName: {
      type: String,
      default: ''
    },
    // dropdown options should each be object with these fields:
    // text - what is rendered in the dropdown if no custom slot passed
    // value - value passed back to parent component when selected
    // disabled - optional, pass if value should be shown but not selected
    dropdownOptions: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: 'Select one'
    },
    initialSelectionIndex: {
      type: Number,
      default: null
    },
    small: {
      type: Boolean,
      default: false
    },
    validations: {
      type: Object,
      default: () => {
        return { $touch () {} }
      }
    },
    canClear: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedIndex: null,
      dropdownOpen: false,
      pointer: -1
    }
  },
  computed: {
    selectedOption () {
      return this.dropdownOptions && this.selectedIndex !== null ? this.dropdownOptions[this.selectedIndex] : null
    }
  },
  watch: {
    modelValue (curr) {
      this.setSelectedOption(curr)
    }
  },
  mounted () {
    document.addEventListener('click', this.handleClickOutside)

    if (this.initialSelectionIndex != null) {
      this.selectedIndex = this.initialSelectionIndex
    }
  },
  activated () {
    document.addEventListener('click', this.handleClickOutside)
  },
  deactivated () {
    document.removeEventListener('click', this.handleClickOutside)
  },
  unmounted () {
    document.removeEventListener('click', this.handleClickOutside)
  },
  methods: {
    setPointerIdx (idx) {
      this.pointer = idx
    },
    toggleDropdown () {
      this.dropdownOpen = !this.dropdownOpen
      if (this.dropdownOpen) {
        this.$nextTick(() => {
          window.addEventListener('keydown', this.handleKeyInput)
        })
      } else {
        this.$refs.selectedOption.focus()
        window.removeEventListener('keydown', this.handleKeyInput)
      }
    },
    closeOut () {
      this.dropdownOpen = false
      this.selectedIndex = null
    },
    movePointerDown () {
      if (this.pointer >= this.dropdownOptions.length - 1) return
      this.pointer++
      this.$nextTick(() => { this.$refs.dropdownListItems[this.pointer].focus() })
    },
    movePointerUp () {
      if (this.pointer > 0) {
        this.pointer--
        this.$nextTick(() => { this.$refs.dropdownListItems[this.pointer].focus() })
      }
    },
    setSelectedOption (val) {
      if (val === null || val === '') {
        this.selectedIndex = -1
      } else {
        this.selectedIndex = this.dropdownOptions.map(x => x.value).indexOf(val)
        this.$emit('update:modelValue', this.selectedOption.value)
      }
    },
    setSelectedIndex (indexOverride) {
      if (!this.dropdownOptions || !this.dropdownOptions.length) return

      const index = indexOverride != null ? indexOverride : this.pointer
      const dropdownOption = this.dropdownOptions[index]
      if (dropdownOption.disabled) return

      this.selectedIndex = index
      if (this.dropdownOpen) this.toggleDropdown()
      this.pointer = -1
      this.$emit('update:modelValue', this.selectedOption.value)
      this.$nextTick().then(() => {
        this.$refs.selectedOption.focus()
      })
    },
    clearSelection () {
      this.selectedIndex = null
      this.$emit('update:modelValue', null)
      if (this.dropdownOpen) this.toggleDropdown()
    },
    handleClickOutside (e) {
      if (this.$el.contains(e.target)) return
      this.dropdownOpen = false
    },
    handleKeyInput (event) {
      if (!event) return
      if (event.key === 'ArrowDown') {
        event.preventDefault()
        this.movePointerDown()
      }
      if (event.key === 'ArrowUp') {
        event.preventDefault()
        this.movePointerUp()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$defaultPadding: 12px 16px;
$smallPadding: 8px 8px 8px 12px;

.dropdown-selector {
  min-width: 150px;
  position: relative;
}

.dropdown-single-select {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  border: $default-border;
  border-radius: $sequin-border-radius;
  width: 100%;
  padding: $defaultPadding;
  background: $white;

  &.small {
    padding: $smallPadding;
    font-size: $small-text-font-size;
    line-height: $small-text-line-height;
  }

  .selected-option {
    padding: 0;
    border: none;
    font-weight: $font-weight-semibold;

    &:focus {
      border: none;
      outline: none;
    }
  }
}

.dropdown-list {
  margin-block-start: 0;
  padding: 0;
  position: absolute;
  width: 100%;
  background: $white;
  z-index: $zindex-dropdown;
  overflow: auto;
  border: $default-border;
  max-height: 220px;
  border-top: none;

  &.small {
    max-height: 160px;
  }
}

.disabled {
  color: $ash;
}

.dropdown-list-item {
  list-style: none;
  cursor: pointer;
  background: $white;
  width: 100%;
  padding: $defaultPadding;

  &.small {
    padding: $smallPadding;
    font-size: $small-text-font-size;
    line-height: $small-text-line-height;
  }

  &.disabled {
    cursor: default;
  }

  &.bordered {
    border-bottom: $default-border;
  }

  &:focus {
    outline: none;
    -webkit-box-shadow: inset 0 0 .25rem rgba($ash, 0.5);
    box-shadow: inset 0 0 .25rem rgba($ash, 0.5);
  }

  &:hover {
    background: $origami;

    &.disabled {
      background: $white;
    }
  }

  &.clear-button {
    color: $primary;
    position: absolute;
    z-index: $zindex-dropdown;
    border: $default-border;
    font-size: $fine-print-font-size;
    top: 264px;

    &.small {
      top: 196px;
    }
  }
}
</style>
