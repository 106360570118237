<template>
  <div
    v-if="reviewItem"
    class="container-fluid">
    <div
      v-if="isMobile"
      class="row">
      <ActionHeader
        page-header>
        {{ headerText }}
        <template #rightHeaderSlot>
          <TextLink
            v-if="canSkipReviews"
            @click="completeNextReviewAction(true)">
            Skip
          </TextLink>
        </template>
      </ActionHeader>
    </div>
    <div
      v-else
      class="d-flex offset-md-1 align-items-center column-gap-lg py-sm">
      <BackButton
        v-if="currentReviewItemIndex === 0"
        with-border
        :back-default-route="{ name: 'review-select' }"/>
      <h2 class="m-0 flex-grow-1">
        {{ headerText }}
      </h2>
      <TextLink
        v-if="canSkipReviews"
        class="align-self-end"
        @click="completeNextReviewAction(true)">
        Skip
      </TextLink>
    </div>
    <div
      :class="isMobile ? 'row' : 'd-flex'"
      class="align-items-start mt-md-lg column-gap-md-lg">
      <ReviewItemHeaderCard
        :package-item="reviewItem"
        class="col-md-4 offset-md-1"/>
      <div class="d-flex flex-column col-md-6">
        <ReviewItemSection
          title="General"
          :required="true"
          class="pt-md-0">
          <div>
            <h5>
              Overall, how did you feel about this style?<span class="required-asterisk">*</span>
            </h5>
            <StarRatingScale
              v-model="localReview.overallStarRating"
              :validations="v$.overallStarRating"
              class="star-rating"
              @rating-selected="localReview.overallStarRating = $event"/>
            <BaseErrors
              :class="{ 'error-scroll': v$.overallStarRating.$error }"
              :validations="v$.overallStarRating"/>
          </div>
          <ReviewItemNumWears
            v-model="localReview"
            :validations="v$.nWears"/>
          <ReviewItemOccasions
            v-if="parseInt(localReview.nWears) > 1"
            :review="localReview"/>
        </ReviewItemSection>
        <ReviewItemSection title="Fit">
          <ReviewItemFit
            v-model="localReview"
            :item="reviewItem.item"
            :validations="v$.fitRating"/>
          <ReviewItemSizing
            v-model="localReview.sizingRating"
            :validations="v$.sizingRating"/>
          <ReviewItemMaternityFriendly
            v-if="showMaternityFriendlySection"
            v-model="localReview"
            :validations="v$.maternityFriendly"/>
        </ReviewItemSection>
        <ReviewItemSection title="Condition">
          <ReviewItemQuality
            v-model="localReview"
            :item="reviewItem.item"
            :validations="v$.qualityIssue"/>
        </ReviewItemSection>
        <ReviewItemSection title="Additional Feedback">
          <ReviewImageUpload
            :review="localReview"
            :validations="v$.itemFeedbackPhotos"
            :image-upload-button-text="imageUploadButtonText"
            @photos-changed="localReview.itemFeedbackPhotos = $event"/>
          <div>
            <p>Like reading reviews? Tell others what you thought and how this item fit.</p>
          </div>
          <ReviewFeedback
            v-model="localReview"
            :validations="v$.qualityFeedbackText"
            :placeholder="feedbackTextPlaceholder"/>
        </ReviewItemSection>
        <ReviewItemExpertReview
          v-if="isStaff"
          v-model="localReview"
          :validations="v$.expertReview"/>
        <div data-intercom-target="intercom-submit-reviews">
          <AppMessage
            v-if="error"
            variant="danger"
            class="my-3">
            <div>
              {{ error.toString() }}
            </div>
          </AppMessage>
          <BaseButton
            class="w-100 my-lg"
            variant="primary"
            :text="buttonText"
            :disabled="v$.$invalid || submitting || invalidRequiredRichReview"
            @click="onConfirm"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import ReviewItemOccasions from './ReviewItemOccasions.vue'
import ReviewItemFit from './ReviewItemFit.vue'
import ReviewItemQuality from './ReviewItemQuality.vue'
import ReviewFeedback from './ReviewFeedback.vue'
import ReviewItemSizing from './ReviewItemSizing.vue'
import ActionHeader from '../global/sequin/ActionHeader.vue'
import BackButton from '../global/sequin/BackButton.vue'
import BaseButton from '@shared/components/ADORN/BaseButton.vue'
import BaseErrors from '@/components/global/BaseErrors.vue'
import AppMessage from '../global/sequin/AppMessage.vue'
import TextLink from '../global/sequin/TextLink.vue'
import ReviewImageUpload from './ReviewImageUpload.vue'
import ReviewItemHeaderCard from './ReviewItemHeaderCard.vue'
import StarRatingScale from '@/components/global/sequin/StarRatingScale.vue'
import { useVuelidate } from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'

import ReviewItemNumWears from './ReviewItemNumWears.vue'
import ReviewItemMaternityFriendly from './ReviewItemMaternityFriendly.vue'
import ReviewItemExpertReview from './ReviewItemExpertReview.vue'
import ReviewItemSection from './ReviewItemSection.vue'
import useScreenSize from '@shared/composables/screenSize.js'
import { computed, nextTick, onMounted, ref } from 'vue'
import { useReviewStore } from '@/stores/review.js'
import { storeToRefs } from 'pinia'
import { useCommunityStore } from '@/stores/community.js'
import { useClosetStore } from '@shared/stores/closet.js'
import { useStyleProfileStore } from '@/stores/styleProfile.js'
import { useClientStore } from '@shared/stores/client.js'
import { useRoute, useRouter } from 'vue-router'
import useBackNavigation from '@shared/composables/backNavigation.js'
import { useCaseStore } from '@/stores/case.js'

const localReview = ref({})
const submitting = ref(false)
const error = ref(null)

const { isMobile } = useScreenSize()
const route = useRoute()
const router = useRouter()

const reviewStore = useReviewStore()
const {
  skippedItemIds,
  itemsAvailableToReview,
  reviewItems,
  itemsToSwap,
  deliveredReviewedItems,
  canSkipReviews
} = storeToRefs(reviewStore)
const {
  navigateToItemReview,
  submitReviews,
  submitReviewSuccess,
  SET_REVIEW,
  COUNT_FIVE_STAR_REVIEWS
} = reviewStore

const communityStore = useCommunityStore()
const { occasionTags } = storeToRefs(communityStore)
const { getOccasionTags } = communityStore

const { styleColorsMap, mustReviewItems } = storeToRefs(useClosetStore())
const { showMaternity, showMaternityFriendly } = storeToRefs(useStyleProfileStore())

const {
  influencer,
  isStaff,
  tooManyItemsOut,
  canSwapItems,
  active
} = storeToRefs(useClientStore())

const { untilCaseLoaded } = useCaseStore()

const headerText = computed(() => `Review Item${reviewItems.value.length > 1 ? `s (${displayIndex.value})` : ''}`)
const canSwapDeliveredItems = computed(() => canSwapItems.value && !tooManyItemsOut.value && active.value)
const currentReviewItemIndex = computed(() => parseInt(route.params.reviewItemIndex || 0))
const reviewItem = computed(() => reviewItems.value?.[currentReviewItemIndex.value])
const isLastReview = computed(() => currentReviewItemIndex.value === reviewItems.value.length - 1)
const buttonText = computed(() => {
  if (!isLastReview.value) {
    return 'Submit and Review Next Item'
  }
  return 'Submit Review'
})
const displayIndex = computed(() => `${currentReviewItemIndex.value + 1}/${reviewItems.value.length}`)
const occasionTagsLoaded = computed(() => occasionTags.value?.length > 0)
const showMaternityFriendlySection = computed(() => {
  const style = styleColorsMap.value[reviewItem.value.item.styleColor].style
  return !style.maternity && !style.maternityFriendly && (
    showMaternity.value || showMaternityFriendly.value
  )
})
const reviewItemIds = computed(() => route.params.reviewItemIds?.split(','))
const useSingleItemReviewFlow = computed(() => reviewItemIds.value?.length === 1)
const richReviewRequired = computed(() => isStaff.value || influencer.value)
const itemAlreadyReturned = computed(() => mustReviewItems.value.some(i => i.id === reviewItem.value.id))
const photoUploadRequired = computed(() =>
  richReviewRequired.value && !itemAlreadyReturned.value && localReview.value.nWears > 0
)
const imageUploadButtonText = computed(() =>
  !photoUploadRequired.value ? 'Add a photo to your review.' : `Hi ${isStaff.value ? 'Employees' : 'Influencers'}! We are requiring a photo upload of your look or garment detail to better inform all Armoire members.`
)
const feedbackTextPlaceholder = computed(() =>
  richReviewRequired.value ? `Hi ${isStaff.value ? 'Employees' : 'Influencers'}! We are requiring text reviews to provide insightful details for Armoire members.` : 'Like reading reviews? Tell others what you thought and how item.value fit.'
)
const currentPhotoUploaded = computed(() =>
  localReview.value.itemFeedbackPhotos ? localReview.value.itemFeedbackPhotos.length > 0 : null
)
const validFeedbackMinText = computed(() =>
  localReview.value.qualityFeedbackText ? localReview.value.qualityFeedbackText.length >= 100 : null
)
const validRichReview = computed(() =>
  photoUploadRequired.value ? (currentPhotoUploaded.value && validFeedbackMinText.value) : validFeedbackMinText.value
)
const invalidRequiredRichReview = computed(() => richReviewRequired.value && !validRichReview.value)

onMounted(async () => {
  await checkParameters()

  if (!occasionTagsLoaded.value) {
    await getOccasionTags()
  }
  localReview.value = {
    packageItemPk: reviewItem.value.id,
    itemId: reviewItem.value.item.id,
    itemtypeId: reviewItem.value.item.itemType,
    overallStarRating: null,
    nWears: '',
    feedbackText: '',
    feedbackTextPrivacyLevel: '',
    sizingRating: null,
    fitRating: '',
    fitRatingMadeItWork: '',
    size: [],
    tooBig: [],
    tooSmall: [],
    qualityIssue: false,
    qualityChoices: [],
    locationChoices: [],
    missingItemsChoices: [],
    damaged: false,
    worn: false,
    qualityFeedbackText: '',
    itemFeedbackPhotos: [],
    feedbackPhotosRequired: photoUploadRequired.value,
    occasionTags: occasionTags.value.map(t => {
      return { ...t, selected: false }
    }),
    maternityFriendly: null,
    expertReview: false,
    maternityFriendlySectionRequired: showMaternityFriendlySection.value,
    expertReviewSectionRequired: isStaff.value
  }
  SET_REVIEW(localReview.value)
})

async function checkParameters () {
  await untilCaseLoaded()
  if (reviewItemIds.value) {
    // Filter down to items that exist, sort by reviewed status and then by ID.
    reviewItems.value = itemsAvailableToReview.value.filter(item => reviewItemIds.value.includes(item.id)).toSorted((a, b) => {
      if (a.reviewed && !b.reviewed) return -1
      if (!a.reviewed && b.reviewed) return 1
      return a.id - b.id
    })

    const firstUnreviewedItemIndex = reviewItems.value.findIndex(item => !item.reviewed && !skippedItemIds.value.has(item.id))
    if (firstUnreviewedItemIndex === -1) {
      router.push({ name: 'review-select' })
      return
    }

    const checkedReviewItemIds = reviewItems.value.map(item => item.id).join(',')
    if (checkedReviewItemIds !== route.params.reviewItemIds || firstUnreviewedItemIndex !== currentReviewItemIndex.value) {
      router.replace({
        name: 'review-item',
        params: {
          reviewItemIds: checkedReviewItemIds,
          reviewItemIndex: firstUnreviewedItemIndex
        }
      })
      return
    }
  }
  if (!reviewItems.value.length) {
    router.push({ name: 'review-select' })
  }
}

const { goBack } = useBackNavigation()
async function completeNextReviewAction (skip = false) {
  if (isLastReview.value) {
    if (useSingleItemReviewFlow.value) {
      submitReviewSuccess()
      goBack()
    } else if (itemsToSwap.value.length > 0) {
      router.push({ name: 'swap-confirm' })
    } else if (deliveredReviewedItems.value.length > 0 && canSwapDeliveredItems.value) {
      router.push({ name: 'review-optional-swap-select' })
    } else {
      router.push({ name: 'review-success' })
    }
  } else {
    if (skip) {
      skippedItemIds.value.add(reviewItem.value.id)
    }
    navigateToItemReview(reviewItemIds.value, currentReviewItemIndex.value + 1)
  }
}

async function onConfirm () {
  error.value = null
  if (assertValid()) {
    SET_REVIEW(localReview.value)
    COUNT_FIVE_STAR_REVIEWS(localReview.value)
    try {
      submitting.value = true
      await submitReviews({
        isLastReview: isLastReview.value,
        currentReviewItemIndex: currentReviewItemIndex.value
      })
      submitting.value = false
      completeNextReviewAction()
    } catch (error) {
      error.value = error
      submitting.value = false
    }
  }
}

function assertValid () {
  v$.value.$touch()
  if (v$.value.$invalid) {
    nextTick(() => {
      const e = document.querySelector('.error-scroll')
      e.scrollIntoView({ block: 'start', behavior: 'smooth' })
    })
    return false
  }
  return true
}

const validationRules = computed(() => {
  const baseLocalReview = {
    overallStarRating: {
      required
    },
    sizingRating: {
      required
    },
    nWears: {
      required
    },
    fitRating: {
      required
    },
    maternityFriendly: {
      required: (value, vm) => {
        if (!vm.maternityFriendlySectionRequired) { return true }
        return value !== null
      }
    },
    expertReview: {
      required: (value, vm) => {
        if (!vm.expertReviewSectionRequired) { return true }
        return value !== null
      }
    },
    size: {
      reviewCheckbox: (val, vm) => {
        return vm.fitRating === 'dislike' ? val.length >= 1 : true
      }
    },
    tooBig: {
      reviewCheckbox: (val, vm) => {
        return vm.size.includes('too big') ? val.length >= 1 : true
      }
    },
    tooSmall: {
      reviewCheckbox: (val, vm) => {
        return vm.size.includes('too small') ? val.length >= 1 : true
      }
    },
    qualityIssue: {
      required: (val, vm) => {
        return vm.qualityIssue === true ? val !== null : true
      }
    },
    qualityChoices: {
      reviewCheckbox: (val, vm) => {
        return vm.qualityIssue === true ? val.length >= 1 : true
      }
    },
    feedbackTextPrivacyLevel: {
      required: (val, vm) => {
        if (!vm.feedbackTextPrivacyLevelRequired) { return true }
        return val !== null
      }
    },
    itemOccasions: {
      required: (val, vm) => {
        if (!vm.occasionTagsRequired) { return true }
        return val.length >= 1
      }
    },
    styleChoices: {
      reviewCheckbox: (val, vm) => {
        return vm.styleRating === 'dislike' ? val.length >= 1 : true
      }
    }
  }

  const requiredTextReview = {
    qualityFeedbackText: {
      required,
      minLength: minLength(100)
    }
  }

  const requiredPhotoUpload = {
    itemFeedbackPhotos: {
      required: (value, vm) => {
        if (!vm.feedbackPhotosRequired) { return true }
        return value !== null
      }
    }
  }

  return richReviewRequired.value
    ? photoUploadRequired.value
      ? { ...baseLocalReview, ...requiredTextReview, ...requiredPhotoUpload }
      : { ...baseLocalReview, ...requiredTextReview }
    : baseLocalReview
})

const v$ = useVuelidate(validationRules, localReview)
</script>

<style lang="scss" scoped>
@import './ReviewItem.scss';
</style>
