<template>
  <div class="game-container pt-sm pt-sm-md d-flex flex-column align-items-center">
    <div
      v-show="rating"
      class="text-white text-center py-md px-lg mt-md mb-lg"
      :class="rating+'-info'">
      <p>{{ ratingText }}</p>
    </div>
    <div class="card-stack d-flex justify-content-center align-items-center">
      <StyleGameCard
        v-for="(styleColor, index) in filteredStyleColors"
        :id="'card-' + styleColor.id"
        :key="styleColor.id"
        v-drag="dragHandler(styleColor.id)"
        :drag-options="dragOptions"
        :style-color="styleColor"
        :style="{ zIndex: styleColorCount - index }"
        class="card"
        :class="'card-' + (styleColorCount - index)"
        @dislike="simulateDragHandler(styleColor.id, 'dislike')"
        @favorite="simulateDragHandler(styleColor.id, 'favorite')"/>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import StyleGameCard from '@/components/signUp/styleGame/StyleGameCard.vue'
import { useSignUpStyleGameStore } from '@/stores/signUpStyleGame.js'
import { useClosetStore } from '@shared/stores/closet.js'
import { storeToRefs } from 'pinia'
import { dragDirective } from '@vueuse/gesture'
import useScreenSize from '@shared/composables/screenSize.js'
import { useMediaQuery } from '@vueuse/core'

const props = defineProps({
  styleColors: {
    type: Array,
    default: () => []
  }
})
const dragOptions = {
  filterTaps: true,
  swipeDistance: [1, 1],
  preventWindowScrollY: true,
  useTouch: true
}
const vDrag = {
  beforeMount: dragDirective().bind
}
const { isMobile } = useScreenSize()
const isShortScreen = useMediaQuery('(max-height: 1024px)')
const { filteredRatingsMap } = storeToRefs(useSignUpStyleGameStore())
const styleColorCount = computed(() => props.styleColors.length)
const filteredStyleColors = computed(() => props.styleColors.filter(styleColor => !hideStyleColor(styleColor)))
const closetStore = useClosetStore()

const rating = ref('')
const ratingText = computed(() => {
  if (rating.value === 'favorite') return 'This has been added to your favorites!'
  if (rating.value === 'dislike') return 'Not interested.'
  return ''
})
function hideStyleColor (styleColor) {
  if (!filteredRatingsMap.value) return false
  return filteredRatingsMap.value[styleColor.id]
}
async function setStyleColorSource (styleColorId) {
  const sourceIndex = props.styleColors.findIndex((styleColor) => styleColor.id === styleColorId)
  const source = {
    sourceLocation: 'signup',
    sourceName: 'Sign Up Style Game',
    sourcePosition: sourceIndex + 1,
    sourceType: 'stylegame'
  }
  await closetStore.setStyleColorSource({
    styleColorId,
    source,
    sourceIndex
  })
}
async function dislike (styleColorId) {
  await setStyleColorSource(styleColorId)
  await closetStore.dislike({ styleColorId })
}
async function favorite (styleColorId) {
  await setStyleColorSource(styleColorId)
  await closetStore.favorite({ styleColorId })
}
async function simulateDragHandler (styleColorId, rating) {
  const isFavorite = rating === 'favorite'
  const targetX = isFavorite ? window.innerWidth : -window.innerWidth
  const duration = 100
  const steps = 10
  const stepTime = duration / steps
  let currentStep = 0

  const interval = setInterval(() => {
    currentStep++
    const simulatedX = (targetX / steps) * currentStep
    dragHandler(styleColorId)({ movement: [simulatedX], dragging: true })

    if (currentStep >= steps) {
      clearInterval(interval)
      dragHandler(styleColorId)({ movement: [simulatedX], dragging: false })
    }
  }, stepTime)
}

const dragHandler = (styleColorId) => ({ movement: [x], dragging }) => {
  const threshold = isMobile.value ? 100 : 200
  const clampedX = Math.max(-threshold, Math.min(x, threshold))
  // quadratic formula to create a curve: y = ax^2 + bx + c
  const a = 0.0001
  const b = 0
  const c = 0
  const allCards = Array.from(document.querySelectorAll('.card'))
  const card = document.getElementById(`card-${styleColorId}`)
  const backgroundCards = allCards.filter((c) => c !== card)
  const yCurve = a * clampedX * clampedX + b * clampedX + c
  const slope = 2 * a * clampedX + b
  const angle = Math.atan(slope) * (180 / Math.PI)

  const animationDuration = 400
  const cardResetDelay = 400

  if (dragging && card) {
    card.style.transform = `translate(${clampedX}px, ${yCurve}px) rotate(${angle}deg)`

    backgroundCards.forEach((c) => {
      c.style.transform = `scale(${1 - Math.abs(clampedX) / 950}) rotate(${angle / 12}deg) translateY(${isShortScreen.value ? 50 : 0}px)`
      c.style.transition = 'transform 200ms ease-out'
    })

    rating.value = clampedX > 5 ? 'favorite' : clampedX < -5 ? 'dislike' : ''
  }

  if (!dragging && card) {
    if (Math.abs(x) >= threshold) {
      const direction = x > 0 ? 1 : -1
      const targetX = direction * window.innerWidth
      const currentRating = direction === 1 ? 'favorite' : 'dislike'

      card.style.transition = `transform ${animationDuration}ms ease-out, opacity ${animationDuration}ms ease-out`
      card.style.transform = `translate(${targetX}px, ${yCurve}px) rotate(${angle + direction * 15}deg)`
      card.style.opacity = 0

      rating.value = currentRating
      direction === 1 ? favorite(styleColorId) : dislike(styleColorId)

      setTimeout(() => {
        card.style.transition = ''
        card.style.transform = ''
        card.style.opacity = 1
        setTimeout(() => {
          rating.value = ''
          backgroundCards.forEach((c) => (c.style.transform = ''))
        }, cardResetDelay)
      }, animationDuration)
    } else {
      card.style.transition = `transform ${animationDuration / 2}ms ease-out`
      card.style.transform = ''
      card.style.opacity = 1
      backgroundCards.forEach((c) => (c.style.transform = ''))
      rating.value = ''
    }
  }
}

</script>

<style scoped lang="scss">
.game-container {
  width: 100%;
  position: relative;
}
.card-stack {
  position: relative;
  width: 100%;
}
.card {
  transition: all 0.3s;
  user-select: none; /* Prevent text selection */
  -webkit-user-drag: none; /* Prevent image dragging in Safari */
  -webkit-touch-callout: none; /* Disable touch menu on long press */
  transform: scale(1.0)
}
:deep(.preview-image img) {
  user-select: none; /* Prevent text selection */
  -webkit-user-drag: none; /* Prevent image dragging in Safari */
  -webkit-touch-callout: none; /* Disable touch menu on long press */
  pointer-events: none;
}
.card:nth-child(even) {
  transform: rotate(7deg) scale(0.9);
}
.card:nth-child(1) {
  transform: rotate(0deg);
  box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.10), 0px 10px 10px -5px rgba(0, 0, 0, 0.04);
}
.card:nth-child(3) {
  box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.10), 0px 10px 10px -5px rgba(0, 0, 0, 0.04);
  transform: rotate(7deg) scale(0.9);
}
.dislike-info {
  background: rgba($armor, 0.75);
  width: 350px;
  transition: all 0.3s;
  position: absolute;

}
.favorite-info {
  background: rgba($orchid, 0.75);
  width: 350px;
  transition: all 0.3s;
  position: absolute;
}
</style>
