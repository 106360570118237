<template>
  <div>
    <h6
      v-if="occasionOptions && occasionOptions.length > 0"
      class="mb-0 mt-3">
      Occasion / Vibe:
    </h6>
    <div
      :class="{ 'd-flex justify-content-between align-items-center ': !isMobile,
                'flex-row-reverse': occasionOptions && occasionOptions.length === 0}">
      <horizontal-scroll
        v-if="occasionOptions && occasionOptions.length > 0"
        :class="{'filters-desktop' : !isMobile}">
        <div
          v-for="option in occasionOptions"
          :key="option.tag.id"
          class="me-2">
          <filter-select-button
            class="text-capitalize mt-3"
            :selected="getFilterSelected(option.tag)"
            :text="`${option.tag.tag} (${option.count})`"
            @click="onTagClick(option.tag)"/>
        </div>
      </horizontal-scroll>
      <div
        class="sort d-flex align-items-center mt-3">
        <p
          v-if="!isMobile"
          class="me-3 mb-0 small-text">
          Sort
        </p>
        <dropdown-single-select
          small
          placeholder="Members like me"
          :class="{ 'w-100 pt-2': isMobile }"
          :model-value="sortBy"
          :dropdown-options="sortOptions"
          :initial-selection-index="0"
          @update:modelValue="applySort"/>
      </div>
    </div>
  </div>
</template>

<script>
import FilterSelectButton from '@/components/styleColorSet/filtersFlyout/FilterSelectButton'
import DropdownSingleSelect from '../../global/sequin/DropdownSingleSelect'
import HorizontalScroll from '../../global/sequin/HorizontalScroll'

import { mapActions } from 'vuex'

export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  },
  components: {
    DropdownSingleSelect,
    FilterSelectButton,
    HorizontalScroll
  },
  props: {
    styleColor: {
      type: Object,
      required: true
    },
    modelValue: {
      type: Array,
      default: undefined
    }
  },
  emits: ['update:modelValue'],
  data () {
    return {
      sortBy: 'similarity',
      selectedTags: []
    }
  },
  computed: {
    occasionOptions () {
      return this.styleColor?.filterOptions?.occasionTags
    },
    sortOptions () {
      const sortOptions = [
        { text: 'Members like me', value: 'similarity' },
        { text: 'By date', value: 'created' },
        { text: 'By rating', value: 'rating' }
      ]
      return sortOptions
    }
  },
  watch: {
    sortBy (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getFilteredSortedReviews({
          styleColorId: this.styleColor.id,
          tagIds: this.selectedTags,
          sort: this.sortBy
        })
      }
    }
  },
  methods: {
    ...mapActions('closet', [
      'getFilteredSortedReviews'
    ]),
    applySort (selectedOption) {
      this.sortBy = selectedOption
    },
    getFilterSelected (tag) {
      return this.modelValue.includes(tag.id)
    },
    resetSort () {
      this.sortBy = 'similarity'
    },
    async onTagClick (tag) {
      const index = this.modelValue.indexOf(tag.id)
      let newValue
      if (index > -1) {
        newValue = this.modelValue.filter((_, i) => i !== index)
      } else {
        newValue = [...this.modelValue, tag.id]
      }
      this.$emit('update:modelValue', newValue)
      if (this.selectedTags.includes(tag.id)) {
        this.selectedTags = this.selectedTags.filter((t) => t !== tag.id)
      } else {
        this.selectedTags = [...this.selectedTags, tag.id]
      }
      await this.getFilteredSortedReviews({
        styleColorId: this.styleColor.id,
        tagIds: this.selectedTags,
        sort: this.sortBy
      })
    }
  }
}

</script>

<style lang="scss" scoped>
  .button-section {
    overflow-y: scroll;
  }
  :deep(.horizontal-scroll) {
    padding-bottom: 5px;
  }

  .filters-desktop {
    // not using media queries because of flyout
    max-width: 70%;
  }

</style>
