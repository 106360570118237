import { defineStore, storeToRefs } from 'pinia'
import { ref, computed } from 'vue'
import { getSignUpStyleColors } from '@shared/api/styleGame.js'
import { useClosetStore } from '@shared/stores/closet.js'
import { until } from '@vueuse/core'

export const useSignUpStyleGameStore = defineStore('signUpStyleGame', () => {
  const { ratingsMap, ratingsMapLoaded } = storeToRefs(useClosetStore())

  const signUpStyleColors = ref([])

  const styleColorsLoaded = computed(() => signUpStyleColors.value.length > 0)
  const styleColorIds = computed(() => signUpStyleColors.value.map(item => item.id))
  const filteredRatingsMap = computed(() => Object.fromEntries(
    Object.entries(ratingsMap.value).filter(([key]) => styleColorIds.value.includes(key)))
  )
  const ratedStyleCount = computed(() =>
    Object.values(filteredRatingsMap.value).filter(rating => rating !== 0).length
  )
  const favoritedStyleCount = computed(() =>
    Object.values(filteredRatingsMap.value).filter(rating => rating > 0).length
  )
  const dislikedStyleCount = computed(() =>
    Object.values(filteredRatingsMap.value).filter(rating => rating < 0).length
  )
  const isStyleGameComplete = computed(() => ratedStyleCount.value >= signUpStyleColors.value.length)

  async function fetchStyleGameStyleColors () {
    const response = await getSignUpStyleColors()
    signUpStyleColors.value = response.data
  }

  async function untilDataLoaded ({ timeout = 2000, throwOnTimeout = false } = {}) {
    if (!styleColorsLoaded.value) {
      await Promise.all([
        fetchStyleGameStyleColors().catch((error) => {
          if (throwOnTimeout) {
            throw error
          }
        }),
        until(styleColorsLoaded).toBe(true, { timeout, throwOnTimeout }),
        until(ratingsMapLoaded).toBe(true, { timeout, throwOnTimeout }) // Loaded by router (requiresAuth)
      ])
    }
  }

  return {
    styleColorsLoaded,
    signUpStyleColors,
    styleColorIds,
    filteredRatingsMap,
    ratedStyleCount,
    favoritedStyleCount,
    dislikedStyleCount,
    isStyleGameComplete,
    fetchStyleGameStyleColors,
    untilDataLoaded
  }
})
