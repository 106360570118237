<template>
  <div class="row g-0">
    <div class="col">
      <router-view
        #default="{ Component }"
        :key="$route.path">
        <keep-alive>
          <component
            :is="Component"
            class="custom-fade"/>
        </keep-alive>
      </router-view>
    </div>
  </div>
</template>

<script>
import { vuexAccessors } from '../global/helpers/vuex'
export default {
  computed: {
    ...vuexAccessors('review', [
      'itemsToSwap'
    ])
  }
}
</script>

<style lang="scss" scoped>
  /*
    Vue transitions have issues with keep-alive, so we should stick with
    a simple CSS animation for now (https://github.com/quasarframework/quasar/issues/1466)
   */
  .custom-fade {
    animation: appear .5s ease;
    @keyframes appear {
      0% { opacity: 0 }
      100% { opacity: 1 }
    }
  }
</style>
