<template>
  <div class="style-game-start d-flex flex-column align-items-center text-center">
    <div>
      <h2
        class="mb-lg mb-lg-xl">
        Help Us Understand
        <br v-if="isMobile">
        Your Style
      </h2>
      <h3 class="mb-lgp mb-lg-xxl">
        Like it? {{ verb }} right! <br>
        Not your style? {{ verb }} left.
      </h3>
      <BaseButton
        text="Find Your Style!"
        class="w-100"
        @click="emit('click', event)"/>
    </div>
  </div>
</template>

<script setup>
import BaseButton from '@shared/components/ADORN/BaseButton.vue'
import useScreenSize from '@shared/composables/screenSize.js'
import { computed } from 'vue'

const { isMobile, isDesktop } = useScreenSize()
const verb = computed(() => isDesktop.value ? 'Drag' : 'Swipe')

const emit = defineEmits({
  click: (event) => event instanceof MouseEvent
})
</script>

<style lang="scss" scoped>
.style-game-start {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 178px $spacing-md 0;
  background: rgba(247, 245, 247, 0.75);
  z-index: $zindex-sticky;
}
</style>
