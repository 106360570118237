<template>
  <div
    ref="wrapper"
    class="wrapper">
    <ActionHeader
      v-if="isTabletOrSmaller"
      class="p-3 border-bottom"
      :fallback-route="{ name: 'closet-sections'}">
      {{ mobileHeaderTitle }}
      <template #rightHeaderSlot>
        <CollectionOwner
          v-if="showCollectionOwner"
          :owner="collectionOwner"
          :subtitle="subtitle"
          @on-edit-collection="emit('on-edit-collection', $event)"
          @on-delete-collection="emit('on-delete-collection', $event)"
          @on-share-style-color-set-page="emit('on-share-style-color-set-page', $event)"/>
      </template>
    </ActionHeader>
    <div>
      <StyleColorSetPageHeader
        :style-color-set="styleColorSet"
        :show-collection-owner="showCollectionOwner"
        @on-edit-collection="emit('on-edit-collection', $event)"
        @on-delete-collection="emit('on-delete-collection', $event)"
        @on-share-style-color-set-page="emit('on-share-style-color-set-page', $event)"/>
      <div class="container-fluid">
        <slot name="aboveStyles"/>
        <StyleColorSetPageFilterSection
          :style-color-set="styleColorSet"
          :selected-filters="selectedFilters"
          @remove-filter="emit('remove-filter', $event)"
          @clear-all-filters="emit('clear-all-filters')"
          @on-available-now-toggle="emit('on-available-now-toggle', $event)"/>
        <StyleColorSetPageStylesList
          :style-color-set="styleColorSet"
          :style-colors-loading="styleColorsLoading"
          :selected-filters="selectedFilters"
          @bottom-of-set="emit('bottom-of-set', $event)"
          @clear-all-filters="emit('clear-all-filters')"/>
        <div
          v-if="isTabletOrSmaller && isAuthenticated"
          class="floating-buttons">
          <FilterSortButton
            @click="openFiltersFlyout(styleColorSet)"/>
        </div>
      </div>
      <slot name="belowStyles"/>
    </div>
  </div>
</template>

<script setup>
import { computed, watch, watchEffect } from 'vue'
import useScreenSize from '@shared/composables/screenSize.js'
import useStyleColorSet from '@/composables/styleColorSet.js'
import StyleColorSetPageHeader from './StyleColorSetPageHeader.vue'
import CollectionOwner from '@/components/global/CollectionOwner.vue'
import ActionHeader from '@/components/global/sequin/ActionHeader.vue'
import FilterSortButton from '@/components/styleColorSet/FilterSortButton.vue'
import StyleColorSetPageFilterSection from '@/components/styleColorSet/StyleColorSetPageFilterSection.vue'
import { useFlyouts } from '@/composables/flyouts.js'
import StyleColorSetPageStylesList from '@/components/styleColorSet/StyleColorSetPageStylesList.vue'
import { useRoute, useRouter } from 'vue-router'
import { useLogger } from 'vue-logger-plugin'
import { useHomepageStore } from '@/stores/homepage.js'
import useClient from '@/composables/client'

const { isTabletOrSmaller } = useScreenSize()
const { isAuthenticated } = useClient()

const props = defineProps({
  styleColorSet: {
    type: Object,
    required: true
  },
  styleColorsLoading: {
    type: Boolean,
    default: false
  },
  updateQueryStringFilters: {
    type: Boolean,
    default: true
  }
})

const emit = defineEmits([
  'on-edit-collection',
  'on-delete-collection',
  'on-share-style-color-set-page',
  'on-available-now-toggle',
  'clear-all-filters',
  'bottom-of-set',
  'remove-filter'
])
const homepageStore = useHomepageStore()

watchEffect(() => {
  if (!isAuthenticated.value) {
    homepageStore.showMobileFooter = true
  }
})

const router = useRouter()
const route = useRoute()
const logger = useLogger()
watch(() => props.styleColorSet, ({ filters, sort }, { filters: prevFilters, sort: prevSort }) => {
  if (!props.updateQueryStringFilters) return
  const filtersChanged = JSON.stringify(filters) !== JSON.stringify(prevFilters) || sort !== prevSort
  if (filtersChanged) {
    router.replace({ ...route, query: { ...route.query, ...filters, sort } }).catch(() => {
      // Do nothing. Allow the router to intercept as necessary.
      logger.warn('Error updating query string filters', { filters, sort })
    })
  }
})

const { sourceType, mobileHeaderTitle, collectionOwner, location, subtitle } = useStyleColorSet(() => props.styleColorSet)

const showCollectionOwner = computed(() => {
  return ['collection', 'marketing'].includes(sourceType.value) || location.value === 'featured'
})

const selectedFilters = computed(() => {
  const filters = props.styleColorSet.filters || {}
  const filterOptions = props.styleColorSet.filterOptions || {}

  return Object.keys(filters).flatMap(filterTypeKey => {
    const filtersOfType = filters[filterTypeKey] || []

    return filtersOfType.map(filterOfType => {
      const filterOption = filterOptions[filterTypeKey]?.find(option => option.value === filterOfType)
      return filterOption ? { ...filterOption, filterType: filterTypeKey } : null
    }).filter(Boolean) // Filter out null values
  })
})

const { openFiltersFlyout } = useFlyouts()

</script>

<style lang="scss" scoped>
.prospect {
  :deep(.page-header-info) {
    background-color: $origami;
    color: $armor;
  }
  :deep(.header-subtitle) {
    color: $armor;
  }
  :deep(.collection-owner-name) {
    color: $armor;
  }
}
.floating-buttons {
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  bottom: 24px;
  z-index: $zindex-fixed;
  width: 100%;
  transition: all 300ms ease-in-out;
}
:deep(.back-button) {
  position: unset;
}

</style>
