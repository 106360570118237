<template>
  <div>
    <style-quiz-progress-page
      v-if="showProgressAnimation"
      :progress-index="endOfSectionIndex"/>
    <div
      v-if="isValid"
      class="bottom-nav default-border-top">
      <slot/>
      <div class="d-flex justify-content-center">
        <sequin-button
          class="w-330"
          @click="onNextNavigation">
          {{ buttonText }}
        </sequin-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import SequinButton from '../global/sequin/SequinButton'
import StyleQuizProgressPage from './StyleQuizProgressPage'
import { captureMessage } from '@sentry/vue'
import { useSignUpNavigationStore } from '@/stores/signUpNavigation.js'

export default {
  components: {
    SequinButton,
    StyleQuizProgressPage
  },
  props: {
    buttonText: {
      type: String,
      default: 'Next'
    },
    validations: {
      type: Object,
      default: () => {
        return { $touch () {} }
      }
    },
    nextRouteOverride: {
      type: Object,
      default: null
    }
  },
  setup () {
    const { next } = useSignUpNavigationStore()
    return {
      next
    }
  },
  data () {
    return {
      showProgressAnimation: false,
      allowNext: false
    }
  },
  computed: {
    ...mapState('client', [
      'styleProfileId'
    ]),
    ...mapState('styleProfile', [
      'birthDate'
    ]),
    ...mapGetters('styleQuizNav', [
      'styleQuizRoutes',
      'getCurrentStyleQuizRoute',
      'getNextStyleQuizRoute',
      'getNextStyleQuizRouteForRouter'
    ]),
    currentRoute () {
      return this.getCurrentStyleQuizRoute({ ...this.$route })
    },
    endOfSectionIndex () {
      return this.currentRoute.endOfSection
    },
    nextRoute () {
      return this.getNextStyleQuizRouteForRouter({ ...this.$route })
    },
    isValid () {
      return !this.validations.$invalid
    }
  },
  async mounted () {
    this.allowNext = true
  },
  methods: {
    ...mapActions('styleProfile', [
      'patchStyleProfile'
    ]),
    ...mapActions('client', [
      'setStyleProfileComplete'
    ]),
    async onNextNavigation () {
      if (this.$route.name.startsWith('sign-up-')) {
        await this.next()
        return
      }
      if (this.styleProfileId) {
        await this.patchStyleProfile()
        this.setStyleProfileComplete(this.birthDate !== null)
      } else {
        if (this.$route.name !== 'style-quiz-intro') {
          this.$logger.error('No styleProfileId', this.$route.name)
          captureMessage('Style Profile ID not found', {
            extra: {
              route: this.$route.name
            }
          })
          await this.$router.push({ name: 'login' })
          return
        }
      }
      if (!this.allowNext) {
        this.$logger.info('allowNext is false; skipping out of onNextNavigation')
        return
      }

      if (this.nextRouteOverride) {
        await this.$router.push(this.nextRouteOverride)
        this.allowNext = false
        return
      }
      if (this.endOfSectionIndex !== undefined) {
        this.showProgressAnimation = true
      } else {
        await this.$router.push({ ...this.nextRoute, query: this.$route.query })
        this.allowNext = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 12px;
  background-color: $white;
  border-top: $default-border;
  z-index: $zindex-fixed;
  animation: slide-up .3s forwards ease-out;

  @keyframes slide-up {
    0% {
      transform: translateY(100%)
    }

     100% {
      transform: translateY(0)
    }
  }
}
</style>
