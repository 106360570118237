<template>
  <SignUpScreen>
    <div class="text-center my-md">
      <h2>Let us find the right membership plan for you</h2>
      <h3
        class="mt-xl mb-lg">
        How often do you dress to impress?
      </h3>
      <p
        class="my-lg">
        Select one
      </p>
      <div class="gap-sm mx-auto d-flex flex-wrap justify-content-center">
        <IconPill
          v-for="option in planChoiceFrequencyOptions"
          :key="option.value"
          v-model="planChoiceFrequency"
          :label="option.text"
          type="radio"
          :value="option.value">
          <template #icon>
            <SvgHanger
              v-if="option.value === 'few_per_month'"/>
            <Svg2Hanger
              v-else-if="option.value === 'once_per_week'"/>
            <Svg3Hanger
              v-else-if="option.value === 'several_per_week'"/>
          </template>
        </IconPill>
      </div>
      <h3
        class="mt-xl mb-lg">
        How much do you spend on clothing each month?
      </h3>
      <p
        class="my-lg">
        Select one
      </p>
      <div class="gap-sm mx-auto d-flex flex-wrap justify-content-center">
        <IconPill
          v-for="option in planChoiceSpendOptions"
          :key="option.value"
          v-model="planChoiceSpend"
          :label="option.text"
          type="radio"
          :value="option.value">
          <template #icon>
            <SvgDollar
              v-if="option.value === '100_or_less'"/>
            <Svg2Dollar
              v-else-if="option.value === '100_to_200'"/>
            <Svg3Dollar
              v-else-if="option.value === '200_or_more'"/>
          </template>
        </IconPill>
      </div>
    </div>
  </SignUpScreen>
</template>

<script setup>
import SignUpScreen from '@/views/signUp/SignUpScreen.vue'
import IconPill from '@shared/components/ADORN/IconPill.vue'
import SvgHanger from '@/components/global/svg/SvgHanger.vue'
import Svg2Hanger from '@/components/global/svg/Svg2Hanger.vue'
import Svg3Hanger from '@/components/global/svg/Svg3Hanger.vue'
import SvgDollar from '@/components/global/svg/SvgDollar.vue'
import Svg2Dollar from '@/components/global/svg/Svg2Dollar.vue'
import Svg3Dollar from '@/components/global/svg/Svg3Dollar.vue'
import { useStyleProfileStore } from '@/stores/styleProfile.js'
import { onBeforeMount, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useSubscriptionStore } from '@/stores/subscribe.js'
import { usePlans } from '@shared/composables/plans.js'

const { initializeMembershipPlans, setRoutePlanQuery } = usePlans()
onBeforeMount(() => {
  initializeMembershipPlans()
})

const { selectedPlanId, recommendedPlanId } = storeToRefs(useSubscriptionStore())
const {
  planChoiceFrequency,
  planChoiceFrequencyOptions,
  planChoiceSpend,
  planChoiceSpendOptions
} = storeToRefs(useStyleProfileStore())

watch(recommendedPlanId, async (recommendedPlanId) => {
  if (planChoiceFrequency.value && planChoiceSpend.value) {
    selectedPlanId.value = recommendedPlanId
    setRoutePlanQuery(recommendedPlanId)
  }
})
</script>

<style lang="scss" scoped>
.icon-pill {
  width: 111px;
  padding-left: $spacing-xxs !important;
  padding-right: $spacing-xxs !important;
  @include media-tablet-or-larger {
    width: 115px;
    padding-left: $spacing-xs !important;
    padding-right: $spacing-xs !important;
  }

  svg {
    color: $plum;
  }
}
</style>
