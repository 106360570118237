<template>
  <div class="background">
    <transition name="fade">
      <div v-if="outstandingItems.length">
        <div class="container pb-xxl">
          <h2 class="section-headline">
            Please return your clothes
          </h2>
          <p class="mb-md">
            You requested to cancel your membership on {{ toMonthDayYearString(membershipDetails.scheduledCancelDate) }},
            but we have not received your outstanding items. If we do not receive your items by
            {{ toMonthDayYearString(membershipDetails.cancelGracePeriodDate) }}, you will be charged the full member
            price for the clothing you have kept.
          </p>
          <p>
            Print a new return label if you’ve lost yours.
          </p>
          <SequinButton
            v-if="fedexPreferred"
            type="a"
            small
            :href="printReturnLabelLink"
            class="mt-md">
            Print Return Label
          </SequinButton>
          <SequinButton
            v-else
            small
            class="mt-md"
            @click="contactSupport">
            Contact Support
          </SequinButton>
        </div>
        <div class="bg-origami pb-xxl">
          <div class="container">
            <h2 class="section-headline">
              Already returned your items?
            </h2>
            <p>Track the status of any items you’ve already dropped in the mail.</p>
            <SequinButton
              small
              type="router-link"
              :to="{ name: 'account', query: { section: 'shipping-returns' } }"
              class="mt-md">
              Return Status
            </SequinButton>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { toMonthDayYearString } from '@/utils/stringParsing.js'
import { useReturnCarrier } from '@/composables/returnCarrier'
import SequinButton from '../global/sequin/SequinButton'
import { useClientStore } from '@shared/stores/client'
import { useClosetStore } from '@shared/stores/closet.js'

const clientStore = useClientStore()
const { membershipDetails } = storeToRefs(clientStore)
const closetStore = useClosetStore()
const { outstandingItems } = storeToRefs(closetStore)
const { getPackageItems } = closetStore

const { fedexPreferred, printReturnLabelLink } = useReturnCarrier()
const contactSupport = () => {
  window.Intercom('showNewMessage')
}
onMounted(() => {
  getPackageItems()
})
</script>

<style lang="scss" scoped>
.background {
  height: 100vh;
}
</style>
