<template>
  <SignUpScreen>
    <div class="checkout-screen d-flex flex-column flex-md-row justify-content-center column-gap-lg column-gap-lg-xxl">
      <FadeTransition group>
        <div
          v-if="membershipPlans"
          class="d-flex flex-column align-items-center">
          <PlanCard
            :plan-id="selectedPlanId"
            :selectable="false"
            class="m-md mt-md-xl align-self-stretch"/>
          <BaseLink
            :to="changePlanLink"
            class="text-center color-plum"
            @click="clearAllPromos">
            Change plan selection
          </BaseLink>
        </div>
        <SubscribeForm class="mt-lg mt-md-xl"/>
      </FadeTransition>
    </div>
  </SignUpScreen>
</template>

<script setup>
import SignUpScreen from '@/views/signUp/SignUpScreen.vue'
import { useSubscriptionStore } from '@/stores/subscribe.js'
import { storeToRefs } from 'pinia'
import PlanCard from '@/components/signUp/PlanCard.vue'
import { computed, onMounted } from 'vue'
import BaseLink from '@shared/components/BaseLink.vue'
import { useClientStore } from '@shared/stores/client.js'
import usePrepay from '@shared/composables/prepay.js'
import SubscribeForm from '@/components/signUp/SubscribeForm.vue'
import { usePlans } from '@shared/composables/plans.js'
import FadeTransition from '@shared/components/ADORN/Transitions/FadeTransition.vue'

const subscriptionStore = useSubscriptionStore()
const { isRejoiningMember } = storeToRefs(subscriptionStore)
const { clearAllPromos } = subscriptionStore

const { membershipStatus } = storeToRefs(useClientStore())

const { isPrepayFlow, prepayPlanGroup } = usePrepay()
const { selectedPlanId, membershipPlans, ensurePlanIsSelected, setRoutePlanQuery } = usePlans()

const changePlanLink = computed(() => {
  return isPrepayFlow.value
    ? {
        name: 'style-quiz-prepay-plan', // TODO: ENG-4642
        query: { prepayGroup: prepayPlanGroup.name }
      }
    : {
        name: 'sign-up-plan-choice',
        query: { plan: selectedPlanId.value }
      }
})

onMounted(async () => {
  await ensurePlanIsSelected()
  setRoutePlanQuery()
  isRejoiningMember.value = membershipStatus.value === 'former_member'
})
</script>

<style lang="scss" scoped>
.checkout-screen {
  @include transition-base-all;
  @include media-breakpoint-up(xl) {
    column-gap: 135px !important;
  }
}
</style>
