<template>
  <div :class="isMobile ? 'row' : 'container'">
    <action-header
      v-if="isMobile"
      class="col col-12"
      page-header>
      Review Items
    </action-header>

    <div
      class="col col-12"
      :class="{ 'list-padding': isMobile }">
      <review-select-items-list
        v-if="itemsAvailableToReview?.length"
        title="Select the items you're reviewing today"
        :subtitle="isMobile ? subtitle : null"
        :package-items="sortedItemsAvailableToReview"
        :selected-items="checked"
        @selected="onSelectItem">
        <app-message
          v-if="!isMobile && subtitle.length"
          variant="brand"
          class="text-center mt-n3"
          :class="{ 'mt-0 row border-bottom': isMobile }">
          {{ subtitle }}
        </app-message>
      </review-select-items-list>
      <svg-spinner
        v-else
        class="col my-4"
        :class="{ 'list-padding': isMobile }"
        :size="isMobile ? 100 : 200"
        :color="isMobile ? 'brand' : 'white'"/>
    </div>

    <div
      :class="isMobile
        ? 'col col-12 mobile-button'
        : 'd-flex justify-content-end mt-3 mb-3 me-3'">
      <sequin-button
        mobile-block
        data-intercom-target="intercom-review-continue"
        :disabled="checked.length === 0"
        @click="submit">
        {{ action }} {{ checked.length > 0 ? checked.length : '' }} Item{{ checked.length > 1 ? 's' : '' }}
      </sequin-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import goBack from '../global/mixins/goBack'
import ReviewSelectItemsList from './ReviewSelectItemsList'
import SequinButton from '../global/sequin/SequinButton'
import ActionHeader from '../global/sequin/ActionHeader'
import AppMessage from '../global/sequin/AppMessage'
import { vuexAccessors } from '../global/helpers/vuex'
import SvgSpinner from '@/components/global/svg/SvgSpinner.vue'
import { useReviewStore } from '@/stores/review.js'

export default {
  components: {
    ActionHeader,
    AppMessage,
    ReviewSelectItemsList,
    SequinButton,
    SvgSpinner
  },
  mixins: [goBack],
  setup () {
    const { navigateToItemReview } = useReviewStore()
    return {
      navigateToItemReview
    }
  },
  data () {
    return {
      checked: [],
      noItemsTimeout: null
    }
  },
  computed: {
    ...mapState('client', [
      'membershipDetails',
      'nextBillingDate'
    ]),
    ...mapState('closet', [
      'deliveredItems',
      'toReturnItems',
      'transitItems',
      'transitPackages',
      'packingItems',
      'styleColorsMap'
    ]),
    ...mapGetters('client', [
      'allPickupPackages'
    ]),
    ...mapGetters('review', [
      'itemsAvailableToReview',
      'isReviewOOC',
      'minItemsToReview'
    ]),
    ...vuexAccessors('review', [
      'reviewItems',
      'itemsToSwap'
    ]),
    swapAvailable () {
      return this.membershipDetails.allowedShipments === null || this.membershipDetails.allowedShipments > 0
    },
    packageInTransit () {
      return this.packingItems.length || (this.transitPackages && this.transitPackages.length && !this.allPickupPackages)
    },
    subtitle () {
      return this.isReviewOOC
        ? `You must review at least ${this.minItemsToReview} item${this.minItemsToReview > 1 ? 's' : ''} before you can start your next case swap.`
        : 'You can review items before or after you swap them.'
    },
    sortedItemsAvailableToReview () {
      return this.itemsAvailableToReview.slice().sort((a, b) => a.id - b.id)
    }
  },
  watch: {
    itemsAvailableToReview (newVal) {
      if (this.noItemsTimeout && newVal?.length) {
        clearTimeout(this.noItemsTimeout)
        this.noItemsTimeout = null
        this.init()
      }
    }
  },
  mounted: function () {
    // If there's nothing to review route back to closet after a short timeout.
    if (!this.itemsAvailableToReview?.length) {
      this.noItemsTimeout = setTimeout(() => {
        this.$router.push({ name: 'closet-sections' })
      }, 5000)
    } else {
      this.init()
    }
  },
  methods: {
    init () {
      this.checked = this.reviewItems.slice()
      this.itemsToSwap = []
      this.$store.commit('review/CLEAR_REVIEWS')
    },
    submit () {
      this.reviewItems = this.checked.slice()
      this.navigateToItemReview(this.checked)
    },
    onSelectItem (packageItem) {
      const index = this.checked.findIndex(item => item.id === packageItem.id)
      if (index > -1) {
        this.checked.splice(index, 1)
      } else {
        this.checked.push(packageItem)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .mobile-button {
    padding: 12px;
    border-top: $default-border;
    background-color: $white;
    z-index: 2;
    position: fixed;
    bottom: 0;
    left: 0;
  }

  .list-padding {
    padding-bottom: 128px;
  }
</style>
