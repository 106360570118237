import { computed, ref } from 'vue'
import useVuexModule from '@shared/composables/vuexModule.js'
import { defineStore, storeToRefs } from 'pinia'
import { useStyleProfileStore } from '@/stores/styleProfile.js'
import { usePlans } from '@shared/composables/plans.js'

// TODO: Partial implementation (ENG-2859)
export const useSubscriptionStore = defineStore('subscription', () => {
  const { doNothing, ...vuex } = useVuexModule('subscribe', {
    defaults: {
      state: [
        'membershipPlans',
        'membershipPlanSelectedId'
      ],
      getters: [
        'membershipPlanSelected',
        ['membershipPlanFromId', () => doNothing]
      ],
      actions: [
        'fetchPlans',
        'selectMembershipPlan'
      ]
    }
  })

  const {
    membershipPlanSelectedId: selectedPlanId,
    membershipPlanSelected: selectedPlan,
    membershipPlanFromId
  } = vuex

  const { planChoiceFrequency, planChoiceSpend } = storeToRefs(useStyleProfileStore())

  const recommendedPlanId = computed(() => {
    const { fourItemPlan, sevenItemPlan, unlimitedPlan } = usePlans()
    const fourItemPlanId = fourItemPlan.value?.id
    const sevenItemPlanId = sevenItemPlan.value?.id
    const unlimitedPlanId = unlimitedPlan.value?.id
    const map = {
      few_per_month: {
        '100_or_less': fourItemPlanId,
        '100_to_200': sevenItemPlanId,
        '200_or_more': unlimitedPlanId
      },
      once_per_week: {
        '100_or_less': sevenItemPlanId,
        '100_to_200': sevenItemPlanId,
        '200_or_more': unlimitedPlanId
      },
      several_per_week: {
        '100_or_less': sevenItemPlanId,
        '100_to_200': unlimitedPlanId,
        '200_or_more': unlimitedPlanId
      }
    }
    return map[planChoiceFrequency.value]?.[planChoiceSpend.value] ?? unlimitedPlanId
  })
  const recommendedPlan = computed(() => membershipPlanFromId.value(recommendedPlanId.value))
  const isRejoiningMember = ref(false)

  return {
    ...vuex,
    selectedPlanId,
    selectedPlan,
    recommendedPlanId,
    recommendedPlan,
    isRejoiningMember
  }
})
