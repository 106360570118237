import { useSubscriptionStore } from '@/stores/subscribe.js'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export function usePlans () {
  const subscriptionStore = useSubscriptionStore()
  const { membershipPlans, selectedPlanId, recommendedPlanId } = storeToRefs(subscriptionStore)
  const { fetchPlans, selectMembershipPlan } = subscriptionStore

  const fourItemPlan = computed(() => membershipPlans.value?.find(plan => plan.name === 'Armoire Capsule 4 Item'))
  const sevenItemPlan = computed(() => membershipPlans.value?.find(plan => plan.name === 'Armoire Monthly 7 Item'))
  const unlimitedPlan = computed(() => membershipPlans.value?.find(plan => plan.name === 'Armoire Unlimited Monthly'))

  async function initializeMembershipPlans () {
    if (!membershipPlans.value) {
      await fetchPlans()
    }
  }

  async function ensurePlanIsSelected () {
    await initializeMembershipPlans()
    if (!selectedPlanId.value) {
      selectedPlanId.value = recommendedPlanId.value
    }
  }

  const route = useRoute()
  const router = useRouter()
  async function setRoutePlanQuery (planId = selectedPlanId.value) {
    if (planId && route.query.plan !== planId) {
      await router.replace({ query: { ...route.query, plan: planId } })
    }
  }

  return {
    membershipPlans,
    selectedPlanId,
    fourItemPlan,
    sevenItemPlan,
    unlimitedPlan,
    selectMembershipPlan,
    initializeMembershipPlans,
    ensurePlanIsSelected,
    setRoutePlanQuery
  }
}
