<template>
  <div>
    <div
      v-if="isMobile"
      class="row g-0">
      <div
        v-if="title.length"
        class="col col-12 small-text bg-origami p-3 border-bottom text-center">
        {{ title }}
        <p
          v-if="subtitle.length"
          class="mb-0">
          {{ subtitle }}
        </p>
      </div>
      <div class="col col-12 small-text justify-content-center text-center">
        <slot/>
      </div>
      <div
        v-for="packageItem in packageItems"
        :key="packageItem.id"
        class="col col-12 px-0">
        <review-package-item-card
          data-intercom-target="intercom-review-item"
          :selectable="selectable"
          :package-item="packageItem"
          :selected="isSelected(packageItem)"
          @selected="$emit('selected', packageItem)"/>
      </div>
    </div>
    <div
      v-else
      class="mt-5">
      <div class="d-flex align-items-center mt-3 mb-5">
        <back-button
          v-if="showBackButton"
          class="back-button"
          :back-default-route="{ name: 'closet' }"
          with-border/>
        <div class="flex-grow-1">
          <h4 class="mb-0 text-center">
            {{ title }}
          </h4>
        </div>
        <slot name="headerSlotDesktop"/>
      </div>
      <slot/>
      <div class="row mt-4">
        <div
          v-for="packageItem in packageItems"
          :key="packageItem.id"
          class="col col-6 mb-3">
          <review-package-item-card
            data-intercom-target="intercom-review-item"
            :selectable="selectable"
            :package-item="packageItem"
            :selected="isSelected(packageItem)"
            @selected="$emit('selected', packageItem)"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackButton from '../global/sequin/BackButton.vue'
import ReviewPackageItemCard from './ReviewPackageItemCard'

export default {
  name: 'ReviewSelectItems',
  components: { BackButton, ReviewPackageItemCard },
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      default: ''
    },
    packageItems: {
      type: Array,
      required: true
    },
    selectedItems: {
      type: Array,
      required: true
    },
    selectable: {
      type: Boolean,
      default: true
    },
    showBackButton: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    isSelected (packageItem) {
      return this.selectedItems.some(item => item.id === packageItem.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.back-button {
  position: absolute;
  z-index: $zindex-sticky;
  top: 0;
  left: 15px;
}
</style>
