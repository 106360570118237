<template>
  <SignUpScreen
    v-if="!showLoading"
    heading="What makes you most excited about clothing rental?"
    subheading="Select all that apply."
    @next="handleNext">
    <div class="pill-container gap-sm mx-auto d-flex flex-wrap">
      <SignUpPill
        v-for="pill in styleProfile.signupReasonOptions"
        :key="pill.value"
        v-model="reasonSelection"
        :text="pill.text"
        :class="{ 'w-100': isMobile }"
        type="checkbox"
        :value="pill.value"/>
    </div>
  </SignUpScreen>
  <LoadingStylesScreen v-else/>
</template>

<script setup>
import SignUpScreen from '@/views/signUp/SignUpScreen.vue'
import SignUpPill from '@/components/signUp/SignUpPill.vue'
import LoadingStylesScreen from '@/views/signUp/LoadingStylesScreen.vue'
import { useStyleProfileStore } from '@/stores/styleProfile.js'
import { useSignUpNavigationStore } from '@/stores/signUpNavigation.js'
import { computed, ref } from 'vue'
import { useLogger } from 'vue-logger-plugin'
import { captureException } from '@sentry/vue'

const styleProfile = useStyleProfileStore()
const reasonSelection = computed({
  get () {
    return styleProfile.signupReasons
  },
  set (value) {
    styleProfile.setSignupReasons(value)
  }
})
const showLoading = ref(false)

function timeout (ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

const { next } = useSignUpNavigationStore()

const handleNext = async (event) => {
  event.preventDefault()
  showLoading.value = true
  try {
    await timeout(7000)
    next()
  } catch (error) {
    useLogger.error(error)
    captureException(error)
  }
}

</script>

<style lang="scss" scoped>
.pill-container {
  max-width: 636px;
  justify-content: center;
}
</style>
