import { getFedExOptIn } from '@shared/api/fedEx.js'
import { defineStore } from 'pinia'
import useVuexModule from '@shared/composables/vuexModule.js'

// TODO: Partial implementation (ENG-2846)
export const useClientStore = defineStore('client', () => {
  const vuex = useVuexModule('client', {
    defaults: {
      state: [
        ['isAuthenticated', false],
        ['username', ''],
        ['email', ''],
        'authToken',
        ['membershipStatus', ''],
        'styleProfileId',
        ['returnCarrierPreference', 'FEDEX'],
        'shippingAddress',
        'pickupAddress',
        ['showFedexSuccessModal', false]
      ],
      actions: [
        'getClient'
      ]
    }
  })

  const { showFedexSuccessModal, returnCarrierPreference } = vuex

  async function fedExOptIn () {
    await getFedExOptIn()
    returnCarrierPreference.value = 'FEDEX'
    showFedexSuccessModal.value = true
  }

  return {
    ...vuex,
    fedExOptIn
  }
})
