<template>
  <div class="px-md-4 py-md-4 pb-4 pt-0">
    <div class="d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <subscribe-step-indicator
          class="me-2"
          :variant="stepVariant"
          :step="1"/>
        <h4 class="mb-0">
          Shipping Address
        </h4>
      </div>
      <text-link
        v-if="!expanded"
        :disabled="formDisabled"
        @click="$emit('edit-click')">
        edit
      </text-link>
    </div>
    <address-form
      v-if="expanded"
      :address="shippingAddress"
      :show-phone-number-input="showPhoneNumberInput"
      class="mt-2"
      @address-added-updated="$emit('address-added')"/>
    <address-wrapper
      v-else-if="shippingAddress"
      class="d-block small-text"
      :address="shippingAddress"/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AddressWrapper from '../global/address/AddressWrapper'
import AddressForm from './AddressForm'
import TextLink from '../global/sequin/TextLink'
import SubscribeStepIndicator from './SubscribeStepIndicator'

export default {
  components: {
    AddressWrapper,
    AddressForm,
    SubscribeStepIndicator,
    TextLink
  },
  props: {
    expanded: {
      type: Boolean,
      default: false
    },
    formDisabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('client', [
      'shippingAddress',
      'oauthLogin'
    ]),
    stepVariant () {
      return this.expanded
        ? 'in-progress'
        : this.shippingAddress ? 'complete' : 'to-do'
    },
    showPhoneNumberInput () {
      return this.oauthLogin
    }
  }
}
</script>
