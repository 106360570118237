import axios from './util/axios.js'

export function getStylingInfo () {
  return axios.get('/api/closets/get_styling_info/')
}

export function getStylistClosets () {
  return axios.get('/api/closets/get_stylist_closets/')
}

export function postSaveStylingNote (data) {
  return axios.post('/api/closets/save_styling_note/', data)
}

export function postStylingRequest (data) {
  const username = data.username
  return axios.post(`/api/clients/${username}/request_stylist/`, data)
}

export function postStylistCloset (data) {
  return axios.post('/api/closets/publish_closet/', data)
}

export function postAddStyleColor (data) {
  return axios.post('/api/closets/add_item_to_closet/', data)
}

export function postRemoveStyleColor (data) {
  return axios.post('/api/closets/remove_item_from_closet/', data)
}

export function postAddClientNote (data) {
  return axios.post('/api/closets/add_client_notes/', data)
}

export function postDeleteClientNote (data) {
  return axios.post('/api/closets/delete_client_notes/', data)
}

export function postAddItemToStylistPackage (data) {
  return axios.post('/api/closets/add_stylist_package_item/', data)
}

export function postRemoveItemFromStylistPackage (data) {
  return axios.post('/api/closets/remove_stylist_package_item/', data)
}

export function postFinishStylistPackage (data) {
  return axios.post('/api/closets/finish_stylist_package/', data)
}

export function postDeleteStylistCloset (data) {
  return axios.post('/api/closets/delete_stylist_closet/', data)
}
