<template>
  <div>
    <div
      v-if="disabled"
      class="d-flex w-100">
      <input
        class="calendar-input"
        :disabled="disabled"
        :value="toMonthDayYearString(modelValue)"
        v-on="inputEvents">
      <plain-button
        class="calendar-button"
        :disabled="disabled"
        aria-label="Date picker"
        :class="{disabled: disabled}">
        <svg-calendar/>
      </plain-button>
    </div>
    <div
      v-else>
      <!-- TODO: use v-model attribute for v-date-picker once compat mode is disabled -->
      <v-date-picker
        v-bind="$attrs"
        :model-value="modelValue"
        mode="date"
        class="date-picker"
        :popover="{ placement: 'bottom', visibility: 'click' }"
        :select-attribute="selectAttribute"
        @update:modelValue="$emit('update:modelValue', $event)">
        <template #default="{ inputValue, inputEvents }">
          <div class="d-flex w-100">
            <input
              class="calendar-input"
              :placeholder="placeholder"
              :value="inputValue"
              v-on="inputEvents">
            <plain-button
              class="calendar-button"
              aria-label="Date picker"
              :class="{disabled: disabled}">
              <svg-calendar/>
            </plain-button>
          </div>
        </template>
      </v-date-picker>
    </div>
  </div>
</template>

<script>
import { DatePicker as VDatePicker } from 'v-calendar'
import PlainButton from './PlainButton'
import { toMonthDayYearString } from '@/utils/stringParsing.js'
import SvgCalendar from '@/components/global/svg/SvgCalendar.vue'

export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  },
  components: {
    PlainButton,
    VDatePicker,
    SvgCalendar
  },
  props: {
    modelValue: {
      type: Date,
      default: () => null
    },
    placeholder: {
      type: String,
      default: 'Choose a date'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  data () {
    return {
      selectAttribute: {
        highlight: {
          class: 'bg-primary'
        }
      }
    }
  },
  methods: {
    toMonthDayYearString
  }
}
</script>

<style lang="scss" scoped>
@import 'v-calendar/style.css';

.date-picker {
  :global(.vc-popover-content-wrapper) {
    z-index: $zindex-popover;
  }

}
.calendar-input {
  border-radius: $sequin-border-radius 0 0 $sequin-border-radius;
  width: 100%;
  border: $default-border;
  padding: 4px 8px;
  font-size: $small-text-font-size;
  outline: none;
}

.calendar-button {
  padding: 8px;
  background: $primary;
  border-radius: 0 $sequin-border-radius $sequin-border-radius 0;
  color: $white;

  &.disabled {
    background: $gray;
  }
}
</style>
