<template>
  <div
    v-if="!packageItemsLoaded"
    class="text-center">
    <svg-spinner
      class="mt-2"
      height="40"/>
  </div>
  <div
    v-else-if="itemPurchased"
    class="text-center semi-bold">
    You purchased this item
  </div>
  <closet-item-type-with-me
    v-else-if="clientItemTypeStatus === 'with-me'"
    class="item-status"
    :item-to-purchase="getItemToPurchase(selectedItemType)"/>
  <div v-else-if="!clientHasPurchased">
    <div
      class="d-flex flex-column">
      <closet-item-type-add-to-case
        v-if="styleColor.rentable || !hasRentalPlan"
        :selected-item-type="selectedItemType"
        :item-in-case="itemInCase"
        :item-available="itemTypeAvailable(selectedItemType)"
        @add-click="addToCase"
        @remove-click="removeFromCase"
        @mustSwap-click="swapToCase"/>
      <closet-item-type-buy-now
        v-if="itemCanBuyNow && hasRentalPlan"
        class="mt-2 mt-md-3"
        :selected-item-type="selectedItemType"
        :style-color="styleColor"/>
    </div>
    <closet-item-type-cannot-select v-if="showCannotSelect"/>
    <closet-item-type-in-transit
      v-if="clientItemTypeStatus === 'in-transit'"
      :item-type-shipped-package="getTransitItemShippedPackage(selectedItemType)"/>
  </div>
  <div
    v-else-if="clientHasPurchased"
    class="text-center">
    <h6>You purchased this item</h6>
  </div>
</template>

<script>
import { useStylingStore } from '@/stores/styling.js'
import { mapGetters, mapState as mapVuexState, mapActions as mapVuexActions } from 'vuex'
import { mapState, mapActions } from 'pinia'
import ClosetItemTypeAddToCase from './ClosetItemTypeAddToCase'
import ClosetItemTypeInTransit from './ClosetItemTypeInTransit'
import ClosetItemTypeWithMe from './ClosetItemTypeWithMe'
import ClosetItemTypeCannotSelect from './ClosetItemTypeCannotSelect'
import ClosetItemTypeBuyNow from './ClosetItemTypeBuyNow'
import { ItemTypeStatusMixin } from './ItemTypeStatusMixin'
import SvgSpinner from '@/components/global/svg/SvgSpinner.vue'
import { vuexAccessors } from '@/components/global/helpers/vuex.js'

export default {
  components: {
    ClosetItemTypeAddToCase,
    ClosetItemTypeCannotSelect,
    ClosetItemTypeInTransit,
    ClosetItemTypeWithMe,
    ClosetItemTypeBuyNow,
    SvgSpinner
  },
  mixins: [ItemTypeStatusMixin],
  props: {
    selectedItemType: {
      type: Object,
      default: null
    },
    styleColor: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapVuexState('closet', [
      'packageItemsLoaded',
      'soldPackingItems'
    ]),
    ...mapVuexState('client', [
      'closetItemNumber',
      'membershipStatus',
      'membershipDetails'
    ]),
    ...mapState(useStylingStore, [
      'stylistCuratedCaseInProgress'
    ]),
    ...mapGetters('client', [
      'canSelectItems',
      'nextShipmentDate'
    ]),
    ...mapGetters('review', [
      'isReviewOOC'
    ]),
    ...vuexAccessors('review', [
      'itemToAddAfterSwap'
    ]),
    clientItemTypeStatus () {
      return this.getClientItemTypeStatus(this.selectedItemType)
    },
    itemPurchased () {
      if (!this.selectedItemType) { return false }
      return this.soldPackingItems.filter(x => x.itemType === this.selectedItemType.id).length > 0
    },
    itemCanBeAddedOrRemoved () {
      return this.selectedItemType && this.itemTypeAvailable(this.selectedItemType) && this.canSelectItems && this.styleColor.rentable
    },
    clientHasPurchased () {
      return this.clientItemTypeStatus === 'sold'
    },
    showCannotSelect () {
      if (this.itemCanBuyNow || this.membershipStatus === 'former_member') return false
      return !this.itemCanBeAddedOrRemoved || this.isReviewOOC
    }
  },
  methods: {
    ...mapVuexActions('closet', [
      'select',
      'deselect'
    ]),
    ...mapActions(useStylingStore, [
      'addStylistPackageItem',
      'removeStylistPackageItem'
    ]),
    async addToCase () {
      this.stylistCuratedCaseInProgress
        ? await this.addStylistPackageItem(this.selectedItemType.id)
        : this.select(this.selectedItemType)
    },
    async removeFromCase () {
      this.stylistCuratedCaseInProgress
        ? await this.removeStylistPackageItem(this.selectedItemType.id)
        : this.deselect(this.selectedItemType)
    },
    swapToCase () {
      this.itemToAddAfterSwap = this.selectedItemType
    }
  }
}
</script>
