import getKeyedPromise from '@/utils/keyedPromise.js'
import { isBeta, isProd } from '@shared/utils/environment.js'
import { ref } from 'vue'
import { useLogger } from 'vue-logger-plugin'

export default function useStripe () {
  const logger = useLogger()

  const stripe = ref(null)
  const card = ref(null)
  const elements = ref(null)

  function loadStripe (scriptId = '_stripe-checkout-script') {
    if (typeof window.Stripe === 'undefined') {
      const script = document.createElement('script')
      script.id = scriptId
      script.src = 'https://js.stripe.com/v3/'
      script.type = 'text/javascript'
      document.head.appendChild(script)
    }
  }

  async function untilStripeLoaded () {
    return getKeyedPromise('stripe', async (resolve) => {
      const checkIfStripeLoaded = () => {
        if (typeof window.Stripe !== 'undefined') {
          resolve()
        } else {
          logger.info('Stripe not loaded yet, trying again in 1 second')
          setTimeout(checkIfStripeLoaded, 1000)
        }
      }
      checkIfStripeLoaded()
    })
  }

  async function initializeStripe (cardElementId = null) {
    logger.info('initializeStripe()', cardElementId)

    const useProdKey = isProd || isBeta
    const key = useProdKey
      ? process.env.VUE_APP_STRIPE_KEY_PROD
      : process.env.VUE_APP_STRIPE_KEY_TEST
    logger.info(`Initializing Stripe with key for ${useProdKey ? 'prod' : 'test'}`)
    stripe.value = window.Stripe(key)
    elements.value = stripe.value.elements()

    if (cardElementId) {
      createCard(cardElementId)
    }

    logger.info('Stripe initialized')
    return elements.value
  }

  function createCard (cardElementId) {
    if (!card.value) {
      const style = {
        base: {
          color: '#32325d',
          lineHeight: '18px',
          fontFamily: 'Open Sans, "Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4'
          }
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a'
        }
      }
      card.value = elements.value.create('card', { style })
    }
    card.value.mount(`#${cardElementId}`)

    card.value.addEventListener('change', (event) => {
      const displayError = document.getElementById('card-errors')
      if (displayError) {
        if (event.error) {
          displayError.textContent = event.error.message
        } else {
          displayError.textContent = ''
        }
      }
    })
  }

  return {
    stripe,
    card,
    elements,
    loadStripe,
    untilStripeLoaded,
    initializeStripe,
    createCard
  }
}
