<template>
  <div class="style-game-end-wrapper d-flex flex-column align-items-center h-100">
    <h3 class="mb-md">
      Great style!
    </h3>
    <h5 class="mb-xl">
      You nailed our vibe check.
    </h5>
    <BaseButton
      text="Next"
      fixed-width
      class="mb-xxl"
      :to="nextScreenRouterLink"/>
  </div>
</template>

<script setup>
import BaseButton from '@shared/components/ADORN/BaseButton.vue'
import { useSignUpNavigationStore } from '@/stores/signUpNavigation'

const { nextScreenRouterLink } = useSignUpNavigationStore()
</script>

<style lang="scss" scoped>
.style-game-end-wrapper {
  margin-top: 200px;
}
</style>
