import useLink from '@shared/composables/link.js'
import { useClientStore } from '@shared/stores/client.js'
import { useNavBarStore } from '@shared/stores/navBar.js'
import { storeToRefs } from 'pinia'
import { toValue } from 'vue'
import { useRouter } from 'vue-router'

export default function useBackNavigation (useDefaultFallback = true) {
  const router = useRouter()

  const { canGoBack } = storeToRefs(useNavBarStore())
  const { isAuthenticated } = storeToRefs(useClientStore())

  const { activate: goHome } = useLink(() => isAuthenticated.value ? { name: 'home' } : '/')

  /**
   * Navigate back in the browser history or activate a fallback route
   * if there is no history to go back to.
   * @param {import('vue-router').RouteLocation | string} fallbackRoute
   */
  function goBack (fallbackRoute = null) {
    if (canGoBack.value) {
      router.back()
    } else if (fallbackRoute) {
      router.push(fallbackRoute)
    } else if (toValue(useDefaultFallback)) {
      goHome()
    }
  }

  return {
    canGoBack,
    goBack
  }
}
