import { storeToRefs, defineStore } from 'pinia'
import { ref, computed, watch } from 'vue'
import { useStorage } from '@vueuse/core'
import { useReturnCarrier } from '@/composables/returnCarrier.js'
import useAnalytics from '@shared/composables/analytics.js'
import { useAccountStore } from '@/stores/account.js'
import { useClientStore } from '@shared/stores/client.js'
import { useModals } from '@/composables/modals.js'
import { useClosetStore } from '@shared/stores/closet.js'

export const useFedExOptInStore = defineStore('fedExOptIn', () => {
  const { fedexPreferred } = useReturnCarrier()
  const { track } = useAnalytics()
  const { selectReturnCarrierPreference } = useAccountStore()
  const { openFedExModal, closeFedExModal: closeModal } = useModals()
  const { showFedexSuccessModal: showSuccessModal } = storeToRefs(useClientStore())
  const { fedExOptIn, updateClient } = useClientStore()
  const { pickupConfirmationCode } = storeToRefs(useAccountStore())
  const { toReturnItems } = storeToRefs(useClosetStore())

  const showSuccessMessageCase = ref(false)
  const timeFedExDismissed = useStorage('timeFedExDismissed', null)
  const justSwitched = ref(false)
  const bannerDismissed = ref(false)
  const showSuccessBanner = ref(false)
  const showFedExBanner = computed(() => !fedexPreferred.value && timeFedExDismissed.value && !bannerDismissed.value && !justSwitched.value)
  const itemsToReturn = computed(() => toReturnItems.value.length > 0)
  // If the user is opted into FedEx, has a case due (aka items to return), and has not already scheduled a pickup, they would see the pickup banner
  const showFedExPickupBanner = computed(() => fedexPreferred.value && itemsToReturn.value && !pickupConfirmationCode.value && !bannerDismissed.value)

  async function optIntoFedEx (source) {
    if (['modal', 'returns'].includes(source)) {
      await fedExOptIn() // sets returnCarrierPreference to FedEx + showFedexSuccessModal to true
      checkFedExModals()
    } else {
      await selectReturnCarrierPreference('FEDEX') // sets the returnCarrierPreference to FedEx
      if (source === 'banner') {
        showSuccessBanner.value = true
      }
      if (source === 'case') {
        showSuccessMessageCase.value = true
      }
    }
    removeTimeFedExDismissed()
  }

  function dismissFedExBanner (type) {
    switch (type) {
      case 'success':
        showSuccessBanner.value = false
        break
      case 'opt-in':
        bannerDismissed.value = true
        track('Dismissed FedEx Opt-In Banner')
        break
      case 'pickup':
        bannerDismissed.value = true
        break
      default:
        break
    }
  }

  function removeTimeFedExDismissed () {
    if (timeFedExDismissed.value) {
      localStorage.removeItem('timeFedExDismissed')
      timeFedExDismissed.value = null
    }
  }

  function setTimeFedExDismissed () {
    const now = new Date()
    localStorage.setItem('timeFedExDismissed', now)
    timeFedExDismissed.value = now
  }

  function checkFedExModals () {
    if (justSwitched.value) {
      justSwitched.value = false
    }
    if (fedexPreferred.value) {
      removeTimeFedExDismissed()
      if (showSuccessModal.value) {
        openFedExModal({ success: true })
      }
      return
    }
    if (!timeFedExDismissed.value && !justSwitched.value) {
      openFedExModal()
    }
    const daysDiff = (new Date() - new Date(timeFedExDismissed.value)) / (1000 * 3600 * 24)
    if (daysDiff >= 7) {
      openFedExModal()
    }
  }

  function closeFedExModal (success) {
    if (!success) {
      setTimeFedExDismissed()
      track('Dismissed FedEx Opt-In Notification')
    }
    showSuccessModal.value = false
    closeModal()
    updateClient({ showFedexSuccessModal: false })
  }

  function setJustSwitched () {
    justSwitched.value = true
  }

  watch(showSuccessMessageCase, (value) => {
    if (value) {
      setTimeout(() => {
        showSuccessMessageCase.value = false
      }, 5000)
    }
  })

  return {
    optIntoFedEx,
    closeFedExModal,
    checkFedExModals,
    removeTimeFedExDismissed,
    timeFedExDismissed,
    showSuccessModal,
    showFedExBanner,
    bannerDismissed,
    showSuccessBanner,
    dismissFedExBanner,
    setTimeFedExDismissed,
    justSwitched,
    setJustSwitched,
    fedexPreferred,
    showSuccessMessageCase,
    showFedExPickupBanner
  }
})
