<template>
  <div class="d-flex align-items-center">
    <div class="d-flex align-items-center mb-0">
      <svg-star
        class="d-inline pe-1"
        :class="(styleColor?.numStyleStarRatings) ? 'filled-star': 'unrated-star'"/>
      <h6
        v-if="styleColor?.numStyleStarRatings"
        class="d-inline mb-0 pb-0 pe-2">
        {{ styleColor.avgStyleStarRating }}
      </h6>
      <div class="m-0">
        <text-link
          v-if="link"
          class="pr-3"
          @click="onRatingsLinkClick">
          {{ ratingsText }}
        </text-link>
        <span
          v-else
          class="small-text">
          {{ ratingsText }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { ReviewMixin } from './ReviewMixin'
import SvgStar from '@/components/global/svg/SvgStar.vue'
import TextLink from '@/components/global/sequin/TextLink'

export default {
  components: {
    SvgStar,
    TextLink
  },
  mixins: [ReviewMixin],
  props: {
    styleColor: {
      type: Object,
      required: true
    },
    dense: {
      type: Boolean,
      default: false
    },
    link: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ratingsText () {
      const num = this.styleColor?.numStyleStarRatings
      if (this.dense) {
        return `(${num})`
      }
      return num ? `out of ${num} rating${num === 1 ? '' : 's'} & review${num === 1 ? '' : 's'}` : '(0 ratings & reviews)'
    }
  },
  methods: {
    onRatingsLinkClick () {
      this.$emit('ratings-click')
    }
  }
}

</script>
<style lang="scss" scoped>
  .unrated-star {
    fill: rgba($ash, .5);
    stroke-width: 0;
  }
  .filled-star {
    fill: $secondary;
    stroke-width: 0;
  }
</style>
