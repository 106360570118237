<template>
  <div>
    <div
      class="modal-backdrop show"
      :style="{'z-index': modalZIndex}"/>
    <transition
      appear
      name="fade">
      <div
        class="modal show"
        :style="{ zIndex: modalZIndex }"
        tabindex="-1"
        role="dialog"
        @click="onModalCloseFromOutside">
        <div
          :class="{[dialogClass]: !!dialogClass, 'no-margins': fullScreen, 'ui-refresh': uiRefresh }"
          class="modal-dialog modal-dialog-centered"
          :style="{ width }"
          role="document">
          <div
            class="modal-content"
            :class="{ 'full-screen': fullScreen }">
            <div
              class="modal-header"
              :class="{ 'title-border': useTitleBorder }">
              <div
                class="w-100"
                :class="{ 'text-center': centerTitle }">
                <slot name="title"/>
              </div>
              <plain-button
                v-if="!hideClose"
                type="button"
                class="close-button"
                data-intercom-target="intercom-modal-exit"
                @click="onModalClose">
                <svg-x/>
              </plain-button>
            </div>
            <div
              class="scrollable"
              :class="{ 'flush-content': flushContent, 'px-3 pb-4': useBodyPadding }">
              <div
                class="container-fluid"
                :class="{ 'flush-content': flushContent }">
                <slot name="body"/>
              </div>
            </div>
            <div
              v-if="!noFooter"
              class="footer"
              :class="{ 'full-screen': fullScreen, 'px-3 py-2': uiRefresh }">
              <slot name="footer"/>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useOverlaysStore } from '@/stores/overlays.js'

import PlainButton from './sequin/PlainButton.vue'
import SvgX from './svg/SvgX.vue'

export default {
  components: {
    PlainButton,
    SvgX
  },
  props: {
    dialogClass: {
      type: String,
      default: ''
    },
    hideClose: {
      type: Boolean,
      default: false
    },
    stayOpenOnOutsideClick: {
      type: Boolean,
      default: false
    },
    useBodyPadding: {
      type: Boolean,
      default: true
    },
    useTitleBorder: {
      type: Boolean,
      default: false
    },
    centerTitle: {
      type: Boolean,
      default: true
    },
    flushContent: {
      type: Boolean,
      default: false
    },
    noFooter: {
      type: Boolean,
      default: false
    },
    fullScreen: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: null
    },
    // For the modal UI refresh that includes rounded corners and a few other subtle changes.
    // TA-3141: Remove this prop once all modals are updated.
    uiRefresh: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(useOverlaysStore, [
      'modalZIndex'
    ]),
    routePath () {
      return this.$route.path
    }
  },
  watch: {
    routePath () {
      this.closeModal()
    }
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'closeModal'
    ]),
    onModalCloseFromOutside (e) {
      if (e.target === e.currentTarget && !this.stayOpenOnOutsideClick) {
        this.onModalClose(e)
      }
    },
    onModalClose (e) {
      e.stopPropagation()
      this.$emit('close-modal-clicked')
      this.closeModal()
    }
  }
}
</script>

<style lang="scss" scoped>
.close-button {
  padding: 0;
  position: absolute;
  top: 14px;
  right: 20px;
  z-index: $zindex-modal;
  color: $pewter;
}

.ui-refresh .close-button svg {
  width: 20px;
  height: 20px;
}

.modal {
  display: block;
  overflow-y: none;
  overflow-x: none;
  height: 100dvh;
}

.no-margins {
  margin: 0px !important;
}

.scrollable {
  overflow-y: scroll;
  @include hide-scrollbar;
}

.footer {
  border-top: $default-border;

  &.full-screen {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: $white;
  }
}

.ui-refresh .footer {
  border-top: $light-border;
}

.modal-content {
  position: relative;
  max-height: 95dvh;
  border-radius: 2px;
  border: $default-border;

  &.full-screen {
    width: 100vw;
    min-height: 100dvh;
    margin-top: $nav-bar-height-mobile;
  }
}

.ui-refresh .modal-content {
    border: none;
}

.ui-refresh .modal-content:not(.full-screen) {
    border-radius: 8px;
}

.title-border {
  border-bottom: $default-border;
}

.ui-refresh .title-border {
  border-bottom: $light-border;
}

.flush-content {
  padding: 0 !important;
}

.fit-content {
  max-width: fit-content;
}
</style>
