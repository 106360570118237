import useVuexModule from '@shared/composables/vuexModule.js'
import { defineStore } from 'pinia'

// TODO: Partial implementation (ENG-2849)
export const useCommunityStore = defineStore('community', () => {
  const vuex = useVuexModule('community')

  return {
    ...vuex
  }
})
