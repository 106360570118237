<template>
  <div class="reviews-summary">
    <div
      class="reviews-summary-wrapper"
      :class="{'reviews-summary-wrapper-desktop' : !isMobile}">
      <div
        class="d-flex reviews-summary-header justify-content-between align-items-center">
        <div
          class="d-flex align-items-center">
          <h4
            v-if="!isMobile"
            class="d-inline pe-2 mb-0">
            Reviews
          </h4>
          <closet-detail-star-rating
            :link="false"
            :style-color="styleColor"
            @ratings-click="onRatingsClick()"/>
        </div>
      </div>
      <div
        v-if="reviewSummary"
        class="mt-3">
        <h6>What Members Are Saying:</h6>
        {{ reviewSummary.summary }}
        <div
          class="mt-2">
          <svg-discover
            fill="#00516C"
            stroke="#00516C"
            :width="isMobile ? 25 : 15"
            :height="isMobile ? 25 : 15"/>
          <span
            class="summary-tag small-text ml-2">
            This summary is AI generated
          </span>
        </div>
        <hr>
      </div>
      <closet-review-filters
        v-model="_modelValue"
        :style-color="styleColor"/>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { ReviewMixin } from '../ReviewMixin'
import ClosetReviewFilters from './ClosetReviewFilters'
import ClosetDetailStarRating from '../ClosetDetailStarRating.vue'
import SvgDiscover from '@/components/global/svg/SvgDiscover.vue'

export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  },
  components: {
    ClosetReviewFilters,
    ClosetDetailStarRating,
    SvgDiscover
  },
  mixins: [ReviewMixin],
  props: {
    styleColor: {
      type: Object,
      required: true
    },
    modelValue: {
      type: Array,
      default: undefined
    }
  },
  emits: ['update:modelValue'],
  computed: {
    ...mapState('closet', [
      'styleReviewsSummariesMaps'
    ]),
    styleColorId () {
      return this.$router.currentRoute.value.params.id
    },
    reviewSummary () {
      const styleId = this.styleColor.style.id
      const summary = this.styleReviewsSummariesMaps[styleId]
      if (!summary) {
        this.getStyleReviewsSummary(this.styleColor.style.id)
      }
      return this.styleReviewsSummariesMaps[this.styleColor.style.id]
    },
    _modelValue: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods: {
    ...mapActions('closet', [
      'getStyleReviewsSummary'
    ]),
    onRatingsClick () {
      this.$emit('ratings-click')
    }
  }
}
</script>

<style lang="scss" scoped>

.summary-tag {
  color: $teal;
}

.reviews-summary {
    background-color: $origami;
    margin-left: 0;
}
.reviews-summary-wrapper {
  max-width: 100%;
  padding: 0 20px;
}
.reviews-summary-wrapper-desktop {
  // not using media queries because of the flyout
  max-width: 1120px;
  padding-left: 84px;
  padding-right: 40px;
  margin: 0 auto;
}
.reviews-summary-header {
    padding-top: 2rem;
    padding-bottom: 1rem;
}
.number-of-reviews {
    margin-bottom: 0;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
}
:deep(.horizontal-scroll) {
  padding-bottom: 0;
}

:deep(label) {
  margin-bottom: 0;
}

</style>
