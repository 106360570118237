<template>
  <div :class="['sign-up-page', { 'hide-background': hideBackground }]">
    <SignUpPageHeader/>
    <RouterView
      :key="$route.name"
      #default="{ Component }">
      <Transition
        name="fade"
        mode="out-in">
        <component :is="Component"/>
      </Transition>
    </RouterView>
  </div>
</template>

<script setup>
import SignUpPageHeader from '@/components/signUp/SignUpPageHeader.vue'
import { useSignUpNavigationStore } from '@/stores/signUpNavigation.js'
import { useStyleProfileStore } from '@/stores/styleProfile.js'
import { useSubscriptionStore } from '@/stores/subscribe.js'
import { useClientStore } from '@shared/stores/client.js'
import { storeToRefs } from 'pinia'
import { computed, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import useAnalytics from '@shared/composables/analytics'
import { toTitleCase } from '@/utils/stringParsing.js'
import { whenever } from '@vueuse/core'
import useMobileApp, { MobileAppMessage } from '@/composables/mobileApp.js'
import { useHideIntercomLauncher } from '@shared/composables/intercom.js'

useHideIntercomLauncher()

const signUpNav = useSignUpNavigationStore()
const styleProfile = useStyleProfileStore()
const { getStyleProfileOptions } = styleProfile
const { trackSignUpPageView } = useAnalytics()

// Keep the store in sync with the current route.
const route = useRoute()
watch(route, (to) => { signUpNav.goTo(to.name) }, { immediate: true })
watch(route, (to) => { trackSignUpPageView(`${toTitleCase(to.name)} Screen`) }, { immediate: true })

// Keep the router in sync with the current screen.
const router = useRouter()
const { currentScreenRouterLink } = storeToRefs(signUpNav)
watch(currentScreenRouterLink, (to) => { router.push(to) }, { immediate: true })

const subscriptionStore = useSubscriptionStore()
const { parseQueryParams } = subscriptionStore

const { isStyleProfileComplete, styleProfileLoading } = storeToRefs(styleProfile)
const { setStyleProfileComplete } = useClientStore()
const mobileApp = useMobileApp()
whenever(isStyleProfileComplete, () => {
  setStyleProfileComplete(true)
  mobileApp.post(MobileAppMessage.StyleProfileComplete)
})
whenever(() => !styleProfileLoading.value, () => {
  mobileApp.post(MobileAppMessage.StyleProfileLoaded)
})

onMounted(async () => {
  await parseQueryParams(route)
  await getStyleProfileOptions()
})

const hideBackground = computed(() => route.name === 'sign-up-intro')
</script>

<style lang="scss" scoped>
.sign-up-page {
  height: 100dvh;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr;

  &:not(.hide-background) {
    @include adorn-gradient;
  }
}
</style>
