<template>
  <div class="d-flex flex-column row-gap-lg">
    <review-single-select
      v-model="qualityIssue"
      label="How was the item's condition?"
      :options="trueFalseOptions"
      :validations="validations"/>
    <div
      v-if="review.qualityIssue"
      class="d-flex flex-column row-gap-lg">
      <div>
        <h5>Select all that apply</h5>
        <ReviewItemCheckboxOptions
          v-model="qualityChoices"
          :options="qualityOptions"/>
      </div>
      <div v-if="review.qualityChoices.includes('missing_items')">
        <h5>What items were missing?<span class="required-asterisk">*</span></h5>
        <ReviewItemCheckboxOptions
          v-model="missingItemsChoices"
          :options="missingItemsOptions"/>
      </div>
      <div>
        <h5>Where on the garment?</h5>
        <ReviewItemCheckboxOptions
          v-model="locationChoices"
          :options="locationOptions"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ReviewSingleSelect from './ReviewSingleSelect'
import ReviewItemCheckboxOptions from './ReviewItemCheckboxOptions.vue'

export default {
  components: {
    ReviewItemCheckboxOptions,
    ReviewSingleSelect
  },
  model: {
    prop: 'review',
    event: 'change'
  },
  props: {
    review: {
      type: Object,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    validations: {
      type: Object,
      default: () => {
        return { $touch () {} }
      }
    }
  },
  data () {
    return {
      trueFalseOptions: [
        {
          text: 'Great',
          value: 'no'
        },
        {
          text: 'Something\'s Wrong',
          value: 'yes'
        }
      ],
      qualityOptions: [
        {
          text: 'Torn / Ripped',
          value: 'torn_ripped'
        },
        {
          text: 'Wrinkled',
          value: 'wrinkled'
        },
        {
          text: 'Broken Zipper',
          value: 'broken_zipper'
        },
        {
          text: 'Buttons Falling Off',
          value: 'buttons_falling_off'
        },
        {
          text: 'Odors',
          value: 'odors'
        },
        {
          text: 'Stains',
          value: 'stains'
        },
        {
          text: 'Pilling',
          value: 'pilling'
        },
        {
          text: 'Stretched Out',
          value: 'stretched_out'
        },
        {
          text: 'Shrunken',
          value: 'shrunken'
        },
        {
          text: 'Missing Items',
          value: 'missing_items'
        },
        {
          text: 'Other',
          value: 'other'
        }
      ],
      fullLocationOptions: [
        { text: 'Shoulders', value: 'shoulders' },
        { text: 'Bust', value: 'bust' },
        { text: 'Waist', value: 'waist' },
        { text: 'Hips', value: 'hips' },
        { text: 'Rear', value: 'rear' },
        { text: 'Length', value: 'length' }
      ],
      locationOptionsMap: {
        tops: [
          { text: 'Shoulders', value: 'shoulders' },
          { text: 'Sleeves', value: 'sleeves' },
          { text: 'Bust', value: 'bust' },
          { text: 'Length', value: 'length' }
        ],
        outer: [
          { text: 'Shoulders', value: 'shoulders' },
          { text: 'Sleeves', value: 'sleeves' },
          { text: 'Bust', value: 'bust' },
          { text: 'Length', value: 'length' }
        ],
        dresses: [
          { text: 'Shoulders', value: 'shoulders' },
          { text: 'Bust', value: 'bust' },
          { text: 'Sleeves', value: 'sleeves' },
          { text: 'Waist', value: 'waist' },
          { text: 'Hips', value: 'hips' },
          { text: 'Rear', value: 'rear' },
          { text: 'Length', value: 'length' }
        ],
        jumpsuits: [
          { text: 'Shoulders', value: 'shoulders' },
          { text: 'Bust', value: 'bust' },
          { text: 'Sleeves', value: 'sleeves' },
          { text: 'Waist', value: 'waist' },
          { text: 'Hips', value: 'hips' },
          { text: 'Rear', value: 'rear' },
          { text: 'Length', value: 'length' }
        ],
        bottoms: [
          { text: 'Waist', value: 'waist' },
          { text: 'Hips', value: 'hips' },
          { text: 'Rear', value: 'rear' },
          { text: 'Length', value: 'length' }
        ]
      },
      missingItemsOptions: [
        {
          text: 'Belt',
          value: 'belt'
        },
        {
          text: 'Hood',
          value: 'hood'
        },
        {
          text: 'Slip',
          value: 'slip'
        }
      ]
    }
  },
  computed: {
    ...mapState('closet', [
      'styleColorsMap'
    ]),
    qualityIssue: {
      get () {
        return this.review.qualityIssue ? 'yes' : 'no'
      },
      set (qualityIssue) {
        const review = { ...this.review, qualityIssue }
        if (qualityIssue === 'no') {
          review.qualityIssue = false
          review.qualityChoices = []
        } else if (qualityIssue === 'yes') {
          review.qualityIssue = true
        }
        this.updateQualityRatings(review)
        this.$emit('change', review)
      }
    },
    missingItemsChoices: {
      get () {
        return this.review.missingItemsChoices
      },
      set (missingItemsChoices) {
        this.updateQualityRatings({ ...this.review, missingItemsChoices })
        this.$emit('change', { ...this.review, missingItemsChoices })
      }
    },
    locationChoices: {
      get () {
        return this.review.locationChoices
      },
      set (locationChoices) {
        const review = { ...this.review, locationChoices }
        this.updateQualityRatings(review)
        this.$emit('change', review)
      }
    },
    qualityChoices: {
      get () {
        return this.review.qualityChoices
      },
      set (qualityChoices) {
        const review = { ...this.review, qualityChoices }
        this.updateQualityRatings(review)
        this.$emit('change', review)
      }
    },
    department () {
      let department = this.styleColorsMap[this.item.styleColor].style.department
      // Is this necessary?
      if (department === '') department = 'dresses'

      return department
    },
    locationOptions () {
      return this.locationOptionsMap[this.department]
        ? this.locationOptionsMap[this.department]
        : this.fullLocationOptions
    }
  },
  methods: {
    updateQualityRatings (review) {
      review.damaged = review.qualityChoices.includes('damaged')
      review.worn = review.qualityChoices.includes('worn')
    }
  }
}
</script>
<style lang="scss" scoped>
@import './ReviewItem.scss';
</style>
