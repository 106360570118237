import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useClientStore } from '@shared/stores/client.js'

export const useReturnCarrier = () => {
  const { returnCarrierPreference, shippingAddress, pickupAddress } = storeToRefs(useClientStore())

  const fedexPreferred = computed(() => returnCarrierPreference.value === 'FEDEX')
  const upsPreferred = computed(() => returnCarrierPreference.value === 'UPS')
  const uspsPreferred = computed(() => returnCarrierPreference.value === 'USPS')
  const printReturnLabelLink = computed(() => {
    return shippingAddress.value?.printReturnLabelLink ??
    pickupAddress.value?.printReturnLabelLink ??
    ''
  })
  return {
    fedexPreferred,
    upsPreferred,
    uspsPreferred,
    printReturnLabelLink
  }
}
