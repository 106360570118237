<template>
  <component
    :is="collection.toRoute ? 'plain-button' : 'div'"
    type="router-link"
    :to="collection.toRoute"
    :name="`Link to ${collection.name} Collection`"
    :class="{ 'mobile': isMobile}"
    class="collection-preview-wrapper"
    @click="$emit('click')">
    <div
      class="collection-preview"
      :class="[titleVariant, { 'mobile': isMobile }]">
      <div
        v-if="availableCollectionPreviewImages.length"
        class="w-100 d-flex flex-column h-100">
        <div
          v-if="collectionPreviewImages.length === 1"
          class="d-flex w-100 flex-wrap justify-content-center align-items-start">
          <square-image
            v-for="image in collectionPreviewImages"
            :key="image"
            :alt="`Item from ${collection.name} Collection`"
            :width="heightPercentage"
            object-fit="cover"
            object-position="top center"
            :image-source="image"/>
        </div>
        <div
          v-else-if="collectionPreviewImages.length === 2"
          class="d-flex w-100 h-100">
          <img
            v-for="image in collectionPreviewImages"
            :key="image"
            :alt="`Item from ${collection.name} Collection`"
            class="two-up-image"
            :src="image">
        </div>
        <div
          v-else
          class="d-flex w-100 h-100">
          <div
            class="three-up-primary"
            :class="titleVariant">
            <img
              :alt="`Item from ${collection.name} Collection`"
              class="three-up-image"
              :src="collectionPreviewImages[0]">
          </div>
          <div
            class="three-up-secondary-container"
            :class="titleVariant">
            <div
              v-for="image in collectionPreviewImages.slice(1, maxPreviewLength)"
              :key="image"
              class="three-up-secondary">
              <img
                :alt="`Item from ${collection.name} Collection`"
                class="three-up-image"
                :src="image">
            </div>
          </div>
        </div>
        <div
          v-if="titleVariant === 'overlay'"
          class="title-background w-100 text-center flex-shrink-0"
          :class="[backgroundVariant, { 'mobile': isMobile }]">
          <svg-circle-icon
            v-if="isClientsCollection"
            class="preview-icon"
            inverse
            :padding="12"
            :width="32"
            :height="32"
            :stroke-width="1.5"
            variant="primary">
            <component :is="icon"/>
          </svg-circle-icon>
          <avatar-image
            v-if="collection.client"
            class="avatar-icon"
            :alt="`Profile image of ${collection.client?.firstName}`"
            size="small"
            :profile-pic="collection.client?.profilePic"
            :client-id="collection.client?.id"/>
          <text-line-limit
            tag="h6"
            class="title mb-1">
            {{ collection.name }}
          </text-line-limit>
          <p class="length mb-1">
            {{ collection.length }}
          </p>
          <p
            v-if="!isClientsCollection && collection.client"
            class="mb-0 fine-print text-pewter font-italic">
            Created by {{ collection.client?.firstName }}
          </p>
        </div>
      </div>
      <empty-collection-card
        v-else
        :icon="icon"
        :title-variant="titleVariant"
        :background-variant="backgroundVariant"
        :title="collection.name"
        subtitle="0 styles">
        <slot
          name="empty">
          <sequin-button
            v-if="collection.client?.id === id"
            small
            variant="secondary"
            type="router-link"
            :to="collection.toRoute">
            Add Styles
          </sequin-button>
        </slot>
      </empty-collection-card>
    </div>
    <div
      v-if="titleVariant === 'below'"
      class="mt-3 w-100">
      <text-line-limit
        tag="h6"
        class="title mb-1">
        {{ collection.name }}
      </text-line-limit>
      <p class="length mb-1">
        {{ `${collection.count} style${collection.count === 1 ? '' : 's'}` }}
      </p>
    </div>
  </component>
</template>

<script>
import AvatarImage from '../community/common/AvatarImage.vue'
import EmptyCollectionCard from './EmptyCollectionCard.vue'
import PlainButton from '../global/sequin/PlainButton.vue'
import SequinButton from '../global/sequin/SequinButton.vue'
import SquareImage from '../global/sequin/SquareImage.vue'
import TextLineLimit from '../global/sequin/TextLineLimit.vue'
import { mapState } from 'vuex'
import { mapActions } from 'pinia'
import useAnalytics from '@shared/composables/analytics.js'
import inViewport from 'vue-in-viewport-mixin'
import { useOverlaysStore } from '@/stores/overlays.js'
import SvgCircleIcon from '@/components/global/svg/SvgCircleIcon.vue'
import SvgBookmark from '@/components/global/svg/SvgBookmark.vue'

export default {
  components: {
    AvatarImage,
    EmptyCollectionCard,
    PlainButton,
    SequinButton,
    SquareImage,
    TextLineLimit,
    SvgCircleIcon
  },
  mixins: [inViewport],
  props: {
    collection: {
      type: Object,
      required: true
    },
    backgroundVariant: {
      type: String,
      validator: v => v.match('default|gradient|champagne'),
      default: 'default'
    },
    titleVariant: {
      type: String,
      validator: v => v.match('overlay|below'),
      default: 'overlay'
    },
    analyticsSource: {
      type: Object,
      default: () => {}
    },
    sendDefaultAnalyticsEvents: {
      type: Boolean,
      default: true
    },
    maxPreviewLength: {
      type: Number,
      default: 3
    }
  },
  setup () {
    const { track } = useAnalytics()
    return { track }
  },
  computed: {
    ...mapState('client', [
      'id'
    ]),
    availableCollectionPreviewImages () {
      return this.collection?.previewImages || []
    },
    collectionPreviewImages () {
      return this.availableCollectionPreviewImages.slice(0, this.maxPreviewLength)
    },
    heightPercentage () {
      return `${100 / Math.sqrt(this.collectionPreviewImages.length)}%`
    },
    icon () {
      return this.collection.previewIcon ?? SvgBookmark
    },
    isClientsCollection () {
      return this.collection.client?.id === this.id
    }
  },
  watch: {
    'inViewport.now': function (visible) {
      if (visible) {
        this.$emit('collection-in-view')
      }
    }
  },
  mounted () {
    if (this.sendDefaultAnalyticsEvents) {
      this.track('Viewed Collection Preview', {
        id: this.collection.id,
        name: this.collection.name,
        owner: this.collection.client?.id,
        source: this.analyticsSource
      })
    }
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'openModal'
    ])
  }
}
</script>

<style lang="scss" scoped>
@import './CollectionPreviewIcon.scss';

.collection-preview-wrapper {
  // width: 23.5%;
  flex-grow: 0;
  padding: 0;
}

.collection-preview {
  width: 100%;
  max-height: 292px;

  // Back ups for safari not supporting aspect ratio
  @supports not (aspect-ratio: 1) {
    height: 18vw;

    &.mobile {
      height: 40vw;
    }
  }

  @supports (aspect-ratio: 1) {
    aspect-ratio: 1;
  }

  &.overlay {
    height: 100%;

    @supports (aspect-ratio: 1) {
      aspect-ratio: .9;
    }
  }

  border-radius: 8px;
  border: $default-border;
  overflow: hidden;
  padding: 0;
  position: relative;
}

.three-up-primary {
  width: 50%;
  height: 68%;
  border-right: 1px solid $white;
  border-color: $white;
  flex-shrink: 0;

  &.below {
    height: 100%;
    width: 63%;
  }
}

.three-up-secondary-container {
  flex-shrink: 0;
  width: 50%;
  height: 68%;

  &.below {
    height: 100%;
    width: 37%;
  }
}

.three-up-secondary {
  width: 100%;
  height: 50%;
  border-bottom: 1px solid $white;
}

.three-up-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.two-up-image {
  width: 50%;
  object-fit: cover;
}

.avatar-icon {
  @extend .preview-icon;
  border: none;
  top: -22px;

  :deep(.avatar) {
    background-color: $white;
  }
}

.title-background {
  bottom: 0;
  position: absolute;
  background-color: $origami;
  padding: 28px 16px 16px;
  border-top: $default-border;

  &.mobile {
    padding: 24px 8px 8px;
  }

  .title {
    font-weight: $font-weight-semibold;
    font-size: $font-size-base;
  }

  .length {
    font-size: $small-text-font-size;
  }

  &.champagne {
    background-color: $champagne;
  }

  &.gradient {
    color: $white;
    @include sequin-gradient-linear;

    .length {
      color: $white;
    }
  }
}
</style>
