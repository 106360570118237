<template>
  <div class="row">
    <plain-button
      v-for="plan in plans"
      :key="plan.id"
      :disabled="disabled || plan.id === currentPlanId"
      class="col py-2 px-lg-1"
      @click="$emit('plan-selected', plan.id)">
      <plan-card
        :plan="plan"
        :selected="plan.id === selectedPlanId"
        :header-text="plan.id === currentPlanId ? 'Current Plan' : (!plan.prepayment && plan.shipmentsPerInterval === null) ? 'Best Value' : ''"/>
    </plain-button>
  </div>
</template>

<script setup>
import PlanCard from '../subscribe/PlanCard'
import PlainButton from '../global/sequin/PlainButton'
import useAnalytics from '@shared/composables/analytics.js'
import { onMounted } from 'vue'

const props = defineProps({
  header: {
    type: String,
    required: true
  },
  plans: {
    type: Array,
    required: true
  },
  selectedPlanId: {
    type: String,
    default: ''
  },
  disabled: {
    type: Boolean,
    default: false
  },
  currentPlanId: {
    type: String,
    default: ''
  }
})

const { track } = useAnalytics()
onMounted(() => {
  track('Viewed Plan Selector', {
    source: props.header,
    plans: props.plans.map(p => p.displayName)
  })
})
</script>
