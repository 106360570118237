<template>
  <button
    v-if="show"
    class="back-button d-flex align-items-center justify-content-center p-0"
    @click="() => goBack()">
    <SvgChevronLeft
      width="32"
      height="32"
      stroke-width="1"/>
  </button>
</template>

<script setup>
import SvgChevronLeft from '@/components/global/svg/SvgChevronLeft.vue'
import useBackNavigation from '@shared/composables/backNavigation.js'
import { computed } from 'vue'

const props = defineProps({
  allowFallback: {
    type: Boolean,
    default: false
  }
})

const { canGoBack, goBack } = useBackNavigation(() => props.allowFallback)
const show = computed(() => canGoBack.value || props.allowFallback)
</script>

<style lang="scss" scoped>
.back-button {
  border: none;
  background-color: transparent;
  color: inherit;
}
</style>
