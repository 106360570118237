<template>
  <div
    class="style-card">
    <PreviewImage
      :src="styleColor.images.front.url"
      size="auto"/>
    <div class="d-flex justify-content-between mx-xl my-sm">
      <IconButton
        class="card-buttons dislike"
        variant="primary"
        @click="emit('dislike')">
        <template #icon>
          <SvgDislike/>
        </template>
      </IconButton>
      <IconButton
        class="card-buttons favorite"
        variant="primary"
        @click="emit('favorite')">
        <template #icon>
          <SvgHeartNewOutline/>
        </template>
      </IconButton>
    </div>
    <div class="description text-center m-md">
      <TextLineLimit
        tag="h2">
        {{ styleColor.style.brand }}
      </TextLineLimit>
      <TextLineLimit
        :line-limit="2">
        {{ styleColor.style.name }}
      </TextLineLimit>
    </div>
  </div>
</template>

<script setup>
import PreviewImage from '@shared/components/ADORN/PreviewImage.vue'
import SvgDislike from '@/components/global/svg/SvgDislike.vue'
import IconButton from '@shared/components/ADORN/IconButton.vue'
import SvgHeartNewOutline from '@/components/global/svg/SvgHeartNewOutline.vue'
import TextLineLimit from '@/components/global/sequin/TextLineLimit.vue'

defineProps({
  styleColor: {
    type: Object,
    default: () => ({})
  }
})
const emit = defineEmits(['dislike', 'favorite'])

</script>

<style scoped lang="scss">
.style-card {
  position: absolute;
  top: 0;
  margin: auto;
  background-color: white;
  border-radius: 2px;
  max-height: 80dvh;
  width: min(100%, 80dvh * (350 / 750));
}

.card-buttons {
  border-radius: 100%;
  aspect-ratio: 1/1;
  @media screen and (max-height: 700px) {
    min-height: 48px;
    height: 48px;
    width: 48px;
    min-width: 48px;
  }
  svg {
    width: 48px;
    height: 48px;
    @media screen and (max-height: 700px) {
    height: 38px;
    width: 38px;
  }
  }
}
.dislike {
  background-color: $armor;
  color: #f8f8f8;
}
.favorite {
  background-color: $orchid;
  color: #f8f8f8;
}
:deep(.preview-image){
  border-radius: 0px 0px 2px 2px;
}
.description {
  height: 80px;
}
</style>
