import { computed } from 'vue'
import { useStore } from 'vuex'
import { useOverlaysStore } from '@/stores/overlays.js'

// Helper composable built on top of the overlays store for opening modals.
export function useModals () {
  const { openModal, closeModal } = useOverlaysStore()
  const store = useStore()

  const isAuthenticated = computed(() => store.state.client.isAuthenticated)

  function openCreateCollectionModal () {
    openModal({ name: 'create-collection' })
  }

  function openAddToCollectionModal (styleColorId) {
    if (isAuthenticated.value) {
      openModal({
        name: 'add-to-collection',
        context: { styleColorId }
      })
    } else {
      openClosetRatingInfoModal('collect')
    }
  }

  function openClosetRatingInfoModal (action) {
    openModal({
      name: 'closet-rating-info',
      context: { action }
    })
  }
  function openDeleteCollectionModal (collectionId) {
    openModal({
      name: 'delete-collection',
      context: { collectionId: collectionId }
    })
  }
  function openShareModal (shareContext) {
    openModal({
      name: 'share-style-color-set-page',
      context: { styleColorSetPageModal: shareContext }
    })
  }
  function openReferralQRCodeModal () {
    openModal({
      name: 'referral-qr-code'
    })
  }
  function openFedExModal (context) {
    openModal({
      name: 'fed-ex-opt-in',
      context
    })
  }
  function closeFedExModal () {
    closeModal({
      name: 'fed-ex-opt-in'
    })
  }

  return {
    openCreateCollectionModal,
    openAddToCollectionModal,
    openClosetRatingInfoModal,
    openDeleteCollectionModal,
    openShareModal,
    openReferralQRCodeModal,
    openFedExModal,
    closeFedExModal
  }
}
export default useModals
