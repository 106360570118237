import api from '../api/api'
import router from '../../router'
import { getSetters, parseError } from './helpers/shared'
import { serializeParams } from '../api/helpers/helpers'
import logger from '../../logger'
import getKeyedPromise from '@/utils/keyedPromise.js'
import { useStylingStore } from '@/stores/styling'
import { getMe } from '@shared/api/client.js'

// initial state
export const state = {
  // front end
  loaded: false,
  isUpdatingPayment: false,

  // client
  authToken: null,
  id: null,
  bio: null,
  balance: null,
  benefitsValue: null,
  canConfirmCase: true,
  canChangeShipping: true,
  canViewFeed: false,
  closetItemNumber: 4,
  defaultPayment: null,
  deliveryInstructions: '',
  email: '',
  firstCaseDueDate: null,
  firstName: '',
  hasReviewPhotos: false,
  isAuthenticated: false,
  isImpersonated: process.env.NODE_ENV === 'development'
    ? false
    : window.armoire.isImpersonated,
  impersonator: null,
  isStaff: false,
  lastName: '',
  lastCharge: null,
  logLevel: null,
  logToConsole: false,
  mainPhone: '',
  membershipDetails: null,
  membershipStatus: '',
  numReviewsWithPhoto: 0,
  numIncentivePhotosForBonusItem: 0,
  nextBillingDate: null,
  oauthLogin: false,
  packagingPreference: 'Standard',
  pickupAddress: null,
  profilePic: null,
  readyForStyling: false,
  referralCode: null,
  referrals: null,
  referralAwards: null,
  reportEvents: true,
  returnCarrierPreference: 'FEDEX',
  retailValueReceived: null,
  returnLabelEligible: true,
  selectedPackages: 0,
  settings: {},
  shippingAddress: null,
  shippingEligibility: null,
  shippedPackages: 0,
  signUpMethod: null,
  showMobileBanner: true,
  showFedexSuccessModal: false,
  state: 'closet',
  styleProfileId: null,
  styleProfileComplete: false,
  tooManyItemsOut: false,
  username: process.env.NODE_ENV === 'development'
    ? process.env.VUE_APP_USERNAME
    : window.armoireAppUserInfo
      ? window.armoireAppUserInfo.username
      : window.armoire?.user?.username ?? '',
  validatedShippingAddress: {},

  // Initially for segment
  babyDate: '',
  birthDate: '',
  caseSize: 0,
  clientHash: '',
  influencer: false,
  leadStage: '',
  paidThrough: '',
  plans: {},
  zipCode: '',
  // items
  transitPackages: []
}

export const getters = {
  hasPlanTierPermission: (state) => (permission) => {
    return state.plans?.currentBasePlan?.tier?.permissions.find(perm => permission === perm.name)
  },
  hasRentalPlan: (state, getters) => {
    return getters.hasPlanTierPermission('rent')
  },
  itemContainerText: (state, getters) => {
    return getters.hasPlanTierPermission('rent') ? 'Case' : 'Cart'
  },
  availExtraItemsSlots: (state, getters) => {
    return (state.closetItemNumber + getters.maxNumExtraItems) - getters.numSelectedItems
  },
  numAvailableCheckoutRecommendations: (state, getters, rootState, rootGetters) => {
    if (!getters.hasRentalPlan) { return 0 }
    if (!getters.availExtraItemsSlots) { return 0 }
    return rootGetters['case/availableCheckoutRecommendations'].length
  },
  numAvailablePurchaseSuggestions: (state, getters, rootState, rootGetters) => {
    if (!getters.hasRentaPlan) { return 0 }
    return rootGetters['case/availablePurchaseSuggestions'].length
  },
  maxNumExtraItems: (state) => {
    return state.membershipDetails?.maxBonusItemPurchases ?? 0
  },
  numSelectedItems: (state, getters, rootState, rootGetters) => {
    return rootGetters['closet/selectedAvailableItemTypes'].length
  },
  numBonusItems: (state, getters) => {
    return getters.bonusItems.length
  },
  numCaseSlots: (state, getters) => {
    return state.closetItemNumber + getters.numBonusItems
  },
  numStandardSlotsFilled: (state, getters) => {
    return Math.min(getters.numSelectedItems, getters.numStandardAndRolloverSlots)
  },
  rolloverItems: (state) => {
    if (Array.isArray(state.membershipDetails?.rolloverItems)) {
      return state.membershipDetails.rolloverItems
    }
    return []
  },
  numRolloverItems: (state, getters) => {
    return getters.rolloverItems.length
  },
  numStandardAndRolloverSlots: (state, getters) => {
    return state.closetItemNumber + getters.numRolloverItems
  },
  numAllSlots: (state, getters) => {
    return getters.numStandardAndRolloverSlots + getters.numBonusItems
  },
  numAllSlotsAvailable: (state, getters) => {
    return getters.numStandardSlotsAvailable + getters.numBonusItemSlotsAvailable
  },
  numAllSlotsFilled: (state, getters) => {
    return getters.numStandardSlotsFilled + getters.numBonusItemSlotsFilled
  },
  numStandardSlotsAvailable: (state, getters) => {
    return getters.numStandardAndRolloverSlots - getters.numStandardSlotsFilled
  },
  numRolloverSlotsAvailable: (state, getters) => {
    return Math.max(getters.numRolloverItems - getters.numSelectedItems, 0)
  },
  numPotentialNewRolloverSlots: (state, getters) => {
    return getters.numStandardAndRolloverSlots - getters.numStandardSlotsFilled
  },
  numBonusItemSlotsFilled: (state, getters) => {
    if (getters.numBonusItems > 0) {
      if (getters.numSelectedItems - state.closetItemNumber > 0) {
        return Math.min(getters.numSelectedItems - getters.numStandardSlotsFilled, getters.numBonusItems)
      }
    }
    return 0
  },
  numBonusItemSlotsAvailable: (state, getters) => {
    if (!getters.canSelectItems) return getters.numBonusItems
    return getters.numBonusItems - getters.numBonusItemSlotsFilled
  },
  numExtraSlotsValid: (state, getters) => {
    if (!getters.hasRentalPlan) { return 0 }
    return Math.min(getters.maxNumExtraItems, getters.numSelectedItems - (state.closetItemNumber + getters.numBonusItems))
  },
  numExtraSlotsOverLimit: (state, getters) => {
    if (!getters.hasRentalPlan) { return 0 }
    return getters.numSelectedItems - (getters.numStandardAndRolloverSlots + getters.numBonusItems + getters.maxNumExtraItems)
  },
  numFreeSlotsAvailable: (state, getters) => {
    return getters.numStandardSlotsAvailable + getters.numBonusItemSlotsAvailable
  },
  casePurchaseItems: (state, getters, rootState, rootGetters) => {
    if (getters.hasRentalPlan) return []
    return rootGetters['closet/selectedAvailableItemTypes']
  },
  selectedStandardItems: (state, getters, rootState, rootGetters) => {
    return rootGetters['closet/selectedAvailableItemTypes'].slice(0, getters.numStandardSlotsFilled)
  },
  selectedBonusItems: (state, getters, rootState, rootGetters) => {
    if (!getters.canSelectItems) return []
    return rootGetters['closet/selectedAvailableItemTypes'].slice(getters.numStandardSlotsFilled, getters.numStandardSlotsFilled + getters.numBonusItemSlotsFilled)
  },
  selectedExtraItemsValid: (state, getters, rootState, rootGetters) => {
    if (!getters.hasRentalPlan) { return [] }
    return rootGetters['closet/selectedAvailableItemTypes'].slice(getters.numStandardSlotsFilled + getters.numBonusItemSlotsFilled, getters.numStandardSlotsFilled + getters.numBonusItemSlotsFilled + getters.numExtraSlotsValid)
  },
  selectedExtraItemsOverLimit: (state, getters, rootState, rootGetters) => {
    if (!getters.hasRentalPlan) { return [] }
    const selectedAvailableItemTypes = rootGetters['closet/selectedAvailableItemTypes']
    return selectedAvailableItemTypes.slice(selectedAvailableItemTypes.length - getters.numExtraSlotsOverLimit, selectedAvailableItemTypes.length)
  },
  tooFewSelected: (state, getters) => {
    return getters.numSelectedItems === 0
  },
  pickupPackages: (state, getters, rootState) => {
    return state.transitPackages
      .filter(p => p.pickupFrom.name)
      .map(p => {
        return {
          ...p,
          packageItems: rootState.closet.transitItems.filter(transitItem => transitItem.package.id === p.id)
        }
      })
  },
  shippedPackages: (state, getters, rootState) => {
    return state.transitPackages
      .filter(p => !p.pickupFrom.name)
      .map(p => {
        return {
          ...p,
          packageItems: rootState.closet.transitItems.filter(transitItem => transitItem.package.id === p.id)
        }
      })
  },
  itemsInProcessing: (state, getters, rootState) => {
    return rootState.closet.packingItems.length || rootState.closet.pendingItems.length
  },
  itemsInTransit: (state, getters, rootState) => {
    return getters.itemsInProcessing || rootState.closet.transitItems.length
  },
  canSwapItems: (state, getters, rootState) => {
    if (!state.membershipDetails) { return false }
    return state.membershipDetails.allowedShipments !== 0 && !getters.itemsInTransit &&
      (rootState.closet.deliveredItems.length || rootState.closet.mustReviewItems.length)
  },
  active: (state) => {
    return state.membershipStatus === 'active'
  },
  bonusItems: (state) => {
    if (Array.isArray(state.membershipDetails?.bonusItems)) {
      return state.membershipDetails.bonusItems
    }
    return []
  },
  extraStylesItems: (state) => {
    if (Array.isArray(state.membershipDetails?.bonusItems)) {
      return state.membershipDetails.bonusItems.filter(item => item.variant === 'extra_styles')
    }
    return null
  },
  selectedReplacementItem: (state, getters, rootState) => {
    return rootState.client.settings.replacementItemChoice
  },
  canPickItems: (state, getters) => {
    if (getters.itemsInTransit) { return false }
    if (!state.membershipDetails) { return false }
    if (state.membershipDetails.allowedShipments === null) {
      return state.closetItemNumber > 0
    }
    return state.membershipDetails.allowedShipments && state.closetItemNumber > 0
  },
  canSelectItems: (state, getters, rootState, rootGetters) => {
    if (!getters.hasRentalPlan) return true
    return getters.canPickItems && !state.tooManyItemsOut && !rootGetters['review/isReviewOOC']
  },
  canRequestStyling: (state) => {
    return !state.readyForStyling && !state.tooManyItemsOut
  },
  creditBalance: (state) => {
    if (state.balance > 0) return 0
    return Math.abs(state.balance)
  },
  clientShareLinkParams: (state, _getters, rootState) => {
    const params = {}

    if (rootState.community.profileShareToken) {
      params.share = rootState.community.profileShareToken
      params.shareProfileId = state.id
    }

    if (state.referralCode) {
      params.utm_source = 'armoire_member_share'
      params.utm_medium = 'organic'
      params.utm_campaign = state.referralCode
    }
    return params
  },
  shareQueryString: (state, getters) => {
    const params = getters.clientShareLinkParams
    return Object.keys(params).length ? `?${serializeParams(params)}` : ''
  },
  employee: (state) => {
    // NOTE rationalize use of staff and employee
    if (state.plans && state.plans.currentBasePlan) {
      return state.plans.currentBasePlan.name.toLowerCase().includes('employee')
    }
    return false
  },
  nextShipmentDate: (state) => {
    if (state.nextBillingDate === null) {
      return null
    }
    const date = new Date(Date.parse(state.nextBillingDate))
    date.setDate(date.getDate() + 1)
    return date
  },
  allPickupPackages: (state) => {
    return state.transitPackages.length > 0 && state.transitPackages.filter(p => p.pickupFrom.name).length === state.transitPackages.length
  },
  paymentIsExpired: (state) => {
    const d = new Date()
    const month = d.getMonth() + 1
    const year = d.getFullYear()
    const expMonth = state.defaultPayment?.expMonth
    const expYear = state.defaultPayment?.expYear
    return (year > expYear || (year === expYear && month > expMonth))
  },
  paymentSoonToExpire: (state) => {
    const d = new Date()
    const month = d.getMonth() + 1
    const year = d.getFullYear()
    const expMonth = state.defaultPayment?.expMonth
    const expYear = state.defaultPayment?.expYear
    return (month === expMonth && year === expYear)
  },
  showFirstCaseCountdown (state) {
    return (new Date() < new Date(state.firstCaseDueDate)) && state.membershipStatus === 'active'
  }
}

export const actions = {
  async login ({ commit, dispatch }, data) {
    try {
      const res = await api.apiAuth.login(data)
      logger.info(res.data)
      commit('UPDATE_CLIENT', res.data)
      dispatch('getClientFollowup')

      const query = router.currentRoute.value.query
      'next' in query
        ? router.push({ path: query.next })
        : router.push({ path: '/home' })
    } catch (err) {
      logger.error(err)
      throw parseError(err)
    }
  },
  async signup ({ commit }, data) {
    try {
      const res = await api.apiAuth.signup(data)
      logger.info(res.data)
      commit('UPDATE_CLIENT', res.data)
      commit('SET_LOADED', false)
    } catch (err) {
      logger.error(err)
      throw parseError(err)
    }
  },
  async getCasePrice (context, data) {
    const res = await api.apiClient.getCasePrice(data)
    return res
  },
  async purchaseCase (context, data) {
    const res = await api.apiClient.purchaseCase(data)
    return res
  },
  async getShippingEligibility ({ commit, dispatch }) {
    dispatch('case/getShippingOffers', null, { root: true })
    const res = await api.apiClient.getShippingEligibility()
    commit('UPDATE_CLIENT', { shippingEligibility: res.data })
  },
  async getClient ({ commit, dispatch }) {
    return getKeyedPromise('client/getClient', async (resolve, reject) => {
      try {
        const res = await getMe()
        logger.info(res.data)
        commit('UPDATE_CLIENT', res.data)
        dispatch('getClientFollowup')
        resolve()
      } catch (err) {
        logger.error('client/getClient error', err)
        reject(parseError(err))
      }
    })
  },
  async getClientFollowup ({ state, commit, dispatch }) {
    // async fire off follow up API calls to fetch (or prefetch) data
    // that we'll need, but not necessarily immediately - so don't hold
    // up the getClient return (and consequently rendering of the page)
    // prioritize preloading closet data; wait for it so we
    // don't spam API calls all at once

    // Update logging settings, honoring local environment if set.
    const level = process.env.VUE_APP_LOG_LEVEL ?? state.logLevel
    const enabled = level !== null
    const consoleEnabled = process.env.NODE_ENV !== 'production' || state.logToConsole
    logger.apply({ enabled, consoleEnabled, level })

    dispatch('checkImpersonation')

    // BUGBUG - getStyleProfile is called TWICE if styleProfileComplete is true ... loadInitialData calls it too
    // Also why not await?
    dispatch('styleProfile/getStyleProfile', null, { root: true })
    if (state.styleProfileComplete) {
      await dispatch('closet/loadInitialData', null, { root: true })
    }

    // sleep for just a bit to let visible closet sections fire
    // off their get style colors call, since we want to prioritize those
    // network calls
    setTimeout(async () => {
      // high priority
      const highPriorityApiCalls = []
      highPriorityApiCalls.push(dispatch('notifications/getNotifications', null, { root: true }))
      highPriorityApiCalls.push(dispatch('styleGames/initializeStyleGames', 0, { root: true }))
      await Promise.all(highPriorityApiCalls)

      // medium priority
      const mediumPriorityApiCalls = []
      mediumPriorityApiCalls.push(dispatch('closet/getPackageItems', null, { root: true }))
      mediumPriorityApiCalls.push(dispatch('closet/getSelected', null, { root: true }))
      mediumPriorityApiCalls.push(dispatch('closet/getActiveStylistCuratedPackage', null, { root: true }))
      mediumPriorityApiCalls.push(dispatch('closet/getRatingsMap', null, { root: true }))
      await Promise.all(mediumPriorityApiCalls)
      commit('case/SET_CASE_LOADING', false, { root: true })

      // low priority
      const lowPriorityApiCalls = []
      lowPriorityApiCalls.push(dispatch('getBilling'))
      lowPriorityApiCalls.push(dispatch('community/getClientFollowing', { clientId: state.id }, { root: true }))
      lowPriorityApiCalls.push(dispatch('community/getFeedItems', 'all', { root: true }))
      lowPriorityApiCalls.push(dispatch('brands/getTopBrands', null, { root: true }))
      await Promise.all(lowPriorityApiCalls)

      // lowest Priority
      const apiCallsLowestPriority = []
      apiCallsLowestPriority.push(dispatch('case/getBaseExtraItemsPrice', null, { root: true }))
      apiCallsLowestPriority.push(dispatch('case/getAvailableAddOnItems', null, { root: true }))
      apiCallsLowestPriority.push(dispatch('styleProfile/getStyleProfileOptions', null, { root: true }))
      apiCallsLowestPriority.push(dispatch('closet/getSale', null, { root: true }))
      await Promise.all(apiCallsLowestPriority)

      // lowest priority, batch 2 (split up by batches to avoid too many simultaneous calls)
      const apiCallsLowestPriorityBatch2 = []
      apiCallsLowestPriorityBatch2.push(dispatch('case/getItemPurchaseSuggestions', null, { root: true }))
      apiCallsLowestPriorityBatch2.push(dispatch('case/getCheckoutRecommendations', null, { root: true }))
      apiCallsLowestPriorityBatch2.push(dispatch('community/getProfileShareToken', null, { root: true }))
      apiCallsLowestPriorityBatch2.push(dispatch('subscribe/getReferralCampaign', null, { root: true }))

      await Promise.all(apiCallsLowestPriorityBatch2)
    }, 100)
  },
  async getUnlockShipmentPricing () {
    const res = await api.apiClient.getUnlockShipmentPricing()
    return res.data
  },
  async getReferrals ({ commit }) {
    const res = await api.apiClient.getReferrals()
    commit('UPDATE_CLIENT', { referrals: res.data.referrals })
    commit('UPDATE_CLIENT', { referralAwards: res.data.awards })
  },
  async getBilling ({ commit }) {
    const apiCalls = []
    apiCalls.push(api.apiClient.getPaymentInfo())
    apiCalls.push(api.apiClient.getBilling())
    const apiResults = await Promise.all(apiCalls)
    commit('UPDATE_CLIENT', apiResults[0].data)
    commit('UPDATE_CLIENT', apiResults[1].data)
  },
  async buyPackageItem ({ commit, dispatch }, data) {
    try {
      const res = await api.apiClient.buyItem({
        packageItemPk: data.packageItem.id,
        promoCode: data.promoCode,
        source: data.source
      })
      commit('closet/ITEM_PURCHASED', data.packageItem.id, { root: true })
      commit('case/SET_ITEMS_PURCHASED', [data.packageItem], { root: true })
      commit('SET_CLOSET_ITEM_NUMBER', res.data.closetItemNumber)
      commit('SET_STATE', res.data.state)
      commit('SET_STATUS', res.data.status)
      dispatch('account/getItemPromotions', null, { root: true })
    } catch (err) {
      throw parseError(err)
    }
  },
  async buyItem ({ commit, dispatch }, data) {
    try {
      const res = await api.apiItems.purchaseItem(data.id, data)
      commit('closet/SET_SOLD_PACKING_ITEMS', res.data.soldItems.packing, { root: true })
      commit('closet/SET_SOLD_TRANSIT_ITEMS', res.data.soldItems.transit, { root: true })
      commit('closet/SET_SOLD_FUTURE_ITEMS', res.data.soldItems.future, { root: true })
      dispatch('account/getItemPromotions', null, { root: true })
    } catch (err) {
      throw parseError(err)
    }
  },
  async unlockShipments ({ commit }) {
    try {
      const res = await api.apiClient.unlockShipments()
      commit('UPDATE_CLIENT', res.data.client)
    } catch (err) {
      throw parseError(err)
    }
  },
  async getValidatedShippingAddress ({ commit }) {
    const res = await api.apiClient.getValidatedShippingAddress()
    commit('UPDATE_CLIENT', { validatedShippingAddress: res.data })
  },
  async getItemPrice (context, data) {
    try {
      const res = await api.apiItems.getItemPrice(data.itemPk, data.promoCode)
      return res.data
    } catch (err) {
      throw parseError(err)
    }
  },
  async getItemTypePrice ({ commit }, itemType) {
    try {
      const res = await api.apiItems.getItemTypePrice(itemType.id)
      commit('closet/SET_ITEM_TYPE_PRICE', {
        itemTypeId: itemType.id,
        value: res.data
      }, { root: true })
      return res.data
    } catch (err) {
      throw parseError(err)
    }
  },
  async postSupportRequest (context, data) {
    try {
      const res = await api.apiClient.postSupportRequest(data)
      return res
    } catch (err) {
      throw parseError(err)
    }
  },
  async postDefaultPayment ({ commit }, data) {
    try {
      await api.apiClient.postDefaultPayment({ token: data.id })
      commit('UPDATE_CLIENT', {
        defaultPayment: {
          brand: data.card.brand,
          last4: data.card.last4,
          expMonth: data.card.exp_month,
          expYear: data.card.exp_year
        }
      })
    } catch (err) {
      throw parseError(err)
    }
  },
  async checkReferralCodeAvailability (context, code) {
    try {
      const res = await api.apiClient.checkReferralCodeAvailability(code)
      return res.data
    } catch (err) {
      throw parseError(err)
    }
  },
  async updateReferralCode ({ commit }, code) {
    try {
      const noCaseRefCode = code.toLowerCase()
      await api.apiClient.updateClient({ code: noCaseRefCode })
      commit('UPDATE_CLIENT', { referralCode: noCaseRefCode })
    } catch (err) {
      throw parseError(err)
    }
  },
  async updateNotificationSetting ({ commit }, data) {
    try {
      const payload = {}
      payload[data.key] = data.value.toString()
      await api.apiClient.updateClient(payload)
      commit('UPDATE_NOTIFICATION_SETTING', data)
    } catch (err) {
      throw parseError(err)
    }
  },
  async updateClient ({ commit }, data) {
    try {
      await api.apiClient.updateClient(data)
      commit('UPDATE_CLIENT', data)
    } catch (err) {
      throw parseError(err)
    }
  },
  async checkImpersonation ({ commit }) {
    try {
      const res = await api.apiClient.checkImpersonation()
      if (res.data.impersonator) {
        commit('UPDATE_CLIENT', { impersonator: res.data.impersonator })
      }

      if (state.isImpersonated && state.impersonator.groups.includes('Remote Stylist')) {
        const styling = useStylingStore()
        styling.fetchClosets({
          username: state.username,
          stylistId: state.impersonator.id
        })
        styling.fetchStylingInfo()
      }
    } catch (err) {
      throw parseError(err)
    }
  },
  updateMembershipPlan ({ commit }, plan) {
    // Update the membership plan on the UI, before stripe webhook returns
    commit('UPDATE_MEMBERSHIP_PLAN', plan)
  },
  setStyleProfileComplete ({ commit }, value) {
    logger.info(value)
    commit('SET_STYLE_PROFILE_COMPLETE', value)
  }
}

export const mutations = {
  ...getSetters(state),
  'UPDATE_CLIENT' (state, data) {
    Object.keys(data).forEach(key => {
      state[key] = data[key]
    })
    state.loaded = true
  },
  'UPDATE_NOTIFICATION_SETTING' (state, data) {
    state.settings.notifications[data.key] = data.value
  },
  'UPDATE_ITEMS_MAP' (state, packageItem) {
    state.itemsMap[packageItem.pk] = packageItem
  },
  'UPDATE_MEMBERSHIP_PLAN' (state, plan) {
    state.plans.currentBasePlan = plan
  },
  'UPDATE_REPLACEMENT_CHOICE' (state, data) {
    state.settings.replacementItemChoice = data.pref
  },
  'UPDATE_APPLY_GIFT_CARDS_TO_SUBSCRIPTIONS' (state, data) {
    state.settings.applyGiftCardsToSubscriptions = data.pref
  },
  'SET_STYLE_PROFILE_COMPLETE' (state, value) {
    state.styleProfileComplete = value
  }
}
