<template>
  <BaseTextArea
    v-model="qualityFeedbackText"
    :placeholder="placeholder"
    :validations="validations"
    class="h5-label"/>
</template>

<script setup>
import { computed } from 'vue'
import BaseTextArea from '@/components/global/BaseTextArea.vue'

defineOptions({
  model: {
    prop: 'review',
    event: 'update:review'
  }
})

const props = defineProps({
  review: {
    type: Object,
    required: true
  },
  placeholder: {
    type: String,
    required: true
  },
  validations: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(['update:review'])

const qualityFeedbackText = computed({
  get () { return props.review.qualityFeedbackText },
  set (value) {
    emit('update:review', {
      ...props.review,
      qualityFeedbackText: value
    })
  }
})
</script>
