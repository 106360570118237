<template>
  <div :class="isMobile ? 'row g-0' : 'container'">
    <action-header
      v-if="isMobile"
      class="col col-12"
      page-header>
      Swap Items
    </action-header>
    <div
      class="col col-12"
      :class="{ 'list-padding': isMobile }">
      <review-select-items-list
        v-if="canSwap"
        title="Select the items you're swapping today"
        :package-items="deliveredItems"
        :selected-items="selectedItems"
        @selected="onSelectItem"/>
      <div
        v-else
        class="p-2 bold">
        You cannot swap at this time. {{ noSwapMessage }}
      </div>
    </div>
    <div
      :class="isMobile
        ? 'col col-12 mobile-button'
        : 'd-flex justify-content-end mt-3 mb-3 me-3'">
      <sequin-button
        mobile-block
        :disabled="selectedItems.length === 0"
        @click="submit">
        Swap {{ selectedItems.length > 0 ? selectedItems.length : '' }} Item{{ selectedItems.length > 1 ? 's' : '' }}
      </sequin-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import goBack from '../global/mixins/goBack'
import ReviewSelectItemsList from './ReviewSelectItemsList'
import SequinButton from '../global/sequin/SequinButton'
import ActionHeader from '../global/sequin/ActionHeader'
import { vuexAccessors } from '../global/helpers/vuex'
import { useReviewStore } from '@/stores/review.js'

export default {
  components: {
    ActionHeader,
    ReviewSelectItemsList,
    SequinButton
  },
  mixins: [goBack],
  setup () {
    const { navigateToItemReview } = useReviewStore()
    return {
      navigateToItemReview
    }
  },
  data () {
    return {
      selectedItems: []
    }
  },
  computed: {
    ...mapState('client', [
      'membershipDetails',
      'nextBillingDate',
      'tooManyItemsOut'
    ]),
    ...mapState('closet', [
      'deliveredItems',
      'transitItems',
      'transitPackages',
      'packingItems',
      'styleColorsMap'
    ]),
    ...mapGetters('client', [
      'allPickupPackages'
    ]),
    ...mapGetters('review', [
      'isReviewOOC'
    ]),
    ...vuexAccessors('review', [
      'canSkipReviews',
      'itemsToSwap',
      'reviewItems'
    ]),
    swapAvailable () {
      return this.membershipDetails.allowedShipments === null || this.membershipDetails.allowedShipments > 0
    },
    packageInTransit () {
      return this.packingItems.length || (this.transitPackages && this.transitPackages.length && !this.allPickupPackages)
    },
    canSwap () {
      return this.deliveredItems.length && this.swapAvailable && !this.isReviewOOC && !this.tooManyItemsOut && !this.packageInTransit
    },
    noSwapMessage () {
      if (!this.deliveredItems.length) {
        return 'You have no items with you to swap.'
      } else if (!this.swapAvailable) {
        return 'You have no shipments available.'
      } else if (this.isReviewOOC) {
        return 'You have too many items that need to be reviewed.'
      } else if (this.tooManyItemsOut) {
        return 'You have too many items out.'
      } else if (this.packingItems.length) {
        return 'You have a package being prepared.'
      } else if (this.transitPackages && this.transitPackages.length && !this.allPickupPackages) {
        return 'You have a package in transit.'
      } else {
        return ''
      }
    }
  },
  methods: {
    submit () {
      this.itemsToSwap = this.selectedItems.slice()
      const unreviewedItemsToSwap = this.itemsToSwap.filter(item => !item.reviewed)
      if (unreviewedItemsToSwap.length) {
        this.reviewItems = unreviewedItemsToSwap
        this.canSkipReviews = true
        this.navigateToItemReview(unreviewedItemsToSwap)
      } else {
        this.$router.push({ name: 'swap-confirm' })
      }
    },
    onSelectItem (packageItem) {
      if (this.selectedItems.includes(packageItem)) {
        this.selectedItems.splice(this.selectedItems.indexOf(packageItem), 1)
      } else {
        this.selectedItems.push(packageItem)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .mobile-button {
    padding: 12px;
    border-top: $default-border;
    background-color: $white;
    z-index: 2;
    position: fixed;
    bottom: 0;
    left: 0;
  }

  .list-padding {
    padding-bottom: 128px;
  }
</style>
