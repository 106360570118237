<template>
  <div class="d-flex flex-column">
    <sequin-button
      v-if="!isClient"
      :variant="memberFoundHelpful ? 'active' : 'secondary'"
      class="p-2 fit-content-width"
      :class="{ 'mt-2': !isMobile }"
      @click="toggleRating">
      <div class="d-flex align-items-center justify-content-between">
        <svg-like
          height="18"
          class="me-1"/>
        Helpful
      </div>
    </sequin-button>
    <sequin-button
      v-if="isClient"
      class="whitespace-no-wrap fit-content-width"
      variant="secondary"
      small
      @click="openEditReviewPhotos">
      {{ review.images.length === 0 ?
        'Add Review Photos' :
        'Edit Review Photos' }}
    </sequin-button>
    <sequin-button
      v-if="isClient && isStaff && review.adminEditReviewLink"
      class="whitespace-no-wrap fit-content-width mt-2"
      variant="secondary"
      small
      @click="openEditReviewAdmin">
      {{ "Edit Review in Admin" }}
    </sequin-button>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import SequinButton from '../../../global/sequin/SequinButton.vue'
import { vuexAccessors } from '../../../global/helpers/vuex'
import SvgLike from '@/components/global/svg/SvgLike.vue'

export default {
  components: {
    SequinButton,
    SvgLike
  },
  props: {
    review: {
      type: Object,
      required: true
    },
    styleColorId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      buttonToggled: false
    }
  },
  computed: {
    ...mapState('client', [
      'id',
      'styleProfileComplete',
      'loaded',
      'isStaff'
    ]),
    ...vuexAccessors('review', [
      'reviewForEditPhotos'
    ]),
    isClient () {
      return this.id === this.review.clientId
    },
    memberFoundHelpful () {
      return this.buttonToggled || this.review.memberFoundHelpful
    }
  },
  methods: {
    ...mapActions('closet', [
      'rateReview'
    ]),
    toggleRating () {
      this.rateReview({
        styleColorId: this.styleColorId,
        itemFeedbackId: this.review.id,
        rating: this.memberFoundHelpful ? 0 : 1
      })
      this.buttonToggled = true
    },
    openEditReviewPhotos () {
      this.reviewForEditPhotos = { ...this.review, styleColorId: this.styleColorId }
      this.$router.push({ name: 'edit-review-photos' })
    },
    openEditReviewAdmin () {
      window.location.href = this.review.adminEditReviewLink
    }
  }

}
</script>

<style>

</style>
