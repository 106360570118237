/* eslint-disable import/no-namespace */
import * as account from './account'
import * as brands from './brands'
import * as caseModule from './case'
import * as client from './client'
import * as closet from './closet'
import * as collections from './collections'
import * as community from './community'
import * as global from './global'
import * as notifications from './notifications'
import * as review from './review'
import * as search from './search'
import * as styleGames from './styleGames'
import * as styleProfile from './styleProfile'
import * as styleQuizNav from './styleQuizNav'
import * as subscribe from './subscribe'

const modules = Object.entries({
  account,
  brands,
  case: caseModule,
  client,
  closet,
  collections,
  community,
  global,
  notifications,
  review,
  search,
  styleGames,
  styleProfile,
  styleQuizNav,
  subscribe
}).reduce((acc, [name, module]) => {
  acc[name] = {
    namespaced: true,
    ...module
  }
  return acc
}, {})

export default modules
