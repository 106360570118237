<template>
  <div class="text-center px-4 py-1">
    <h6
      v-if="!isEvent"
      :class="{'marketing': !isMobile}">
      This is the fun part!
    </h6>
    <h4
      v-if="isEvent"
      class="mb-4">
      Access your unlimited wardrobe with an Armoire membership. Start by creating an account with us
    </h4>
    <h4
      v-else
      class="mb-4">
      Take our Style Quiz and access your {{ signUpReason }} closet
    </h4>
    <div :class="{'content-wrapper d-flex' : !isMobile}">
      <base-image
        v-if="!isMobile"
        src="https://dt002ezve8cvy.cloudfront.net/style_quiz_may21_images/banners/acct_image_lrg.jpg"
        class="account-creation-image"/>
      <style-quiz-sign-up
        :class="{'ps-5' : !isMobile}"
        :form-disabled="formDisabled"
        :form-errors="formErrors"
        :is-event="isEvent"
        @error="setError"
        @sign-up="completeSignup"/>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { vuexAccessors } from '@/components/global/helpers/vuex.js'
import { identify } from '../../plugins/analytics'
import useAnalytics from '@shared/composables/analytics.js'
import StyleQuizSignUp from './StyleQuizSignUp.vue'
import { signupReasonsResponseKey } from '@/store/modules/styleProfile'
import useMobileApp from '@/composables/mobileApp.js'
import BaseImage from '@/components/global/BaseImage.vue'
import { useExperimentsStore } from '@shared/stores/experiments.js'

export default {
  components: {
    StyleQuizSignUp,
    BaseImage
  },
  setup () {
    const mobileApp = useMobileApp()
    const { track } = useAnalytics()
    const { untilExperimentsLoaded } = useExperimentsStore()

    return {
      mobileApp,
      untilExperimentsLoaded,
      track
    }
  },
  data () {
    return {
      formDisabled: false,
      formErrors: []
    }
  },
  computed: {
    ...mapState('client', [
      'email',
      'mainPhone'
    ]),
    ...mapState('styleProfile', [
      'signupReasons'
    ]),
    ...vuexAccessors('styleQuizNav', [
      'isEvent'
    ]),
    ...mapGetters('styleQuizNav', [
      'getNextStyleQuizRouteForRouter'
    ]),
    signUpReason () {
      return signupReasonsResponseKey[this.signupReasons[this.signupReasons.length - 1] || 'money']
    }
  },
  created () {
    this.isEvent = this.$route.query.isEvent === 'true'
  },
  methods: {
    ...mapActions('client', [
      'signup'
    ]),
    ...mapActions('styleProfile', [
      'patchStyleProfile',
      'getStyleProfileOptions'
    ]),
    navigateToNextRoute () {
      this.$router.push({ ...this.getNextStyleQuizRouteForRouter({ ...this.$route }) })
    },
    async completeSignup (formData) {
      this.formDisabled = true

      formData.funnel_type = 'standard'
      try {
        await this.signup(formData)
        await this.untilExperimentsLoaded({ throwOnTimeout: false })
        this.mobileApp.postSignedUp()
        await this.patchStyleProfile()
        await this.getStyleProfileOptions()
        this.formErrors = []
        this.navigateToNextRoute()

        try {
          identify(this.$store.state.client, () => {
            if (this.isEvent) {
              this.track('Event Sign-up', {
                email: this.email
              })
            }

            // TikTok uses email + phone for conversion identification.  They require the phone number to be +1xxxxxxxxxx with no hyphens.
            this.track('Client Created', {
              value: '1.00',
              currency: 'USD',
              email: this.email,
              phone: this.mainPhone.replace(/-/g, ''),
              signUpMethod: this.$store.state.client.signUpMethod
            })
          })
        } catch (err) {
          // do nothing
          this.$logger.error('identify call failed', err)
        }
      } catch (errors) {
        this.setError(errors)
      }

      this.formDisabled = false
    },
    setError (errors) {
      this.formErrors = errors
      if (this.formErrors?.length > 0) {
        this.track('Create Account Error', { formErrors: this.formErrors })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.account-creation-image {
  max-width: 50%;
  height: auto;
  object-fit: contain;
}
</style>
