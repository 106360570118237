<template>
  <div v-if="membershipPlanSelected">
    <div class="row">
      <div class="col col-12 col-md-5 col-lg-4 px-0 pe-md-3">
        <plan-card
          :form-disabled="formDisabled"
          :plan="membershipPlanSelected"
          show-change-plan-button
          header-text="Current Plan"
          @change-click="onChangePlanClick"/>
      </div>
      <div
        :class="{'border-top': !isMobile}"
        class="col col-12 col-md-7 col-lg-7 border-start border-end border-bottom">
        <div class="px-md-4 py-4 px-2">
          <h4 class="group-header">
            Payment Method
          </h4>
          <div
            v-if="defaultPayment !== null"
            class="d-flex justify-content-between bg-white pt-3 small-text">
            <default-payment
              class="ms-0"
              :show-link="false"/>
            <text-link
              :disabled="formDisabled"
              @click="openModal({ name: 'update-credit-card' })">
              change
            </text-link>
          </div>
        </div>
        <change-plan-summary
          class="px-2"
          :form-disabled="formDisabled"
          fine-print-text="Confirm">
          <div
            v-if="stripeErrorMessage"
            class="text-center">
            <p class="small-text text-danger">
              {{ stripeErrorMessage }}
              <br><span v-html="stripeErrorFollowup"/>
            </p>
          </div>
          <div
            v-if="promoCodeEligible"
            class="w-100 d-flex justify-content-between align-items-center pt-2 pb-3">
            <base-input
              v-model="enteredCode"
              class="w-100 pe-md"
              :disabled="formDisabled"
              label="Gift or promo code"/>
            <sequin-button
              small
              :disabled="formDisabled"
              variant="secondary"
              @click="apply">
              Apply
            </sequin-button>
          </div>
          <div
            v-for="(error, index) in errors"
            :key="index"
            class="mt-2">
            <span
              class="small-text text-danger"
              v-html="error"/>
          </div>
          <sequin-button
            id="btn-stripe"
            :disabled="formDisabled"
            block
            class="mt-2 mb-4 d-flex justify-content-center"
            @click="submitChangePlan">
            Confirm
          </sequin-button>
        </change-plan-summary>
        <div
          v-if="membershipPlanSelected.prepayment && membershipPlanSelected.defaultPromo && membershipPlanSelected.defaultPromo.dollarAmount">
          <p class="fine-print text-center">
            By clicking the “Confirm” button you agree to the
            <text-link
              type="a"
              class="fine-print mb-0 py-0 fine-print-link"
              variant="secondary"
              href="https://support.armoire.style/rental-policy/"
              target="_blank">
              Rental Policy
            </text-link>
            and agree to be charged {{ formatPrice(membershipPlanSelected.basePrice, false ) }} automatically {{ chargeIntervalCopy(membershipPlanSelected) }} after the initial trial price of {{ formatPrice(membershipPlanSelected.basePrice - membershipPlanSelected.defaultPromo.dollarAmount, false ) }}, until you cancel the subscription service as set forth in the
            <text-link
              type="a"
              class="fine-print mb-0 py-0 fine-print-link"
              variant="secondary"
              href="https://support.armoire.style/legal/"
              target="_blank">
              Terms of Service.
            </text-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from '@/components/global/BaseInput.vue'
import PlanCard from '../../subscribe/PlanCard'
import DefaultPayment from '../../global/stripe/DefaultPayment'
import ChangePlanSummary from '../ChangePlanSummary'
import SequinButton from '../../global/sequin/SequinButton'
import TextLink from '../../global/sequin/TextLink'
import usePayment from '@shared/composables/payment.js'
import MembershipMixin from './MembershipMixin'
import { mapState, mapActions as mapVuexActions, mapGetters } from 'vuex'
import { mapActions } from 'pinia'
import { vuexAccessors } from '../../global/helpers/vuex'
import useAnalytics from '@shared/composables/analytics.js'
import { captureException } from '@sentry/vue'
import { formatPrice } from '@/utils/stringParsing.js'
import { useOverlaysStore } from '@/stores/overlays.js'

export default {
  components: {
    PlanCard,
    SequinButton,
    TextLink,
    DefaultPayment,
    ChangePlanSummary,
    BaseInput
  },
  mixins: [MembershipMixin],
  setup () {
    const { track } = useAnalytics()
    const { promoAmount, total, chargeIntervalCopy } = usePayment()

    return {
      promoAmount,
      total,
      chargeIntervalCopy,
      track
    }
  },
  data () {
    return {
      enteredCode: '',
      codes: [],
      errors: null,
      stripeErrorMessage: null,
      stripeErrorFollowup: null,
      paymentRequest: null,
      formDisabled: false
    }
  },
  computed: {
    ...vuexAccessors('account', [
      'showPlanUpdateSuccess'
    ]),
    ...mapState('subscribe', [
      'membershipPlans'
    ]),
    ...mapGetters('subscribe', [
      'membershipPlanSelected'
    ]),
    ...mapState('client', [
      'defaultPayment',
      'membershipDetails'
    ]),
    promoCodeEligible () {
      return this.membershipDetails?.planChangePromoEligible === true
    }
  },
  watch: {
    promoAmount () {
      if (this.paymentRequest) {
        this.paymentRequest.update({
          total: {
            label: 'Armoire subscription',
            amount: Math.round(this.total * 100)
          }
        })
      }
    }
  },
  async mounted () {
    if (this.breadcrumbs.length === 0) {
      // direct page load / browser refresh
      // breadcrumbs not important enough to save in query string, so fake
      // it. It's good enough.
      this.breadcrumbs = [
        { name: 'Membership', to: { name: 'membership-overview' } },
        { name: 'Confirm Plan Change' }
      ]
    }

    const query = this.$route?.query
    if (!this.membershipPlans) {
      // direct page load / browser refresh.  This data was not
      // loaded via the plan select page, so we have to do it here.
      await this.fetchPlans()
      await this.getQuote({ planId: query?.planId })
    }
    if (query?.planId) {
      this.membershipPlanSelectedId = query.planId
    }
    const index = this.breadcrumbs.length > 1 ? this.breadcrumbs.length - 2 : this.breadcrumbs.length - 1
    this.track('Viewed Confirm Plan Change', {
      source: this.breadcrumbs[index].name,
      currentPlan: this.plans.currentBasePlan.displayName,
      plan: this.membershipPlanSelected.displayName,
      quote: this.planQuote
    })
  },
  methods: {
    ...mapVuexActions('subscribe', [
      'changePlan',
      'getQuote'
    ]),
    ...mapActions(useOverlaysStore, [
      'openModal'
    ]),
    formatPrice,
    onChangePlanClick () {
      this.$router.go(-1)
    },
    async apply () {
      this.formDisabled = true
      const code = this.enteredCode.toUpperCase()
      const codesToValidate = [...this.codes]
      this.errors = []
      if (!this.codes.includes(code)) {
        codesToValidate.push(code)
      }
      try {
        await this.getQuote({
          planId: this.membershipPlanSelectedId,
          codes: codesToValidate
        })
        this.codes = [...codesToValidate]
      } catch (err) {
        this.errors = err
      }
      this.enteredCode = ''
      this.formDisabled = false
    },
    async submitChangePlan (onComplete = null) {
      this.formDisabled = true
      this.errors = []

      try {
        const data = {
          analyticsMetrics: {
            source: this.breadcrumbs[this.breadcrumbs.length - 2].name,
            quote: this.planQuote
          },
          promo_codes: this.codes
        }
        const res = await this.changePlan({ planId: this.membershipPlanSelected.id, data: data })

        if ('stripeError' in res.data) {
          this.stripeErrorMessage = res.data.stripeError.errorMessage
          this.stripeErrorFollowup = res.data.stripeError.errorFollowup

          if (onComplete) {
            onComplete.complete('fail')
          }
        } else {
          if (onComplete) {
            onComplete.complete('success')
          }
          try {
            this.onPlanChangeSuccess()
          } catch (err) {
            captureException(err)
          } finally {
            this.showPlanUpdateSuccess = true
            this.$router.push({ name: 'membership-overview' })
          }
        }
      } catch (err) {
        this.errors = err || ['There was an issue confirming your subscription. Please try again.']
      }

      this.formDisabled = false
    }
  }
}
</script>

<style lang="scss" scoped>
.sticky-top {
  top: 16px;
  height: fit-content;
}

.apple-pay-button {
  background-color: black;
  width: 100%;
  text-align: right;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI' !important;
  font-weight: $font-weight-semibold !important;

  &:hover {
    background-color: black;
  }

  .apple-pay-logo {
    background-image: -webkit-named-image(apple-pay-logo-white);
    background-size: 100% 100%;
    background-origin: content-box;
    background-repeat: no-repeat;
    height: 20px;
    width: 52px;
    margin-left: 4px;
  }
}
</style>
