import { track } from '../../plugins/analytics'
import api from '../api/api'
import { searchStyleColorSections } from '@shared/api/search'
import { getSetters, parseError } from './helpers/shared'

export const state = {
  collectionTagOptions: null,

  // Collection data by client, style color
  collectionDataByClientId: {},
  collectionIdsByStyleColorId: {},
  collectedStyleColors: [], // All the style color ids that the client has added to 1+ collection
  clientCollectionsByStyleColorId: {}, // StyleColorId => Client's collections that contain it

  // Collections data
  collectionsById: {}, // Collection basic data
  collectionStyleColorsById: {}, // Style colors in collection
  collectionSuggestionsById: {}, // Style color suggestions for collection

  genericCollections: {},
  baseCollectionStyleColorsResults: {
    results: [],
    loading: true,
    next: null,
    prev: null,
    count: null
  },
  sizeFilter: true
}

export const getters = {
  collectionsFromClientId: (state, _getters) => (clientId) => {
    const collectionData = state.collectionDataByClientId[clientId]
    if (!collectionData) return null
    return collectionData.results.map(collectionId => state.collectionsById[collectionId])
  },
  genericCollections: (state) => (filter) => {
    return (state.genericCollections && state.genericCollections[filter]) ? state.genericCollections[filter] : null
  },
  standardCollectionsFromClientId: (_state, getters) => (clientId) => {
    const clientCollections = getters.collectionsFromClientId(clientId)
    if (!clientCollections) return null
    const allClientCollections = clientCollections.slice()
    const waitlistIndex = allClientCollections.findIndex(collection => collection.type === 'waitlist')
    if (waitlistIndex === -1) return allClientCollections
    return [...allClientCollections.slice(0, waitlistIndex), ...allClientCollections.slice(waitlistIndex + 1, allClientCollections.length)]
  },
  numCollectionsFromClientId: (state, getters, rootState) => (clientId) => {
    const clientCollectionData = state.collectionDataByClientId[clientId]
    const collectionsCount = clientCollectionData ? clientCollectionData.count : 0
    return collectionsCount + (rootState.client.id === clientId ? 1 : 0)
  },
  styleColorsFromCollectionId: (state) => (collectionId) => {
    return state.collectionStyleColorsById[collectionId].results
  },
  styleColorsLoadingFromCollectionId: (state) => (collectionId) => {
    return !state.collectionStyleColorsById[collectionId] ||
      !state.collectionStyleColorsById[collectionId] ||
      state.collectionStyleColorsById[collectionId].loading
  },
  styleColorCountFromCollectionId: (state) => (collectionId) => {
    return state.collectionStyleColorsById[collectionId].count
  },
  clientsCollections: (_state, getters, rootState) => {
    return getters.collectionsFromClientId(rootState.client.id)
  },
  clientsStandardCollections: (_state, getters, rootState) => {
    return getters.standardCollectionsFromClientId(rootState.client.id)
  },
  numClientCollections: (_state, getters, rootState) => {
    return getters.numCollectionsFromClientId(rootState.client.id)
  },
  numGenericCollections: (_state) => (filter) => {
    const collectionData = state.genericCollections
    if (!collectionData || !collectionData[filter]) return 0
    return collectionData[filter].count
  }
}

export const actions = {
  async getClientCollections ({ commit, rootState, dispatch }) {
    try {
      const res = await api.apiCollections.getCollections({ clientId: rootState.client.id })
      const collections = res.data.results
      commit('SET_CLIENT_COLLECTIONS', {
        clientId: rootState.client.id,
        collectionData: {
          ...res.data,
          sourceType: 'collection',
          results: res.data.results.map(collection => collection.id),
          loading: false
        }
      })
      commit('SET_COLLECTIONS_BY_ID', { collections })
      dispatch('initCollectionStyleColors', collections)
      dispatch('getClientCollectedStyleColors')
    } catch (err) {
      throw parseError(err)
    }
  },
  async loadMoreClientCollections ({ commit, state, dispatch }, { clientId }) {
    try {
      const clientCollectionData = Object.assign({}, state.collectionDataByClientId[clientId])
      if (clientCollectionData && clientCollectionData.next) {
        commit('SET_CLIENT_COLLECTIONS', {
          clientId,
          collectionData: {
            ...clientCollectionData,
            loading: true
          }
        })

        const res = await api.apiCollections.getCollectionsNextPage(clientCollectionData.next)
        commit('SET_CLIENT_COLLECTIONS', {
          clientId,
          collectionData: {
            ...res.data,
            results: clientCollectionData.results.concat(res.data.results.map(collection => collection.id)),
            loading: false
          }
        })

        const collections = res.data.results
        commit('SET_COLLECTIONS_BY_ID', { collections })
        dispatch('initCollectionStyleColors', collections)
      }
    } catch (err) {
      throw parseError(err)
    }
  },
  async getClientCollectedStyleColors ({ commit }) {
    const res = await api.apiCollections.getClientCollectedStyleColors()
    commit('SET_COLLECTED_STYLE_COLORS', Object.keys(res.data))
  },
  async getClientsCollectionsWithStyleColor ({ commit }, styleColorId) {
    const res = await api.apiCollections.getStyleColorClientCollections(styleColorId)
    commit('SET_CLIENT_COLLECTIONS_WITH_STYLE_COLOR', { styleColorId, collections: res.data })
  },
  async getCollections ({ commit, dispatch, rootState, state }, { styleColorId, clientId }) {
    if (clientId) {
      const res = await api.apiCollections.getCollections({ styleColorId, clientId })
      const collections = res.data.results
      commit('SET_CLIENT_COLLECTIONS', {
        clientId,
        collectionData: {
          ...res.data,
          results: res.data.results.map(collection => collection.id),
          loading: false
        }
      })
      if (collections.length > 0 && !rootState.community.clientInfoById[clientId]) {
        commit('community/SET_CLIENT_INFO', collections[0].client, { root: true })
      }
      commit('SET_COLLECTIONS_BY_ID', { collections })
      dispatch('initCollectionStyleColors', collections)
    } else if (styleColorId) {
      if (styleColorId in state.collectionIdsByStyleColorId) {
        // do nothing; we already have it (or are fetching it)
        return
      }
      // put an empty array to prevent new fetches while we get it the first time
      let collections = []
      commit('SET_STYLE_COLOR_COLLECTIONS', { styleColorId, collections })
      try {
        const res = await api.apiCollections.getCollections({ styleColorId, clientId })
        collections = res.data.results
        commit('SET_STYLE_COLOR_COLLECTIONS', { styleColorId, collections })
        commit('SET_COLLECTIONS_BY_ID', { collections })
        dispatch('initCollectionStyleColors', collections)
      } catch (err) {
        commit('CLEAR_STYLE_COLOR_COLLECTIONS', { styleColorId })
        throw err
      }
    }
  },
  async fetchGenericCollections ({ commit, dispatch }, { filterType, filter, minItems }) {
    try {
      const res = await api.apiCollections.getCollectionsByFilter(filterType, filter, minItems)
      if (res?.data) {
        const collections = res.data.results
        commit('SET_GENERIC_COLLECTIONS', { filter, collectionsData: collections })
        dispatch('initCollectionStyleColors', collections)
      }
      return res
    } catch (err) {
      throw parseError(err)
    }
  },
  async getCollection ({ commit, state, dispatch }, { collectionId }) {
    try {
      const res = await api.apiCollections.getCollection(collectionId)

      if (res?.data) {
        commit('SET_COLLECTIONS_BY_ID', { collections: [res.data] })
        if (!state.collectionStyleColorsById[collectionId]) {
          dispatch('initCollectionStyleColors', [res.data])
        }
      }

      return res
    } catch (err) {
      throw parseError(err)
    }
  },
  async getCollectionStyleColorsNextPage ({ commit, state, rootState }, { collectionId, available = false }) {
    const collectionStyleColors = state.collectionStyleColorsById[collectionId]
    const nextPage = collectionStyleColors.next

    if (nextPage) {
      commit('SET_COLLECTION_STYLE_COLORS', {
        collectionId,
        styleColorResults: {
          ...collectionStyleColors,
          filterOptions: rootState.closet.baseFilters,
          filters: rootState.closet.baseFilters,
          available: available,
          loading: true,
          sizeFilter: state.sizeFilter
        }
      })

      const res = await api.apiCollections.getCollectionStyleColorsNextPage(nextPage)

      commit('SET_COLLECTION_STYLE_COLORS', {
        collectionId,
        styleColorResults: {
          ...res.data,
          filters: rootState.closet.baseFilters,
          available: available,
          results: collectionStyleColors.results.concat(res.data.results),
          loading: false
        }
      })
    }
  },
  async getCollectionStyleColors ({ commit, state, rootState }, { collectionId, available = true }) {
    const params = { collectionId }
    if (available !== null) {
      params.available = available
    }
    params.sizeFilter = state.sizeFilter
    const res = await api.apiCollections.getCollectionStyleColors(params)
    commit('SET_COLLECTION_STYLE_COLORS', {
      collectionId,
      styleColorResults: {
        ...res.data,
        filters: rootState.closet.baseFilters,
        available: available,
        loading: false,
        sizeFilter: state.sizeFilter
      }
    })
    commit('closet/UPDATE_STYLE_COLORS_MAP', res.data.results, { root: true })
  },
  async getCollectionSuggestions ({ commit }, { collectionId }) {
    commit('SET_COLLECTION_STYLE_COLOR_SUGGESTIONS', { collectionId, suggestedStyleColors: [] })
    const res = await api.apiCollections.getCollectionStyleColorSuggestions(collectionId)
    commit('SET_COLLECTION_STYLE_COLOR_SUGGESTIONS', { collectionId, suggestedStyleColors: res.data })
  },
  async createCollection ({ dispatch }, data) {
    try {
      const res = await api.apiCollections.createCollection(data)
      await dispatch('getClientCollections')

      track('Created Collection', {
        id: res.data.id,
        name: res.data.name
      })

      return res.data
    } catch (err) {
      throw parseError(err)
    }
  },
  async addStyleColorToCollection ({ dispatch, rootState }, { collectionId, styleColorId }) {
    try {
      const source = rootState.closet.styleColorSources[styleColorId]
      await api.apiCollections.addStyleColorToCollection(collectionId, { id: styleColorId, source })
      dispatch('trackStyleColorCollectionEvent', { collectionId, styleColorId, event: 'Added to Collection' })
      dispatch('getClientCollections')
      dispatch('getClientsCollectionsWithStyleColor', styleColorId)
      dispatch('getCollectionStyleColors', { collectionId })
      dispatch('getClientCollectedStyleColors')
    } catch (err) {
      throw parseError(err)
    }
  },
  async removeStyleColorFromCollection ({ dispatch }, { collectionId, styleColorId }) {
    try {
      await api.apiCollections.removeStyleColorFromCollection(collectionId, { id: styleColorId })
      dispatch('trackStyleColorCollectionEvent', { collectionId, styleColorId, event: 'Removed from Collection' })
      dispatch('getClientCollections')
      dispatch('getClientsCollectionsWithStyleColor', styleColorId)
      dispatch('getClientCollectedStyleColors')
    } catch (err) {
      throw parseError(err)
    }
  },
  async addSuggestedStyleColorsToCollection ({ dispatch }, { collectionId, styleColors }) {
    const promises = styleColors.map(styleColorId => {
      dispatch('trackStyleColorCollectionEvent', { collectionId, styleColorId, event: 'Added to Collection', sourceOverride: { type: 'collection-suggestions' } })
      return api.apiCollections.addStyleColorToCollection(collectionId, { id: styleColorId })
    })
    await Promise.all(promises)
    dispatch('getClientCollections')
    dispatch('getCollectionStyleColors', { collectionId })
    dispatch('getClientCollectedStyleColors')
  },
  async getCollectionTags ({ commit }) {
    const res = await api.apiCollections.getCollectionTags()
    commit('SET_COLLECTION_TAG_OPTIONS', res.data)
  },
  async updateCollection ({ dispatch }, { collectionId, data }) {
    try {
      await api.apiCollections.updateCollection(collectionId, data)
      await dispatch('getCollection', { collectionId })
    } catch (err) {
      throw parseError(err)
    }
  },
  async deleteCollection ({ state, dispatch }, { collectionId }) {
    try {
      const collection = state.collectionsById[collectionId]
      await api.apiCollections.deleteCollection(collectionId)

      track('Deleted Collection', {
        id: collectionId,
        name: collection.name
      })

      await dispatch('getClientCollections')
    } catch (err) {
      throw parseError(err)
    }
  },
  async postCollectionToFeed ({ dispatch }, { collectionId, caption }) {
    try {
      await api.apiCollections.postCollectionToFeed(collectionId, { caption })
      await dispatch('community/getFeedItems', null, { root: true })
    } catch (err) {
      throw parseError(err)
    }
  },
  trackStyleColorCollectionEvent ({ state, rootGetters, rootState }, { collectionId, styleColorId, event = 'Added to Collection', sourceOverride }) {
    const collection = state.collectionsById[collectionId]
    const styleColorSource = rootState.closet.styleColorSources[styleColorId]
    const indexKey = styleColorId + '-' + styleColorSource
    const eventSource = sourceOverride ? { sourceType: sourceOverride } : styleColorSource
    track(event, {
      id: collectionId,
      name: collection.name,
      styleColor: rootGetters['closet/styleColorSegmentData'](styleColorId),
      sourceIndex: rootState.closet.styleColorSourceIndexes[indexKey] ?? null,
      ...eventSource
    })
  },
  initCollectionStyleColors ({ state, commit, rootState }, collections) {
    collections.forEach(collection => {
      if (!state.collectionStyleColorsById[collection.id]) {
        commit('SET_COLLECTION_STYLE_COLORS', {
          collectionId: collection.id,
          styleColorResults: {
            results: null,
            next: null,
            prev: null,
            count: collection.count,
            filterOptions: rootState.closet.baseFilters,
            filters: rootState.closet.baseFilters,
            available: true,
            loading: true
          }
        })
      }
    })
  },
  async searchCollections ({ commit, dispatch }, { name, autosuggest = false, excludeSectionCollections = false }) {
    const res = await api.apiCollections.searchCollections(name, autosuggest, excludeSectionCollections)
    const collections = autosuggest ? res.data : res.data.results
    commit('SET_COLLECTIONS_BY_ID', { collections })
    dispatch('initCollectionStyleColors', collections)
    return res
  },
  async searchMarketingCollections ({ commit, dispatch }, { name, autosuggest = false }) {
    const res = await searchStyleColorSections(name, autosuggest)
    const collections = autosuggest ? res.data : res.data.results
    commit('SET_COLLECTIONS_BY_ID', { collections })
    dispatch('initCollectionStyleColors', collections)
    return res
  },
  async searchCollectionsNextPage ({ commit, dispatch }, nextPage) {
    const res = await api.apiCollections.getCollectionsNextPage(nextPage)
    const collections = res.data.results
    commit('SET_COLLECTIONS_BY_ID', { collections })
    dispatch('initCollectionStyleColors', collections)
    return res
  },
  async filterStyleColors ({ commit, state }, collectionId) {
    const section = state.collectionStyleColorsById[collectionId]
    const res = await api.apiCollections.getCollectionStyleColors({
      collectionId,
      available: section.available,
      sizeFilter: state.sizeFilter,
      filters: section.filters
    })
    commit('SET_COLLECTION_STYLE_COLORS', {
      collectionId,
      styleColorResults: {
        ...res.data,
        available: section.available,
        filterOptions: section.filterOptions,
        filters: section.filters,
        sizeFilter: state.sizeFilter,
        loading: false
      }
    })
  },
  updateFilters ({ state, commit, dispatch }, { collectionId, filters, available }) {
    commit('SET_COLLECTION_STYLE_COLORS', {
      collectionId,
      styleColorResults: { ...state.collectionStyleColorsById[collectionId], filters, available }
    })
    dispatch('filterStyleColors', collectionId)
  },
  clearFilters ({ commit, dispatch, rootState }, collectionId) {
    const filters = { ...rootState.closet.baseFilters }
    commit('SET_COLLECTION_STYLE_COLORS', {
      collectionId,
      styleColorResults: {
        ...state.collectionStyleColorsById[collectionId],
        filters: filters
      }
    })
    dispatch('filterStyleColors', collectionId)
  },
  updateSizeFilters ({ commit, dispatch }, { collectionId, sizeFilter, available }) {
    commit('UPDATE_SIZE_FILTERS', { sizeFilter })
    dispatch('getCollectionStyleColors', { collectionId, available })
  }
}

export const mutations = {
  ...getSetters(state),
  'SET_CLIENT_COLLECTIONS' (state, { clientId, collectionData }) {
    state.collectionDataByClientId[clientId] = collectionData
  },
  'SET_GENERIC_COLLECTIONS' (state, { filter, collectionsData }) {
    state.genericCollections[filter] = collectionsData
  },
  'SET_CLIENT_COLLECTIONS_WITH_STYLE_COLOR' (state, { styleColorId, collections }) {
    state.clientCollectionsByStyleColorId[styleColorId] = collections
  },
  'SET_STYLE_COLOR_COLLECTIONS' (state, { styleColorId, collections }) {
    state.collectionIdsByStyleColorId[styleColorId] = collections.map(collection => collection.id)
  },
  'CLEAR_STYLE_COLOR_COLLECTIONS' (state, { styleColorId }) {
    delete state.collectionIdsByStyleColorId[styleColorId]
  },
  'SET_COLLECTION_STYLE_COLORS' (state, { collectionId, styleColorResults }) {
    state.collectionStyleColorsById[collectionId] = styleColorResults
  },
  'SET_COLLECTIONS_BY_ID' (state, { collections }) {
    collections.forEach(collection => {
      state.collectionsById[collection.id] = collection
    })
  },
  'SET_COLLECTION_STYLE_COLOR_SUGGESTIONS' (state, { collectionId, suggestedStyleColors }) {
    state.collectionSuggestionsById[collectionId] = suggestedStyleColors
  },
  'UPDATE_SIZE_FILTERS' (state, { sizeFilter }) {
    state.sizeFilter = sizeFilter
  }
}
