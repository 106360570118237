<template>
  <div
    class="">
    <text-link
      v-if="linkVariant"
      id="filterButton"
      :variant="'inherit'"
      :underline="false"
      :size="'paragraph'"
      :disabled="disabled"
      class="d-flex filter-button-link"
      :class="{'selected': selected}"
      @click="$emit('click')">
      <slot/>
    </text-link>
    <base-button
      v-else
      :variant="selected ? 'primary' : 'secondary'"
      condensed
      class="me-1 filter-button"
      :text="text"
      @click="$emit('click')"/>
  </div>
</template>

<script>
import BaseButton from '@shared/components/ADORN/BaseButton'
import TextLink from '@/components/global/sequin/TextLink'

export default {
  components: {
    BaseButton,
    TextLink
  },
  props: {
    linkVariant: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
  .filter-button {
    font-family: $body-font-family;
    white-space: nowrap;
    border: 1px solid;
  }
  :deep(.text-link:hover) {
    text-decoration: none !important
  }
  .filter-button-link {
    color: $white;
    font-size: 16px;
    font-weight: 400 !important;
    display: flex;
    margin: 0 !important;
    padding: 0px !important;
    text-decoration: 0 underline transparent !important;
    @media (min-width: 576px) {
      transition: text-decoration 250ms, font-weight 250ms;
    }
    &:before {
      width: 24px;
      opacity: 0;
      content: '';
      margin-right: 8px;
    }
    &.selected {
      color: $white;
      font-weight: 700 !important;
      &:before {
      opacity: 1;
      content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check" color="white"><polyline points="20 6 9 17 4 12" stroke="white"/></svg>');
      }
    }
    &:hover {
      color: $white;
      @media (min-width: 576px) {
        color: unset;
        text-decoration: 1px underline $white !important;
        &:before {
          opacity: 1;
          transition: opacity 750ms;
          content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check" color="white"><polyline points="20 6 9 17 4 12" stroke="white"/></svg>');
        }
      }
    }
  }

</style>
