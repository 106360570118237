import { MobileAppMessage, IncomingMobileAppMessage, getMobileWebView, inMobileWebView, postToMobileWebView } from '@/utils/mobileApp.js'
import logger from '@shared/logger'
import { useClientStore } from '@shared/stores/client.js'
import { storeToRefs } from 'pinia'
import { onMounted, onUnmounted, ref } from 'vue'

export { MobileAppMessage, IncomingMobileAppMessage }

// Utility composable for detecting and communicating with the mobile app.
export default function useMobileApp () {
  const getWebView = getMobileWebView
  const inWebView = inMobileWebView
  const post = postToMobileWebView

  const messageListeners = ref(
    Object.values(IncomingMobileAppMessage).reduce((acc, message) => {
      acc[message] = []
      return acc
    }, {})
  )

  function postSignedUp () {
    const { username, email, authToken } = storeToRefs(useClientStore())
    post(MobileAppMessage.SignedUp, {
      username: username.value,
      email: email.value,
      authToken: authToken.value
    })
  }

  function isValidIncomingMessage (message) {
    return message && Object.values(IncomingMobileAppMessage).includes(message)
  }

  function onMessage (event) {
    try {
      const { message, data } = JSON.parse(event.data)
      if (isValidIncomingMessage(message)) {
        (messageListeners.value[message] ?? []).forEach(listener => {
          try {
            listener(data)
          } catch (error) {
            logger.error(`Error in message listener for ${message}:`, error)
          }
        })
      }
    } catch {
      // Ignore messages that aren't JSON
    }
  }
  onMounted(() => {
    if (inWebView()) {
      window.addEventListener('message', onMessage)
    }
  })
  onUnmounted(() => {
    if (inWebView()) {
      window.removeEventListener('message', onMessage)
    }
  })

  function addMessageListener (message, listener) {
    if (!isValidIncomingMessage(message)) {
      logger.warn(`Unable to add listener for unknown message: ${message}`)
      return
    }
    if (messageListeners.value[message].includes(listener)) {
      logger.warn(`Listener for '${message}' message already added`)
      return
    }
    messageListeners.value[message].push(listener)
  }

  function removeMessageListener (message, listener) {
    if (!isValidIncomingMessage(message)) {
      logger.warn(`Unable to remove listener for unknown message: ${message}`)
      return
    }
    const listeners = messageListeners.value[message]
    const index = listeners.indexOf(listener)
    if (index === -1) {
      logger.warn(`Listener for '${message}' message not found`)
      return
    }
    listeners.splice(index, 1)
  }

  function subscribeMessageListener (message, listener) {
    onMounted(() => { addMessageListener(message, listener) })
    onUnmounted(() => { removeMessageListener(message, listener) })
  }

  return {
    getWebView,
    inWebView,
    post,
    postSignedUp,
    addMessageListener,
    removeMessageListener,
    subscribeMessageListener
  }
}
