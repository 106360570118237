<template>
  <div class="container">
    <table class="w-100">
      <thead>
        <tr class="row header py-2">
          <th class="col text-start">
            <h5>Name</h5>
          </th>
          <th class="col d-flex justify-content-center">
            <h5>Joined</h5>
          </th>
          <th class="col d-flex align-items-center justify-content-center">
            <h5>Credit Applied</h5>
            <sequin-tooltip icon-class="ms-1 mb-2">
              Referral rewards are automatically added to your account 7 days after a friend signs up
            </sequin-tooltip>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="referee in referrals"
          :key="referee.name"
          class="row py-4">
          <td class="col">
            <div>
              <h6>
                {{ referee.refereeEmail }}
              </h6>
              {{ formatReferralSource(referee.source) }}
            </div>
          </td>
          <td class="col">
            <div class="d-flex justify-content-center">
              <referral-progress-check
                :complete="referee.joined"/>
            </div>
          </td>
          <td class="col">
            <div class="d-flex justify-content-center">
              <referral-progress-check
                :complete="referee.conversionAwarded">
                <template #detail>
                  <p
                    v-if="referee.conversionAwarded"
                    class="mt-1 ms-2 text-pewter">
                    {{ formatDate(referee.conversionAwardedAt ? referee.conversionAwardedAt : referee.modified) }}
                  </p>
                </template>
              </referral-progress-check>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ReferralProgressCheck from './ReferralProgressCheck'
import ReferralsMixin from '../global/mixins/ReferralsMixin'
import SequinTooltip from '../global/sequin/SequinTooltip.vue'

export default {
  name: 'ReferralHistory',
  components: { SequinTooltip, ReferralProgressCheck },
  mixins: [ReferralsMixin],
  props: {
    referrals: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
  th {
    font-family: $body-font-family;
    font-size: $h4-font-size;
    font-weight: $font-weight-normal;
  }

  tr {
    border-bottom: 1px solid $ash;
  }
</style>
