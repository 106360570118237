import useVuexModule from '@shared/composables/vuexModule.js'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useRouter } from 'vue-router'

// TODO: Partial implementation (ENG-2854)
export const useReviewStore = defineStore('review', () => {
  const vuex = useVuexModule('review')

  const router = useRouter()

  const skippedItemIds = ref(new Set())

  /**
   * @param {{id: string}[]|string[]|{id: string}|string} itemsToReview
   * @param {number} index
   */
  function navigateToItemReview (itemsToReview, reviewItemIndex = 0) {
    if (!Array.isArray(itemsToReview)) {
      itemsToReview = [itemsToReview]
    }
    const reviewItemIds = itemsToReview.map(item => item.id ?? item).join(',')
    router.push({ name: 'review-item', params: { reviewItemIds, reviewItemIndex } })
  }

  return {
    ...vuex,
    skippedItemIds,
    navigateToItemReview
  }
})
