<template>
  <div>
    <action-header
      :no-navigation="inClosetDetailFlyout"
      class="py-3 px-3 d-flex align-items-center border-bottom"
      @back-click="onBackClick">
      {{ brand }}
      <template
        v-if="inClosetDetailFlyout"
        #rightHeaderSlot>
        <router-link
          :to="detailPageRoute"
          class="text-reset px-1"
          @click="onExpandClick">
          <svg-expand/>
        </router-link>
      </template>
    </action-header>
    <div class="d-flex justify-content-center w-100">
      <photo-snap-scroll
        :key="styleColor.id"
        :full-width="true"
        :length="allImages.length">
        <template #scrollable>
          <preview-image
            v-for="(image, index) in allImages"
            :key="index"
            class="d-flex align-items-center scroll-item"
            size="xx-large"
            :src="getImageUrl(image)"/>
        </template>
        <template #overlay>
          <style-color-quick-actions
            orientation="horizontal"
            size="x-large"
            :style-color="styleColor"
            :style-color-source="styleColorSource"/>
        </template>
        <template #static>
          <div
            v-if="styleColor.modelSizeCaption"
            class="model-size small-text">
            {{ styleColor.modelSizeCaption }}
          </div>
        </template>
      </photo-snap-scroll>
      <style-color-image-overlay
        :message="sale && styleColor.saleEligible ? sale.displayName : ''"/>
    </div>
    <div class="px-4">
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <text-link
            class="mb-1"
            type="router-link"
            variant="secondary"
            :to="brandPageLink">
            {{ brand }}
          </text-link>
          <h6>
            {{ styleColor.style.name }}
          </h6>
        </div>
        <plain-button
          v-if="shareApiAvailable"
          class="pe-1"
          @click="onShareClick">
          <svg-share/>
        </plain-button>
      </div>
      <closet-detail-star-rating
        dense
        :style-color="styleColor"
        @ratings-click="onRatingsClick"/>
      <div
        class="pt-3">
        <closet-detail-size-select
          v-if="!isDiscontinued && recommendedSizeLoaded && loaded && !inClosetDetailFlyout"
          v-model="selectedItemType"
          :style-color="styleColor"
          @update-size-selection="updateSizeSelection"/>

        <template v-if="!inClosetDetailFlyout">
          <closet-item-type-action-buttons
            v-if="loaded"
            class="mt-1 pb-3"
            :selected-item-type="selectedItemType"
            :style-color="styleColor"/>
          <div
            v-else>
            <sequin-button
              block
              type="router-link"
              :to="{
                name: 'style-quiz-intro',
                query: {
                  r: shareProfileId
                }}">
              Sign up to rent this style
            </sequin-button>
            <div
              class="mt-3 sign-up small-text text-center">
              Access thousands of high-end styles for one flat monthly
              price! Learn <text-link
                variant="tertiary"
                @click="onHowItWorksClick">
                how it works
              </text-link> or
              <text-link
                type="router-link"
                variant="tertiary"
                :to="{ name: 'style-quiz-intro'}">
                take our 3-minute style quiz
              </text-link>
              to get started.
            </div>
          </div>
        </template>
        <closet-detail-client-collections
          :style-color="styleColor"
          class="pb-3"/>
      </div>
    </div>
    <closet-detail-additional-colors
      v-if="additionalColors.length"
      class="pt-3 px-4 mb-4"
      :style-color="styleColor"/>
    <div
      v-if="memberPhotoAlbumEntries"
      class="px-4"
      :class="{'px-md-0': !forceMobile}">
      <h6 class="mb-3">
        Member Photos ({{ memberPhotoAlbumEntries.length }})
      </h6>
      <closet-photo-scroll
        :style-color-id="styleColor.id"
        :entries="memberPhotoAlbumEntries"/>
    </div>
    <closet-page-detail-mobile-pivot-menu
      v-if="!isDiscontinued && isMobile"
      ref="pivot"
      :style-color="styleColor"
      :selected-item-type="itemTypeSelectionFilter"/>
    <collection-signup-footer
      ref="signupFooter"/>
  </div>
</template>

<script>
import { mapActions as mapVuexActions, mapGetters, mapState } from 'vuex'
import { mapActions } from 'pinia'
import { getImageUrl, slugify } from '@/utils/stringParsing.js'
import { StyleColorMixin } from '../styleColorSet/StyleColorMixin'
import { ClosetImagesMixin } from './ClosetImagesMixin'
import { ClosetMemberPhotoMixin } from './ClosetMemberPhotoMixin'
import { useOverlaysStore } from '@/stores/overlays.js'

import CollectionSignupFooter from '../collections/CollectionSignupFooter.vue'
import ActionHeader from '../global/sequin/ActionHeader.vue'
import ClosetDetailClientCollections from './ClosetDetailClientCollections.vue'
import ClosetItemTypeActionButtons from './ClosetItemTypeActionButtons'
import ClosetDetailSizeSelect from './ClosetDetailSizeSelect'
import PhotoSnapScroll from '../global/sequin/PhotoSnapScroll'
import PreviewImage from '@shared/components/ADORN/PreviewImage.vue'
import SequinButton from '../global/sequin/SequinButton'
import TextLink from '../global/sequin/TextLink'
import PlainButton from '../global/sequin/PlainButton'
import useAnalytics from '@shared/composables/analytics.js'
import StyleColorImageOverlay from '../styleColorSet/StyleColorImageOverlay'
import ClosetDetailStarRating from './ClosetDetailStarRating'
import ClosetPhotoScroll from './ClosetPhotoScroll.vue'
import ClosetDetailAdditionalColors from './ClosetDetailAdditionalColors'
import ClosetPageDetailMobilePivotMenu from './ClosetPageDetailMobilePivotMenu.vue'
import StyleColorQuickActions from '@shared/components/ADORN/StyleColorQuickActions.vue'
import SvgExpand from '@/components/global/svg/SvgExpand.vue'
import SvgShare from '@/components/global/svg/SvgShare.vue'

export default {
  components: {
    CollectionSignupFooter,
    ClosetDetailClientCollections,
    PreviewImage,
    PhotoSnapScroll,
    ActionHeader,
    ClosetItemTypeActionButtons,
    ClosetDetailSizeSelect,
    SequinButton,
    TextLink,
    PlainButton,
    StyleColorImageOverlay,
    ClosetDetailStarRating,
    ClosetPhotoScroll,
    ClosetDetailAdditionalColors,
    ClosetPageDetailMobilePivotMenu,
    StyleColorQuickActions,
    SvgExpand,
    SvgShare
  },
  mixins: [
    StyleColorMixin,
    ClosetImagesMixin,
    ClosetMemberPhotoMixin
  ],
  inject: {
    inClosetDetailFlyout: {
      default: false
    },
    selectionContext: {
      default: null
    }
  },
  props: {
    styleColor: {
      type: Object,
      required: true
    },
    styleColorSource: {
      type: Object,
      required: true
    }
  },
  setup () {
    const { track } = useAnalytics()
    return {
      track
    }
  },
  data () {
    return {
      selectedItemType: null
    }
  },
  computed: {
    ...mapGetters('closet', [
      'selectedAvailableItemTypes',
      'styleColorSegmentData'
    ]),
    ...mapGetters('client', [
      'canSelectItems',
      'clientShareLinkParams'
    ]),
    ...mapState('client', [
      'loaded',
      'isAuthenticated'
    ]),
    ...mapState('closet', [
      'sale'
    ]),
    ...mapState('community', [
      'profileShareToken',
      'clientInfoById'
    ]),
    shareProfileId () {
      return this.$route.query.shareProfileId
    },
    shareProfile () {
      return this.clientInfoById[this.shareProfileId]
    },
    shareApiAvailable () {
      return !!navigator.share
    },
    // Read-only property for child components to filter based on the selected ItemType.
    // Used by the ClosetDetailFlyout. Components that read/write selectedItemType`
    // should be disabled when `selectionContext` is provided.
    itemTypeSelectionFilter () {
      return this.selectionContext?.itemType ?? this.selectedItemType
    },
    detailPageRoute () {
      return {
        name: 'closet-detail',
        params: {
          name: slugify(`${this.styleColor.style.brand} ${this.styleColor.style.name}`),
          id: this.styleColor.id
        }
      }
    }
  },
  async mounted () {
    // Add the share token / id to the URL automatically in case the
    // member does a copy and paste from the browser bar
    if (!this.inClosetDetailFlyout && !this.$route.query.share && this.isAuthenticated) {
      if (!this.profileShareToken) {
        await this.getProfileShareToken()
      }
      this.$router.replace(
        {
          path: this.$route.path,
          query: {
            ...this.$route.query,
            ...this.clientShareLinkParams
          },
          hash: this.$route.hash
        }
      )
    }
    if (!('recommendedSize' in this.styleColor)) {
      await this.getClientSizingForStyleColor(this.styleColor.id)
    }
  },
  async unmounted () {
    // Remove the share token / id from the URL automatically. This
    // is needed when ClosetPageDetail is displayed as a flyout.
    if (!this.inClosetDetailFlyout && this.$route.query.share) {
      const shareTokens = Object.keys(this.clientShareLinkParams).reduce((acc, key) => {
        acc[key] = undefined
        return acc
      }, {})
      this.$router.replace({ path: this.$route.path, query: { ...this.$route.query, ...shareTokens } })
    }
  },
  methods: {
    ...mapVuexActions('closet', [
      'getPriceRange',
      'getClientSizingForStyleColor'
    ]),
    ...mapVuexActions('community', [
      'getProfileShareToken'
    ]),
    ...mapActions(useOverlaysStore, [
      'closeFlyout'
    ]),
    getImageUrl,
    updateSizeSelection (selection) {
      this.selectedItemType = selection
    },
    onHowItWorksClick () {
      window.location = 'https://armoire.style/howitworks/'
    },
    onShareClick () {
      this.track('Shared PDP - WebShare')
      navigator.share({
        text: `Check out this ${this.styleColor.style.name} from Armoire!`,
        url: 'https://armoire.style' + this.$route.path
      })
    },
    onBackClick () {
      if (this.inClosetDetailFlyout) {
        this.closeFlyout()
      }
    },
    onExpandClick () {
      this.track('Expanded PDP', {
        styleColor: this.styleColorSegmentData(this.styleColor.id)
      })
    },
    onRatingsClick () {
      this.$refs.pivot.setTabSelected({ id: 'reviews' })
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.text-link) {
  padding: 0px;
}

:deep(.preview-image.xx-large) {
  border-radius: 0px;
  flex-shrink: 0;
  width: 100%;
  height: auto;
}

:deep(.bookmark) svg {
  fill: $velvet;
  path {
  stroke: $velvet
  }
}
.model-size {
  background-color: $origami;
  color: $plum;
  padding: 16px;
  text-align: center;
}
</style>
