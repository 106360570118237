<template>
  <BaseStickyFooter
    id="get-started-footer"
    :is-visible="showMobileFooter">
    <template #content>
      <GetStartedButton
        location="Mobile Prospect Footer"
        fixed-width/>
    </template>
  </BaseStickyFooter>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import BaseStickyFooter from '@shared/components/ADORN/BaseStickyFooter.vue'
import GetStartedButton from '@/homepage/components/GetStartedButton.vue'
import { useHomepageStore } from '@/stores/homepage.js'

const { showMobileFooter } = storeToRefs(useHomepageStore())
</script>
