<template>
  <div>
    <transition
      name="fade"
      appear>
      <div
        class="search-bar"
        tabindex="-1"
        role="dialog">
        <div
          class="input-wrapper"
          :class="{'input-wrapper-autosuggest-open' : shouldShowAutosuggest}">
          <div class="d-flex align-items-center w-100">
            <SvgSearch/>
            <form
              class="w-100 mb-0"
              @submit.prevent="doSearch">
              <input
                ref="searchInput"
                :value="searchBarQuery"
                class="search-input"
                placeholder="What are you looking for today?"
                type="search"
                title="Search"
                @focus="onSearchFocus"
                @blur="onSearchBlur"
                @input="debounceAutosuggest">
            </form>
          </div>
          <div class="end-buttons">
            <PlainButton
              v-if="searchBarQuery && searchBarQuery.length"
              class="p-0 text-pewter small-text me-1 ms-1 me-md-3"
              @click="clearSearch">
              clear
            </PlainButton>
          </div>
        </div>
        <div
          v-if="hasResults && shouldShowAutosuggest"
          class="autosuggest-list-wrapper">
          <ul class="autosuggest-list">
            <li
              v-for="(suggestion, index) in autosuggestBrandResults"
              :key="suggestion.id"
              class="suggestion col"
              :class="{ 'hovered': index === autoSuggestIndex }"
              @click="clickAutosuggestBrand(suggestion)">
              <span v-html="suggestion.name"/>
            </li>
            <li
              v-for="(suggestion, index) in autosuggestList"
              :key="index"
              class="suggestion col"
              :class="{ 'hovered': index === autoSuggestIndex - autosuggestDefaultStart, 'recent': suggestion.type === 'recent' }"
              @click="clickAutosuggest(suggestion.query)">
              <svg-history
                v-if="suggestion.type === 'recent'"
                class="recent-svg"
                height="18"
                width="18"/>
              <span v-html="suggestion.displayText"/>
            </li>
            <li
              v-if="state.autosuggestMemberResults.length"
              class="suggestions-sub-header smaller">
              Members
            </li>
            <li
              v-for="(suggestion, index) in state.autosuggestMemberResults"
              :key="suggestion.id"
              class="suggestion col"
              :class="{ 'hovered': index === autoSuggestIndex - state.autosuggestMemberStart}"
              @click="clickAutosuggestMember(suggestion.id)">
              <AvatarImage
                :profile-pic="suggestion.profilePic"
                :client-id="suggestion.id"
                size="tiny"
                class="me-2"/>
              <span v-html="suggestion.displayText"/>
            </li>
            <li
              v-if="state.autosuggestCollectionResults.length"
              class="suggestions-sub-header smaller">
              Collections
            </li>
            <li
              v-if="state.autosuggestCollectionResults.length"
              class="collections-wrapper">
              <HorizontalScroll
                hide-desktop-scroll-buttons
                class="collections">
                <CollectionPreview
                  v-for="(collection, i) in state.autosuggestCollectionResults"
                  :key="collection.id"
                  :class="[
                    i === 0 ? 'first-collection' : 'ms-3',
                    {'me-3': i === state.autosuggestCollectionResults.length - 1},
                    {'mobile': isMobile}
                  ]"
                  :collection="{
                    ...collection,
                    styleColors: styleColorsFromCollectionId(collection.id),
                    length: `${styleColorCountFromCollectionId(collection.id)} style${styleColorCountFromCollectionId(collection.id) > 1 ? 's' : ''}`,
                    toRoute: determineCollectionRoute(collection)
                  }"
                  class="collection-preview"
                  :analytics-source="{ type: 'search', searchTerm: state.autosuggestTerm }"
                  @click="clickAutosuggestCollection(collection)"/>
              </HorizontalScroll>
            </li>
            <li
              v-if="state.autosuggestMemberResults.length"
              class="suggestion"
              :class="{ 'hovered': autoSuggestIndex === allAutosuggestResults.length - 1 }"
              @click="doSearch({ membersOnly: true })">
              <svg-search
                height="20"
                class="me-3"/>
              Search "<b>{{ autosuggestTerm }}</b>"
            </li>
          </ul>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import PlainButton from '@/components/global/sequin/PlainButton'
import AvatarImage from '@/components/community/common/AvatarImage'
import CollectionPreview from '@/components/collections/CollectionPreview'
import HorizontalScroll from '@/components/global/sequin/HorizontalScroll'
import SvgHistory from '@/components/global/svg/SvgHistory.vue'
import SvgSearch from '@/components/global/svg/SvgSearch.vue'
import useAnalytics from '@shared/composables/analytics.js'
import { slugify } from '@/utils/stringParsing'
import { useRouter } from 'vue-router'
import { getSectionRouteLocation } from '@shared/utils/route.js'

const store = useStore()
const router = useRouter()

const state = computed(() => store.state.search)
const emptyAutosuggestList = computed(() => store.getters['search/emptyAutosuggestList'])
const autosuggestTerm = computed(() => store.getters['search/autosuggestTerm'])
const styleColorsFromCollectionId = computed(() => store.getters['collections/styleColorsFromCollectionId'])
const styleColorCountFromCollectionId = computed(() => store.getters['collections/styleColorCountFromCollectionId'])
const searchBarQuery = ref('')
const autoSuggestIndex = ref(-1)
const isAutocomplete = ref(false)
const searchInput = ref(null)
const newAutosuggestStarted = ref(false)

let debounceAutosuggestTimeout = null

const isMobile = computed(() => store.getters['global/isMobile'])

const searchPageQuery = computed(() => {
  return router.query ? router.query.q : null
})

const shouldShowAutosuggest = computed(() => {
  return state.value.searchSessionActive
})

const autosuggestList = computed(() => {
  // When there's no results and no query in progress, show recent and empty state suggestions
  if (!state.value.autosuggestResults.length &&
    (!newAutosuggestStarted.value || !searchPageQuery.value || !searchBarQuery.value)) {
    return emptyAutosuggestList.value
  }

  return state.value.autosuggestResults.slice(0, 5).map(result => {
    return {
      ...result,
      displayText: result.displayText.replace(//g, '<b>').replace(//g, '</b>'),
      type: 'autosuggest'
    }
  })
})

const lastSelectableAutosuggestIndex = computed(() => {
  if (state.value.autosuggestMemberResults.length > 0) {
    return autosuggestList.value.length + state.value.autosuggestMemberResults.length + autosuggestDefaultStart.value + 1
  }
  return autosuggestList.value.length
})

const autosuggestDefaultStart = computed(() => {
  return state.value.autosuggestBrandResults.length
})

const autosuggestBrandResults = computed(() => {
  return state.value.autosuggestBrandResults
})

// Unified list of autosuggest results to avoid messy pointer logic
const allAutosuggestResults = computed(() => {
  return [
    ...state.value.autosuggestBrandResults,
    ...autosuggestList.value,
    // Repeat the last autosuggest result to skip Members header
    autosuggestList.value[autosuggestList.value.length - 1],
    ...state.value.autosuggestMemberResults,
    state.value.autosuggestTerm
  ]
})

const hasResults = computed(() => {
  return true
})

const movePointerDown = () => {
  if (autoSuggestIndex.value >= lastSelectableAutosuggestIndex.value) {
    autoSuggestIndex.value = -1
  } else {
    const nonMemberResultsLength = state.value.autosuggestResults.length + state.value.autosuggestBrandResults.length
    if (state.value.autosuggestMemberResults.length > 0 && autoSuggestIndex.value === nonMemberResultsLength - 1) {
      // skip the "members" subheader
      autoSuggestIndex.value++
    }
    autoSuggestIndex.value++
  }
  setQueryFromPointer()
}

const onSearchFocus = () => {
  return startSearchSession()
}

const onSearchBlur = () => {
  // wait for click to register, then end search session.
  setTimeout(() => {
    endSearchSession()
  }, 400)
}

const movePointerUp = () => {
  if (autoSuggestIndex.value >= 0) {
    autoSuggestIndex.value--
  } else {
    autoSuggestIndex.value = lastSelectableAutosuggestIndex.value
  }
  setQueryFromPointer()
}

const setQueryFromPointer = () => {
  if (autoSuggestIndex.value === -1) {
    searchBarQuery.value = state.value.currentSessionQuery?.trim()
    isAutocomplete.value = false
  } else {
    const { name, query } = allAutosuggestResults.value[autoSuggestIndex.value]
    searchBarQuery.value = (name || query || allAutosuggestResults.value[autoSuggestIndex.value])?.trim()
    isAutocomplete.value = true
  }
  newAutosuggestStarted.value = false
}

const doAutosuggest = () => {
  if (!newAutosuggestStarted.value) newAutosuggestStarted.value = searchBarQuery.value?.trim()
  isAutocomplete.value = false
  store.dispatch('search/getAutosuggest', searchBarQuery.value?.trim())
}

const debounceAutosuggest = (event) => {
  searchBarQuery.value = event.target.value
  clearTimeout(debounceAutosuggestTimeout)
  debounceAutosuggestTimeout = setTimeout(() => {
    doAutosuggest(event)
  }, 300)
}

const clickAutosuggest = (val, membersOnly = false) => {
  searchBarQuery.value = val
  doSearch({ isAutoComplete: true, membersOnly })
  trackAutosuggestClick('items', val)
}

const clickAutosuggestMember = (val) => {
  router.push({ name: 'profile', params: { id: val } })
  endSearchSession()
  trackAutosuggestClick('members', val)
}

const clickAutosuggestBrand = (brand) => {
  router.push({
    name: 'brand',
    params: {
      brandId: brand.id,
      name: slugify(brand.name)
    }
  })
  trackAutosuggestClick('brands', brand.id)
  endSearchSession()
}

const clickAutosuggestCollection = (collection) => {
  trackAutosuggestClick('collections', collection.id)
  endSearchSession()
}

const determineCollectionRoute = (collection) => {
  switch (collection.sourceType) {
    case 'collection':
      return {
        name: 'collection',
        params: { id: collection.id }
      }
    case 'section':
      return getSectionRouteLocation({ id: collection.id, name: collection.name })
    default:
      return null
  }
}

const { track } = useAnalytics()
const trackAutosuggestClick = (type, value) => {
  track('Click Autosuggest', {
    type,
    value,
    searchTerm: searchBarQuery.value
  })
}

const clearSearch = () => {
  searchBarQuery.value = ''
  store.dispatch('search/getAutosuggest', searchBarQuery.value)
  newAutosuggestStarted.value = false
  searchInput.value.focus()
}

const doSearch = ({ isAutocomplete, membersOnly = false }) => {
  const searchText = searchBarQuery.value?.trim()
  if (searchText) {
    const brandSelected = autoSuggestIndex.value > -1 && autoSuggestIndex.value < autosuggestDefaultStart.value && state.value.autosuggestBrandResults.length
    const memberSelected = autoSuggestIndex.value >= state.value.autosuggestMemberStart && autoSuggestIndex.value !== allAutosuggestResults.value.length - 1

    if (brandSelected) {
      clickAutosuggestBrand(allAutosuggestResults.value[autoSuggestIndex.value])
    } else if (memberSelected) {
      clickAutosuggestMember(allAutosuggestResults.value[autoSuggestIndex.value].id)
    } else {
      const routeInfo = { name: 'searchResults', query: { q: searchText } }
      if (membersOnly) {
        routeInfo.query.m = 1
      }
      store.dispatch('search/newSearch', { data: { query: searchText }, isAutocomplete })
      router.push(routeInfo)
      store.dispatch('search/getAutosuggest', searchText)
      endSearchSession()
    }
  }
}

const handleKeyInput = (event) => {
  if (!event) return
  if (event.key === 'Escape') {
    endSearchSession()
  }
  if (event.key === 'ArrowDown') {
    event.preventDefault()
    movePointerDown()
  }
  if (event.key === 'ArrowUp') {
    event.preventDefault()
    movePointerUp()
  }
}

onMounted(async () => {
  // populate with the current search when on a search page
  if (searchPageQuery.value) {
    searchBarQuery.value = searchPageQuery.value
    await store.dispatch('search/fetchAutosuggestResults')
    fetchAutosuggest(searchBarQuery.value)
  }
  window.addEventListener('keydown', handleKeyInput)
})

onUnmounted(() => {
  window.removeEventListener('keydown', handleKeyInput)
})

const fetchAutosuggest = (query) => {
  store.dispatch('search/getAutosuggest', query)
}

const startSearchSession = () => {
  store.dispatch('search/startSearchSession')
}

const endSearchSession = () => {
  store.dispatch('search/endSearchSession')
}

</script>

<style lang="scss" scoped>
$searchInputHeight: 58px;

.search-bar {
  left: 0;
  right: 0;
  outline: 0;
  margin: 32px auto 0 auto;
  position: relative;
}

// Get rid of default webkit search input styling
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

.input-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: $searchInputHeight;
  align-items: center;
  background: $white;
  padding: 20px;
  border: 1px solid $ash;
  &-autosuggest-open {
    box-shadow: 0px 4px 4px rgb(186, 186, 191, .25);
    border-bottom: unset;
  }
}

.search-input {
  outline: none;
  border: none;
  width: 100%;
}

.end-buttons {
  flex-shrink: 0;
}

.autosuggest-list-wrapper {
  color: $pewter;
  position: absolute;
  max-height: 80vh;
  z-index: $zindex-dropdown;
  left: 0;
  right: 0;

  .autosuggest-list {
    list-style: none;
    margin: auto;
    padding: 0;
    background: $white;
    // box-shadow: 0px 3px 31px 1px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 4px 4px rgb(186, 186, 191, .25);

    border: 1px solid $ash;
    border-top: none;
  }
}
.suggestion {
  cursor: pointer;
  padding: 10px 52px;
  display: flex;
  align-items: center;

  &:hover, &.hovered {
    background-color: darken($origami, 5%);
  }

  &.recent {
    padding: 10px 52px 10px 24px;
  }
}

.suggestions-sub-header {
  padding: 10px 52px;
}

.recent-svg {
  margin-right: 12px;
  color: $ash;
}

.collections {
  margin-bottom: 18px;
}

.collection-preview {
  height: 288px;
  width: 180px;
  &.mobile {
    height: 268px;
  }
}
</style>
