import { defineStore } from 'pinia'
import useVuexModule from '@shared/composables/vuexModule.js'

// TODO: Partial implementation (ENG-2848)
export const useCollectionsStore = defineStore('collections', () => {
  const vuex = useVuexModule('collections', {
    defaults: {
      state: [
        ['collectedStyleColors', []]
      ]
    }
  })

  return {
    ...vuex
  }
})
