import { toSnakeCase } from '@/utils/stringParsing.js'
import { isProd } from '@shared/utils/environment.js'
import { computed, isRef, ref } from 'vue'
import { useLogger } from 'vue-logger-plugin'
import { useStore } from 'vuex'

// Temporary helper composable to interface with Vuex (ENG-2862)
export default function useVuexModule (moduleName, options = { defaults: {} }) {
  const logger = useLogger()
  const store = useStore()

  const state = Object.keys(store.state[moduleName] ?? []).reduce((acc, key) => {
    acc[key] = computed({
      get () {
        checkDuplicateAccess(key)
        return store.state[moduleName][key]
      },
      set (value) { commit(`SET_${toSnakeCase(key).toUpperCase()}`, value) }
    })
    return acc
  }, {})

  const prefix = `${moduleName}/`
  const getNewKey = (key) => key.replace(prefix, '')

  const getters = Object.keys(store.getters).reduce((acc, key) => {
    if (key.startsWith(prefix)) {
      const getter = getNewKey(key)
      acc[getter] = computed(() => {
        checkDuplicateAccess(getter)
        return store.getters[key]
      })
    }
    return acc
  }, {})

  function dispatch (action, payload) {
    if (store.hasModule(moduleName) && store._actions[`${moduleName}/${action}`]) {
      return store.dispatch(`${moduleName}/${action}`, payload)
    } else if (defaults[action]) {
      checkDuplicateAccess(action)
      return defaults[action](payload)
    }
  }

  const actions = Object.keys(store._actions).reduce((acc, key) => {
    if (key.startsWith(prefix)) {
      const action = getNewKey(key)
      acc[action] = (payload) => dispatch(action, payload)
    }
    return acc
  }, {})

  function commit (mutation, payload) {
    if (store.hasModule(moduleName) && store._mutations[`${moduleName}/${mutation}`]) {
      store.commit(`${moduleName}/${mutation}`, payload)
    } else if (defaults[mutation]) {
      checkDuplicateAccess(mutation)
      defaults[mutation](payload)
    }
  }

  const mutations = Object.keys(store._mutations).reduce((acc, key) => {
    if (key.startsWith(prefix)) {
      const mutation = getNewKey(key)
      acc[mutation] = (payload) => commit(mutation, payload)
    }
    return acc
  }, {})

  const vuexModule = { state, getters, actions, mutations }

  /** No-op function for intentionally unimplemented actions. */
  async function doNothing () {}

  const defaults = {}
  Object.keys(options.defaults ?? {}).forEach((key) => {
    if (!['state', 'getters', 'actions', 'mutations'].includes(key)) {
      logger.warn(`Invalid vuexModule key: ${key}`)
      return
    }

    for (const value of options.defaults[key]) {
      const [property, propertyDefault] = Array.isArray(value) ? value : [value]
      if (vuexModule[key][property]) {
        continue
      }

      const defaultValue = propertyDefault ?? (
        key === 'state'
          ? null
          : key === 'getters'
            ? () => null
            : doNothing
      )

      switch (key) {
        case 'state':
          defaults[property] = isRef(defaultValue) ? defaultValue : ref(defaultValue)
          break
        case 'getters':
          defaults[property] = isRef(defaultValue) ? defaultValue : computed(defaultValue)
          break
        case 'actions':
          defaults[property] = defaultValue
          break
        case 'mutations':
          defaults[property] = defaultValue
          break
      }
    }
  })

  const duplicates = Object.entries(
    Object.keys(vuexModule).reduce((acc, key) => {
      Object.keys(vuexModule[key]).forEach((property) => {
        if (!acc[property]) {
          acc[property] = [key]
        } else {
          acc[property].push(key)
        }
      })
      return acc
    }, {})
  ).reduce((acc, [key, value]) => {
    if (value.length > 1) {
      acc[key] = value
    }
    return acc
  }, {})

  function checkDuplicateAccess (key) {
    if (duplicates[key] && !isProd) {
      logger.warn(`useVuexModule property ${moduleName}.${key} has multiple definitions:`, duplicates[key].join(', '))
    }
  }

  return new Proxy({
    ...defaults,
    ...state,
    ...getters,
    ...actions,
    ...mutations,
    dispatch,
    commit,
    doNothing
  }, {
    // Detect attempts to access properties that don't exist in the module.
    get (target, prop) {
      if (prop in target) {
        return target[prop]
      }
      const msg = `Property "${prop}" not found in ${moduleName} vuexModule, did you forget to set a default?`
      if (isProd) {
        logger.error(msg)
      } else {
        throw new Error(msg)
      }
    }
  })
}
