import { hideIntercomLauncher, showIntercomLauncher } from '@/homepage/plugins/intercom.js'
import { whenever } from '@vueuse/core'
import { onBeforeMount, onUnmounted, ref } from 'vue'

export function useHideIntercomLauncher () {
  const intercomAvailable = ref(!!window.Intercom)

  const timeout = ref(null)
  if (!intercomAvailable.value) {
    timeout.value = setInterval(() => {
      intercomAvailable.value = !!window.Intercom
      if (intercomAvailable.value) {
        clearInterval(timeout.value)
      }
    }, 250)
  }

  const stop = ref(null)
  onBeforeMount(() => {
    if (intercomAvailable.value) {
      hideIntercomLauncher()
    } else {
      stop.value = whenever(intercomAvailable, () => {
        hideIntercomLauncher()
        stop.value()
      })
    }
  })
  onUnmounted(() => {
    clearInterval(timeout.value)
    stop.value?.()
    if (intercomAvailable.value) {
      showIntercomLauncher()
    }
  })
}
