import { useSubscriptionStore } from '@/stores/subscribe.js'
import { useClientStore } from '@shared/stores/client.js'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'

export default function usePayment () {
  const { membershipStatus } = storeToRefs(useClientStore())
  const subscriptionStore = useSubscriptionStore()
  const {
    chargeAmount,
    price,
    discountAmount,
    referralCampaign,
    referralCode,
    membershipPlanSelected,
    selectedPlanReferralPrice
  } = storeToRefs(subscriptionStore)
  const { discountPriceFromPlan } = subscriptionStore

  const promoEligible = computed(() => membershipStatus.value !== 'formerMember' && membershipStatus.value !== 'active')
  const promoName = computed(() => {
    if (!promoEligible.value || !membershipPlanSelected.value.appliedPromo) return ''
    return membershipPlanSelected.value.appliedPromo.promoCode.toUpperCase()
  })
  const promoText = computed(() => {
    if (!promoEligible.value || !membershipPlanSelected.value.appliedPromo || promoAmount.value === 0) return ''
    const months = membershipPlanSelected.value.appliedPromo.firstChargeOverride ? membershipPlanSelected.value.appliedPromo.trialMonths : membershipPlanSelected.value.appliedPromo.durationInMonths
    return (months > 1) ? `${months}-month promo: ${promoName.value}` : promoName.value
  })
  const promoAmount = computed(() => {
    if (discountAmount.value) return Number.parseFloat((discountAmount.value / 100.0)).toFixed(2)
    if (!promoEligible.value || !membershipPlanSelected.value.appliedPromo) return 0
    let amount = 0
    if (price.value != null && membershipPlanSelected.value && !membershipPlanSelected.value.appliedPromo.firstChargeOverride) {
      amount = (membershipPlanSelected.value.basePrice - price.value)
    }
    amount = amount || (membershipPlanSelected.value.appliedPromo.firstChargeOverride && ((membershipPlanSelected.value.basePrice * membershipPlanSelected.value.appliedPromo.trialMonths) - membershipPlanSelected.value.appliedPromo.firstChargeOverride))
    if (amount % 100 === 0) return amount / 100

    return Number.parseFloat((amount / 100.0)).toFixed(2)
  })
  const referralText = computed(() => {
    if (!referralCampaign.value || !referralCode.value) return ''
    return `${referralCampaign.value.refereePlanPercentOff}% off your first month`
  })
  const referralAmount = computed(() => {
    if (!referralCampaign.value || !referralCode.value) return 0
    const referralPrice = membershipPlanSelected.value.basePrice / 100.0 - selectedPlanReferralPrice.value
    return referralPrice.toFixed(2)
  })
  const subtotal = computed(() => {
    let subtotal = null
    if (membershipPlanSelected.value.appliedPromo?.firstChargeOverride) {
      return membershipPlanSelected.value.appliedPromo.firstChargeOverride / 100
    }
    if (price.value != null) {
      return price.value / 100
    }
    if (membershipPlanSelected.value?.basePrice) {
      subtotal = membershipPlanSelected.value.basePrice / 100
    }
    return subtotal
  })
  const total = computed(() => {
    if (chargeAmount.value) return chargeAmount.value / 100
    // if there's no chargeAmount, then the server has not calculated
    // tax (no address yet).  So just show whatever the plan's discounted
    // price is from the promo/referral code
    return discountPriceFromPlan(membershipPlanSelected.value)
  })

  function chargeIntervalCopy (plan) {
    switch (plan.chargeInterval) {
      case 1:
        return 'every month'
      case 2:
      case 3:
      case 6:
      case 12:
        return `every ${plan.chargeInterval} months`
      default:
        return ''
    }
  }

  function promoDiscount (plan) {
    let discountAmount = 0
    if (plan.appliedPromo && plan.appliedPromo.dollarAmount) {
      discountAmount += plan.appliedPromo.dollarAmount
    } else if (plan.appliedPromo && plan.appliedPromo.percentDiscount) {
      discountAmount += plan.basePrice * plan.appliedPromo.percentDiscount
    }
    if (plan.prepaymentDiscount) {
      discountAmount += plan.prepaymentDiscount
    }
    return discountAmount
  }

  return {
    promoEligible,
    promoName,
    promoText,
    promoAmount,
    referralText,
    referralAmount,
    subtotal,
    total,
    chargeIntervalCopy,
    promoDiscount
  }
}
