import { computed } from 'vue'
import { inMobileWebView } from '@/utils/mobileApp'

export const useUserAgent = () => {
  const getUserAgent = () => {
    if (inMobileWebView()) {
      return 'native'
    }
    const userAgent = navigator.userAgent

    if (/android/i.test(userAgent)) {
      return 'android'
    }

    if (/iPad|iPhone|iPod/i.test(userAgent)) {
      return 'ios'
    }
    return 'web'
  }

  const userAgent = computed(() => getUserAgent())

  return {
    userAgent
  }
}
