<template>
  <SignUpScreen>
    <div class="plan-choice-screen mx-auto">
      <h2 class="text-center mt-xs mb-sm mb-lg-xl">
        Your Recommended Plan
      </h2>
      <div
        class="plan-cards d-flex gap-sm align-items-center mx-auto"
        :class="{ 'flex-column': isTabletOrSmaller }">
        <PlanChoiceCard
          v-if="recommendedPlanId && membershipPlans"
          :plan-id="recommendedPlanId"
          :selectable="selectedPlanId !== recommendedPlanId"
          :expanded="expandedPlanId === recommendedPlanId"
          class="w-100"
          :class="`order-lg-${getPlanOrder(recommendedPlanId)}`"
          @selected="onSelected(recommendedPlanId)"
          @on-expand="onSelected(recommendedPlanId)"/>
        <p
          v-if="isTabletOrSmaller"
          class="semi-bold">
          Additional Plans
        </p>
        <PlanChoiceCard
          v-for="{ id: planId } in additionalPlans"
          :key="`additional-plan-${planId}`"
          :plan-id
          :selectable="selectedPlanId !== planId"
          :expanded="expandedPlanId === planId"
          :class="[`order-lg-${getPlanOrder(planId)}`, { 'w-100': isTabletOrSmaller }]"
          @selected="onSelected(planId)"
          @on-expand="onSelected(planId)"/>
      </div>
      <CalloutSection
        v-if="membershipPlans && !isTabletOrSmaller"
        :position="calloutPosition">
        <template #default>
          <div
            v-for="{ id: planId } in membershipPlans"
            :key="`callout-${planId}`">
            <FadeTransition v-if="planId === selectedPlanId">
              <ExampleCaseSection :plan-id/>
            </FadeTransition>
          </div>
        </template>
      </CalloutSection>
    </div>
  </SignUpScreen>
</template>

<script setup>
import SignUpScreen from '@/views/signUp/SignUpScreen.vue'
import PlanChoiceCard from '@/components/signUp/PlanChoiceCard.vue'
import ExampleCaseSection from '@/components/signUp/ExampleCaseSection.vue'
import { computed, onBeforeMount, onBeforeUnmount, onMounted, ref } from 'vue'
import { useSubscriptionStore } from '@/stores/subscribe.js'
import { storeToRefs } from 'pinia'
import useScreenSize from '@shared/composables/screenSize.js'
import CalloutSection from '@shared/components/ADORN/CalloutSection.vue'
import FadeTransition from '@shared/components/ADORN/Transitions/FadeTransition.vue'
import { usePlans } from '@shared/composables/plans.js'
import useMobileApp, { MobileAppMessage } from '@/composables/mobileApp.js'
import useAnalytics from '@shared/composables/analytics.js'

const { isTabletOrSmaller } = useScreenSize()

const {
  recommendedPlanId,
  recommendedPlan,
  membershipPlans,
  selectedPlanId,
  selectedPlan
} = storeToRefs(useSubscriptionStore())

const { ensurePlanIsSelected, setRoutePlanQuery } = usePlans()
const { post } = useMobileApp()

const availablePlans = computed(() => membershipPlans.value?.filter(plan => !plan.prepayment && plan.basePrice > 0))
const additionalPlans = computed(() =>
  availablePlans.value?.filter(plan => plan.id !== recommendedPlanId.value)
    .toSorted((a, b) => {
      /*
      Unlimited selected: 7-item, 4-item.
      7-item selected: Unlimited, 4-item.
      4-item selected: 7-item, Unlimited.

      This just happens to currently map to sorting by nItems descending.
      */
      return b.nItems - a.nItems
    })
)

onBeforeMount(async () => {
  await ensurePlanIsSelected()
  setRoutePlanQuery()
})
onMounted(() => { post(MobileAppMessage.SubscribeLoaded) })

const expandedPlanId = ref(null)
const expandTimeout = ref(null)
onMounted(() => {
  clearTimeout(expandTimeout.value)
  expandTimeout.value = setTimeout(() => {
    expandedPlanId.value = selectedPlanId.value
  }, 1200)
})

function onSelected (planId) {
  clearTimeout(expandTimeout.value)
  expandedPlanId.value = planId
}

function getPlanOrder (planId) {
  if (planId === recommendedPlanId.value) {
    return 1 // Selected plan in the center (assuming 3 total)
  }
  const index = additionalPlans.value.findIndex(plan => plan.id === planId)
  if (index === 0) {
    return 0 // First additional plan on the left
  }
  return 2 // Remaining additional plans on the right
}

const calloutPosition = computed(() =>
  selectedPlanId.value
    ? getPlanOrder(selectedPlanId.value) === 0
      ? 'start'
      : getPlanOrder(selectedPlanId.value) === 1
        ? 'center'
        : getPlanOrder(selectedPlanId.value) === 2
          ? 'end'
          : 'center'
    : 'center'
)

const { track } = useAnalytics()
onBeforeUnmount(() => {
  track('Plan Choice Selection', {
    recommendedPlanId: recommendedPlanId.value,
    recommendedPlanName: recommendedPlan.value?.name,
    selectedPlanId: selectedPlanId.value,
    selectedPlanName: selectedPlan.value?.name
  })
})
</script>

<style lang="scss" scoped>
.plan-choice-screen {
  max-width: 1075px;
}
:deep(.plan-card) {
  @include media-tablet-or-smaller {
    max-width: 330px;
    margin: 0 auto;
  }
}
:deep(.base-accordion .header) {
  max-width: 330px;
  margin: 0 auto;
  width: 100%;
  padding: 0 $spacing-md;
}
:deep(.accordion-content) {
  margin: 0 calc($spacing-md * -1);
}
</style>
