<template>
  <SignUpScreen heading="Tell Us About Yourself">
    <div class="mt-sm">
      <div class="sizing-screen-container">
        <h6 class="mb-0">
          Tell us your birthday:
        </h6>
        <p>We will send you a surprise on your big day!</p>
        <BaseInput
          v-model="birthDate"
          class="pt-xs"
          :validations="v$.formBirthDate"
          :cleave-options="{
            date: true,
            datePattern: ['m', 'd', 'Y']}"
          label="MM/DD/YYYY"/>
        <div class="d-flex w-100 justify-content-between mt-lgp">
          <div>
            <h6 class="mb-sm">
              Height:
            </h6>
            <div class="d-flex gap-xs">
              <DropdownSingleSelect
                v-if="styleProfile.heightFeetOptions.length > 0"
                v-model="heightFeet"
                class="input-shadow"
                small
                placeholder="Feet"
                :dropdown-options="styleProfile.heightFeetOptions"
                :initial-selection-index="getInitialSelectionIndex('heightFeet')"/>
              <DropdownSingleSelect
                v-if="styleProfile.heightInchesOptions.length > 0"
                v-model="heightInches"
                class="input-shadow"
                small
                placeholder="Inches"
                :dropdown-options="styleProfile.heightInchesOptions"
                :initial-selection-index="getInitialSelectionIndex('heightInches')"/>
            </div>
          </div>
          <div>
            <h6 class="mb-sm">
              Bra size:
            </h6>
            <div class="d-flex gap-xs">
              <DropdownSingleSelect
                v-if="styleProfile.braSizeBandOptions.length > 0"
                v-model="braSizeBand"
                class="input-shadow"
                small
                placeholder="Band"
                :dropdown-options="styleProfile.braSizeBandOptions"
                :initial-selection-index="getInitialSelectionIndex('braSizeBand')"/>
              <DropdownSingleSelect
                v-if="styleProfile.braSizeCupOptions.length > 0"
                v-model="braSizeCup"
                class="input-shadow"
                small
                placeholder="Cup"
                :dropdown-options="styleProfile.braSizeCupOptions"
                :initial-selection-index="getInitialSelectionIndex('braSizeCup')"/>
            </div>
          </div>
        </div>
        <div class="mt-xl">
          <h6 class="mb-sm text-center">
            Are you pregnant and interested in maternity styles?
          </h6>
          <SingleSelectRow
            v-model="isMaternityCustomer"
            :class="{'maternity-item': !isMobile}"
            class="input-shadow"
            :options="maternityOptions"/>
          <div
            v-if="visibleDueDate"
            class="text-center">
            <h6 class="caps mt-sm">
              Congratulations!
            </h6>
            <h6 class="mb-sm">
              When is your due date?
            </h6>
            <BaseInput
              v-model="dueDate"
              :validations="v$.formDueDate"
              :cleave-options="{
                date: true,
                datePattern: ['m', 'd', 'Y']}"
              label="Due date"/>
          </div>
        </div>
      </div>
      <div class="text-center mt-xl">
        <h6 class="mb-sm">
          What is your body shape?
        </h6>
        <HorizontalScroll
          v-if="styleProfile.bodyShapeOptions.length > 0"
          :full-bleed="true"
          class="scroll-container mx-auto">
          <IconPill
            v-for="option in styleProfile.bodyShapeOptions"
            :key="option.value"
            v-model="bodyShape"
            class="shape-pill input-shadow"
            :label="`${toTitleCase(option.value)}`"
            :description="option.text"
            type="radio"
            :value="option.value">
            <template #icon>
              <SvgBodyShapeHourglass
                v-if="option.value === 'hourglass'"/>
              <SvgBodyShapeTriangle
                v-else-if="option.value === 'triangle'"/>
              <SvgBodyShapeInvertedTriangle
                v-else-if="option.value === 'inverted_triangle'"/>
              <SvgBodyShapeOval
                v-else-if="option.value === 'oval'"/>
              <SvgBodyShapeRectangle
                v-else-if="option.value === 'rectangle'"/>
            </template>
          </IconPill>
        </HorizontalScroll>
      </div>
    </div>
  </SignUpScreen>
</template>

<script setup>
import SignUpScreen from '@/views/signUp/SignUpScreen.vue'
import DropdownSingleSelect from '@/components/global/sequin/DropdownSingleSelect.vue'
import SingleSelectRow from '@/components/global/sequin/SingleSelectRow.vue'
import IconPill from '@shared/components/ADORN/IconPill.vue'
import SvgBodyShapeHourglass from '@/components/global/svg/SvgBodyShapeHourGlass.vue'
import SvgBodyShapeInvertedTriangle from '@/components/global/svg/SvgBodyShapeInvertedTriangle.vue'
import SvgBodyShapeRectangle from '@/components/global/svg/SvgBodyShapeRectangle.vue'
import SvgBodyShapeTriangle from '@/components/global/svg/SvgBodyShapeTriangle.vue'
import SvgBodyShapeOval from '@/components/global/svg/SvgBodyShapeOval.vue'
import { useStyleProfileStore } from '@/stores/styleProfile.js'
import { computed, ref } from 'vue'
import { toTitleCase } from '@/utils/stringParsing'
import HorizontalScroll from '@shared/components/ADORN/HorizontalScroll.vue'
import useScreenSize from '@shared/composables/screenSize'
import BaseInput from '@/components/global/BaseInput.vue'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { date, futureDate } from '@/components/global/vuelidate/customValidators.js'

const { isMobile } = useScreenSize()

const maternityOptions = [
  {
    text: 'Yes',
    value: 'true'
  },
  {
    text: 'No',
    value: 'false'
  }
]

const styleProfile = useStyleProfileStore()
const visibleDueDate = computed(() => styleProfile.isMaternityCustomer === 'true')

const rules = {
  formDueDate: { required, date, futureDate },
  formBirthDate: { required, date }
}

const formBirthDate = ref('')
const formDueDate = ref('')
const v$ = useVuelidate(rules, {
  formDueDate, formBirthDate
})

const birthDate = computed({
  get () {
    if (formBirthDate.value) return formBirthDate.value
    return styleProfile.birthDate
  },
  set (value) {
    formBirthDate.value = value
    if (!v$.value.formBirthDate.$pending && !v$.value.formBirthDate.$invalid) {
      styleProfile.SET_BIRTH_DATE(value)
    }
  }
})
const dueDate = computed({
  get () {
    if (formDueDate.value) return formDueDate.value
    return styleProfile.dueDate
  },
  set (value) {
    formDueDate.value = value
    if (!v$.value.formDueDate.$pending && !v$.value.formDueDate.$invalid) {
      styleProfile.SET_DUE_DATE(value)
    }
  }
})
const braSizeBand = computed({
  get () {
    return styleProfile.braSizeBand
  },
  set (value) {
    styleProfile.SET_BRA_SIZE_BAND(value)
  }
})
const braSizeCup = computed({
  get () {
    return styleProfile.braSizeCup
  },
  set (value) {
    styleProfile.SET_BRA_SIZE_CUP(value)
  }
})
const heightFeet = computed({
  get () {
    return styleProfile.heightFeet
  },
  set (value) {
    styleProfile.SET_HEIGHT_FEET(value)
  }
})
const heightInches = computed({
  get () {
    return styleProfile.heightInches
  },
  set (value) {
    styleProfile.SET_HEIGHT_INCHES(value)
  }
})
const isMaternityCustomer = computed({
  get () {
    return styleProfile.isMaternityCustomer
  },
  set (value) {
    styleProfile.SET_IS_MATERNITY_CUSTOMER(value)
    if (value === 'false') {
      styleProfile.SET_DUE_DATE(null)
    }
  }
})
const bodyShape = computed({
  get () {
    return styleProfile.bodyShape ?? ''
  },
  set (value) {
    styleProfile.SET_BODY_SHAPE(value)
  }
})

const getInitialSelectionIndex = (type) => {
  const options = styleProfile[`${type}Options`]
  const selectedOption = styleProfile[`${type}`]

  if (selectedOption && options) {
    const index = options.findIndex(option => option.value === selectedOption)
    if (index > -1) return index
  }

  return null
}

</script>

<style lang="scss" scoped>
:deep(.single-select-row label) {
  padding: 8px !important;
  border-radius: $system-border-radius;
}
.dropdown-selector {
  width: 83px;
  min-width: unset;

  :deep(.dropdown-single-select) {
    padding: $spacing-xs $spacing-xxs $spacing-xs $spacing-xs;
  }

  :deep(.selected-option) {
    overflow: hidden;
  }

  :deep(svg) {
    margin-left: 0 !important;
  }
}
.sizing-screen-container {
  width: 100%;
  max-width: fit-content;
  margin: 0 auto;
}
.maternity-item {
  max-width: 415px;
  margin: 0 auto;
}
.scroll-container {
  max-width: 851px;
}
.shape-pill {
    min-width: 128px;
    width: 128px;
    align-self: stretch;
  :deep(.label) {
    height: 40px !important;
  }
  :deep(.description) {
    height: 60px;
  }
}
svg {
  width: 20px;
  height: 32px;
}
.input-shadow {
  box-shadow: 0px 1px 8px 0px rgba($armor, 0.10);
}
</style>
