
/** @typedef {import("vue-router").RawRouteComponent} RawRouteComponent */
/** @typedef {import("vue-router").RouteRecordRaw} RouteRecordRaw */
/** @typedef {import("vue-router").RouteRecord} RouteRecord */
/** @typedef {RouteRecordRaw | RouteRecord} Route */

/**
 * @param {string} name
 * @param {Route[]} routes
 * @returns {Route | null}
 */
export function getRouteByName (name, routes) {
  for (const route of routes) {
    if (route.name === name) {
      return route
    }
    if (route.children) {
      const childRoute = getRouteByName(name, route.children)
      if (childRoute) {
        return childRoute
      }
    }
  }
  return null
}

/**
 * @param {Route | null} route
 * @returns {RawRouteComponent | null}
 */
export function getRouteComponent (route) {
  return route?.components?.default ?? route?.component ?? null
}

/**
 * @param {string} name
 * @param {Route[]} routes
 * @returns {RawRouteComponent | null}
 */
export function getRouteComponentByName (name, routes) {
  return getRouteComponent(getRouteByName(name, routes))
}

/**
 * @param {Record<string, string>} routeReplacements
 * @param {RouteRecord[]} registeredRoutes
 */
export function areRouteComponentsReplaced (routeReplacements, registeredRoutes) {
  return Object.entries(routeReplacements).every(([name, replacement]) => {
    const route = getRouteByName(name, registeredRoutes)
    if (!route) {
      // eslint-disable-next-line no-console
      console.error(`Unable to confirm route component replacement for '${name}': route not found`)
      return true
    }
    const component = getRouteComponent(route)
    if (!component) {
      // eslint-disable-next-line no-console
      console.error(`Unable to confirm route component replacement for '${name}': route has no component`)
      return true
    }
    const replacementComponent = getRouteComponentByName(replacement, registeredRoutes)
    if (!replacementComponent) {
      // eslint-disable-next-line no-console
      console.error(`Unable to confirm route component replacement for '${name}': replacement component not found`)
      return true
    }
    return component === replacementComponent
  })
}

/**
 * @param {string} name
 * @param {RouteRecordRaw[]} routes
 */
export function getRouteParent (name, routes) {
  for (const route of routes) {
    if (route.children) {
      if (route.children.some(child => child.name === name)) {
        return route
      }
      const parent = getRouteParent(name, route.children)
      if (parent) {
        return parent
      }
    }
  }
  return null
}

/**
 * @param {Record<string, string>} routeReplacements
 * @param {RouteRecordRaw[]} routes
 * @param {import("vue-router").Router} router
 */
export function replaceRouteComponents (routeReplacements, routes, router) {
  const replacements = Object.entries(routeReplacements).map(([name, replacement]) => {
    const route = getRouteByName(name, routes)
    if (!route) {
      // eslint-disable-next-line no-console
      console.error(`Unable to replace component for route '${name}': route not found`)
      return null
    }
    const component = getRouteComponentByName(replacement, routes)
    if (!component) {
      // eslint-disable-next-line no-console
      console.error(`Unable to find component from '${replacement}'`)
      return null
    }
    return { ...route, component }
  }).filter(Boolean)
  for (const replacement of replacements) {
    const parent = getRouteParent(replacement.name, routes)
    if (!parent?.name) {
      // eslint-disable-next-line no-console
      console.error(`Unable to replace component for route '${replacement.name}': parent not found or has no name`)
      continue
    }
    router.addRoute(parent.name, replacement)
  }
}
