
export default {
  install (_app, { test }) {
    const INTERCOM_KEY = test ? process.env.VUE_APP_INTERCOM_KEY_TEST : process.env.VUE_APP_INTERCOM_KEY_PROD;

    (function () {
      const w = window
      const ic = w.Intercom
      if (typeof ic === 'function') {
        ic('reattach_activator')
        ic('update', w.intercomSettings)
      } else {
        const d = document
        const i = function () {
          i.c(arguments)
        }
        i.q = []
        i.c = function (args) {
          i.q.push(args)
        }
        w.Intercom = i
        const l = function () {
          setTimeout(() => {
            const s = d.createElement('script')
            s.type = 'text/javascript'
            s.async = true
            s.src = 'https://widget.intercom.io/widget/' + INTERCOM_KEY
            const x = d.getElementsByTagName('script')[0]
            x.parentNode.insertBefore(s, x)
          }, 4000)
        }
        if (d.readyState === 'complete') {
          l()
        } else if (w.attachEvent) {
          w.attachEvent('onload', l)
        } else {
          w.addEventListener('load', l, false)
        }
      }
    }
    )()

    setTimeout(() => {
      window.Intercom('boot', {
        app_id: INTERCOM_KEY
      })
    }, 1000)
  }
}

export function hideIntercomLauncher () {
  window.Intercom('update', { hide_default_launcher: true })
}

export function showIntercomLauncher () {
  window.Intercom('update', { hide_default_launcher: false })
}
