<template>
  <div>
    <!-- Allows scrolling to error on long forms -->
    <div :class="{ 'error-scroll': validations.$error }"/>

    <!-- Label -->
    <div :class="{'text-danger font-weight-bold': validations.$error}">
      <slot name="label">
        <h5 v-if="label">
          {{ label }}
        </h5>
      </slot>
    </div>

    <!-- Button Radios -->
    <div v-if="buttons">
      <div
        class="row"
        :class="rowClass">
        <base-radio
          v-for="option in parsedOptions"
          :key="option.id"
          v-model="_modelValue"
          class="col"
          :col-class="colClass"
          :disabled-value="option.disabled"
          :initial-value="option.value"
          :name="name"
          :text="option.text"
          button/>
      </div>
    </div>

    <!-- Custom Radios -->
    <div v-else-if="custom">
      <base-radio
        v-for="option in parsedOptions"
        :key="option.id"
        v-model="_modelValue"
        :initial-value="option.value"
        :name="name"
        :text="option.text"
        custom>
        <template #customOption="{ active }">
          <div>
            <slot
              name="customOption"
              :active="active"
              :option="option"/>
          </div>
        </template>
      </base-radio>
    </div>

    <!-- Regular Radios -->
    <div
      v-else
      class="regular-radios">
      <base-radio
        v-for="option in parsedOptions"
        :key="option.id"
        v-model="_modelValue"
        :initial-value="option.value"
        :name="name"
        :text="option.text"
        :sequin="sequin"/>
    </div>

    <base-errors :validations="validations"/>
  </div>
</template>

<script>
import BaseRadio from './BaseRadio.vue'
import BaseErrors from './BaseErrors.vue'

export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  },
  components: {
    BaseRadio,
    BaseErrors
  },
  props: {
    modelValue: {
      validator: v => typeof v === 'string' || v === null || v === undefined,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    buttons: {
      type: Boolean,
      default: false
    },
    colClass: {
      type: String,
      default: ''
    },
    rowClass: {
      type: String,
      default: ''
    },
    sequin: {
      type: Boolean,
      default: false
    },
    custom: {
      type: Boolean,
      default: false
    },
    validations: {
      type: Object,
      default: () => {
        return { $touch () {} }
      }
    }
  },
  emits: ['update:modelValue'],
  computed: {
    parsedOptions () {
      if (typeof this.options[0] === 'string') {
        return this.options.map(x => {
          return { text: x, value: x }
        })
      }
      return this.options
    },
    _modelValue: {
      get () { return this.modelValue },
      set (v) { this.$emit('update:modelValue', v) }
    },
    name () {
      return String(this._uid)
    }
  }
}
</script>
