<template>
  <SignUpScreen :scrollable="false">
    <FadeTransition>
      <StyleGameStart
        v-if="showStart"
        @click="dismissStart"/>
    </FadeTransition>
    <SignUpProgressBar
      v-if="!isMobile"
      :progress="styleGameProgress"
      class="pt-xs px-md px-lg-0 mx-auto style-game-progress-bar"/>
    <div
      v-if="!hideGame"
      class="style-game-container">
      <StyleGame :style-colors="styleColors"/>
    </div>
    <FadeTransition>
      <StyleGameEnd v-if="hideGame"/>
    </FadeTransition>
  </SignUpScreen>
</template>

<script setup>
import SignUpScreen from '@/views/signUp/SignUpScreen.vue'
import SignUpProgressBar from '@/components/signUp/SignUpProgressBar.vue'
import StyleGame from '@/components/signUp/styleGame/StyleGame.vue'
import StyleGameStart from '@/components/signUp/styleGame/StyleGameStart.vue'
import StyleGameEnd from '@/components/signUp/styleGame/StyleGameEnd.vue'
import useScreenSize from '@shared/composables/screenSize.js'
import { storeToRefs } from 'pinia'
import { useSignUpNavigationStore } from '@/stores/signUpNavigation.js'
import { useSignUpStyleGameStore } from '@/stores/signUpStyleGame'
import { computed, onBeforeMount, onBeforeUnmount, ref } from 'vue'
import FadeTransition from '@shared/components/ADORN/Transitions/FadeTransition.vue'
import useAnalytics from '@shared/composables/analytics.js'
import { useScrollLock } from '@shared/composables/scrollLock.js'

useScrollLock()

const { isMobile } = useScreenSize()
const { styleGameProgress } = storeToRefs(useSignUpNavigationStore())
const signUpStyleGameStore = useSignUpStyleGameStore()
const { fetchStyleGameStyleColors } = signUpStyleGameStore
const {
  signUpStyleColors: styleColors,
  ratedStyleCount,
  favoritedStyleCount,
  dislikedStyleCount,
  isStyleGameComplete
} = storeToRefs(signUpStyleGameStore)

onBeforeMount(() => {
  fetchStyleGameStyleColors()
})

const startHasBeenDismissed = ref(false)
const styleColorsLoaded = computed(() => styleColors.value.length > 0)
const showStart = computed(() => styleColorsLoaded.value && !startHasBeenDismissed.value && styleGameProgress.value.step < 2)
const hideGame = computed(() => styleColorsLoaded.value && isStyleGameComplete.value)

function dismissStart () {
  startHasBeenDismissed.value = true
}

const { track } = useAnalytics()
onBeforeUnmount(() => {
  track('Style Game Ended', {
    totalStyleCount: styleGameProgress.value.totalSteps,
    ratedStyleCount: ratedStyleCount.value,
    favoritedStyleCount: favoritedStyleCount.value,
    dislikedStyleCount: dislikedStyleCount.value
  })
})
</script>

<style lang="scss" scoped>
.style-game-progress-bar {
  max-width: 400px;
  width: 100%;
}
</style>
