<!-- Layout component for Sign Up screens -->
<template>
  <div :class="['sign-up-screen', { 'show-header': showHeader }]">
    <SignUpPageHeader v-if="showHeader"/>
    <div :class="['sign-up-screen-content px-md pb-md', { scrollable }]">
      <div
        v-if="heading || subheading"
        class="d-flex flex-column row-gap-sm text-center pt-xl pb-lg">
        <h2 v-if="heading">
          {{ heading }}
        </h2>
        <p v-if="subheading">
          {{ subheading }}
        </p>
      </div>
      <slot/>
    </div>
    <SignUpPageFooter @next="emit('next', $event)">
      <template #additional-content>
        <slot name="additional-footer-content"/>
      </template>
    </SignUpPageFooter>
  </div>
</template>

<script setup>
import SignUpPageHeader from '@/components/signUp/SignUpPageHeader.vue'
import SignUpPageFooter from '@/components/signUp/SignUpPageFooter.vue'
import { useSignUpNavigationStore } from '@/stores/signUpNavigation.js'
import { computed, onUnmounted } from 'vue'
import { useRoute } from 'vue-router'

defineProps({
  heading: {
    type: String,
    default: null
  },
  subheading: {
    type: String,
    default: null
  },
  scrollable: {
    type: Boolean,
    default: true
  }
})

const { saveStyleProfileChanges } = useSignUpNavigationStore()
onUnmounted(async () => { await saveStyleProfileChanges() })

const emit = defineEmits(['next'])

const route = useRoute()
const showHeader = computed(() => !route.name.startsWith('sign-up'))
</script>

<style lang="scss" scoped>
.sign-up-screen {
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  overflow: hidden;

  &.show-header {
    grid-template-rows: auto 1fr auto;
  }

  .sign-up-screen-content.scrollable {
    overflow-y: auto;
  }
}
</style>
