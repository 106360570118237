import { ref, watch, nextTick, onMounted, onUnmounted } from 'vue'
import useScreenSize from '@shared/composables/screenSize.js'
import { storeToRefs } from 'pinia'
import { useNavBarStore } from '@shared/stores/navBar.js'

export default function useNavBarScrollLock () {
  const { isDesktop } = useScreenSize()
  const navBarStore = useNavBarStore()
  const { isExpanded } = storeToRefs(navBarStore)
  const appContainerTop = ref(null)
  watch(isExpanded, (newValue) => {
    if (newValue) {
      appContainerTop.value = window.scrollY
      document.body.classList.add('scroll-lock')
      if (isDesktop.value) {
        document.body.style.top = `-${appContainerTop.value}px`
      }
    } else {
      document.body.classList.remove('scroll-lock')
      if (isDesktop.value) {
        nextTick(() => {
          window.scrollBy({
            left: 0,
            top: appContainerTop.value,
            behavior: 'instant'
          })
        })
      }
    }
  })
}

export function useScrollLock () {
  onMounted(() => { document.body.classList.add('scroll-lock') })
  onUnmounted(() => { document.body.classList.remove('scroll-lock') })
}
