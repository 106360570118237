<template>
  <div
    class="reviews">
    <closet-reviews-summary
      v-model="filters"
      :style-color="styleColor"
      @ratings-click="onRatingsClick()"/>
    <div
      id="reviews-start"
      class="reviews-container"
      :class="{'reviews-container-desktop' : !isMobile}">
      <div
        v-if="filteredSortedReviews"
        class="pb-5">
        <closet-review
          v-for="(review, index) in filteredSortedReviews"
          :key="'review-' + index"
          :review="review"
          :photo-album-entries="getMemberPhotoAlbumEntriesForReview(review.id)"
          :style-color-id="styleColor.id"/>
      </div>
      <div
        v-else-if="unfilteredReviews"
        :class="{ 'pb-5': !showMoreReviewsVisible }">
        <closet-review
          v-for="(review, index) in pinnedReviews"
          :key="'pinned-review-' + index"
          :review="review"
          :photo-album-entries="getMemberPhotoAlbumEntriesForReview(review.id)"
          :style-color-id="styleColor.id"/>
        <closet-review
          v-for="(review, index) in unfilteredReviews"
          :key="'review-' + index"
          :review="review"
          :photo-album-entries="getMemberPhotoAlbumEntriesForReview(review.id)"
          :style-color-id="styleColor.id"/>
        <div
          v-if="showMoreReviewsVisible"
          class="p-4 d-flex justify-content-center">
          <sequin-button
            variant="primary"
            @click="showMoreReviews">
            {{ loadMoreReviewsText }}
          </sequin-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClosetReviewsSummary from './ClosetReviewsSummary'
import ClosetReview from './review/ClosetReview'
import SequinButton from '../../global/sequin/SequinButton.vue'

import { ClosetMemberPhotoMixin } from '../ClosetMemberPhotoMixin'
import { ReviewMixin } from '../ReviewMixin'
import { mapActions } from 'vuex'

export default {
  components: {
    ClosetReviewsSummary,
    ClosetReview,
    SequinButton
  },
  mixins: [ReviewMixin, ClosetMemberPhotoMixin],
  props: {
    styleColor: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      batchSize: 20,
      writtenReviewsViewing: 2,
      filters: []
    }
  },
  computed: {
    pinnedReviews () {
      return this.styleColor?.expertReviews?.concat(this.styleColor?.clientReviews)
    },
    filteredSortedReviews () {
      return this.styleColor?.filteredSortedReviews
    },
    writtenReviewsCount () {
      return this.styleColor?.writtenReviews?.count
    },
    loadedWrittenReviewsCount () {
      return this.styleColor?.writtenReviews?.results.length
    },
    loadMoreReviewsText () {
      const nextBatch = Math.min(this.batchSize, this.writtenReviewsCount - this.writtenReviewsViewing)
      return `Show ${nextBatch} more reviews`
    },
    showMoreReviewsVisible () {
      return this.writtenReviewsViewing < this.writtenReviewsCount
    },
    unfilteredReviews () {
      const reviews = this.styleColor?.unfilteredReviews?.results
      if (reviews) {
        return reviews.slice(0, Math.min(this.writtenReviewsViewing, this.loadedWrittenReviewsCount))
      } else {
        return []
      }
    }
  },
  methods: {
    ...mapActions('closet', [
      'getReviews'
    ]),
    getMemberPhotoAlbumEntriesForReview (reviewId) {
      return this.memberPhotoAlbumEntries.filter(entry => entry.reviewId === reviewId)
    },
    async showMoreReviews () {
      this.writtenReviewsViewing += this.batchSize
      await this.getReviews({ styleColorId: this.styleColor.id })
    },
    onRatingsClick () {
      this.$emit('ratings-click')
    }
  }
}
</script>

<style lang="scss" scoped>

.reviews {
  background: $origami;
}

.reviews-container {
  background: $origami;
  max-width: 90%;
  margin: 0 auto;
}
.reviews-container-desktop {
  background: $origami;
  max-width: 1120px;
  padding-left: 84px;
  padding-right: 40px;
  margin: 0 auto;
}

</style>
