<!-- PlanCard with an example case -->
<template>
  <div class="d-flex flex-column row-gap-sm">
    <PlanCard
      :plan-id
      :selectable
      @selected="onSelected()"/>
    <BaseAccordion
      v-if="isTabletOrSmaller"
      title="Example Case"
      chevron-placement="right"
      :start-expanded="expanded"
      @on-expand="emit('on-expand')">
      <template #title>
        <h5 class="m-0">
          Example Case
        </h5>
      </template>
      <div>
        <ExampleCaseSection :plan-id="planId"/>
      </div>
    </BaseAccordion>
  </div>
</template>

<script setup>
import PlanCard from '@/components/signUp/PlanCard.vue'
import BaseAccordion from '@shared/components/ADORN/BaseAccordion.vue'
import useScreenSize from '@shared/composables/screenSize.js'
import ExampleCaseSection from './ExampleCaseSection.vue'

const props = defineProps({
  planId: {
    type: String,
    required: true
  },
  selectable: {
    type: Boolean,
    default: true
  },
  expanded: {
    type: Boolean,
    default: false
  }
})

const { isTabletOrSmaller } = useScreenSize()
const emit = defineEmits(['selected', 'on-expand'])

function onSelected () {
  if (props.selectable) {
    emit('selected')
  }
}
</script>

<style scoped lang="scss">
:deep(.details) {
  @include media-desktop {
      height: 197px;
    }
}
</style>
