<template>
  <nav
    v-click-outside="closeNavbar"
    class="navigation"
    :class="{ 'expanded' : isExpanded, 'prospect' : !isAuthenticated }"
    @mouseenter="openNavbar($event)"
    @mouseleave="closeNavbar($event)">
    <div
      class="header"
      :class="{'justify-content-center': isCollapsed && !isTabletOrSmaller && isAuthenticated, 'align-items-center' : isTabletOrSmaller}">
      <div class="logo-section">
        <PlainButton
          v-if="isTabletOrSmaller && !isExpanded"
          class="hamburger-button"
          @click="openNavbar($event)">
          <SvgHamburger
            width="24"
            height="24"/>
        </PlainButton>
        <BaseLink
          :to="homeUrl"
          class="d-flex align-items-center">
          <ArmoireLogo
            v-if="isTabletOrSmaller || isExpanded || !isAuthenticated"
            class="header-logo custom-fade"
            :class="(isTabletOrSmaller && isExpanded) ? 'animate-slide-logo' : 'animate-slide-logo-right'"
            height="32"/>
          <IconOrigamiDress
            v-else
            class="custom-fade text-plum"/>
        </BaseLink>
      </div>
      <div
        v-if="!isTabletOrSmaller && !isAuthenticated"
        class="d-flex align-items-center gap-xl me-2 prospect-nav-list">
        <TrackingLink
          v-for="item in prospectNavItems"
          :key="item.name"
          :to="item.to"
          app-entry-point="homepage"
          :event-label="item.name"
          class="prospect-nav-items text-armor text-button">
          {{ item.name }}
        </TrackingLink>
        <GetStartedButton
          location="Navbar"
          class="me-md"
          text="Start"/>
      </div>
      <div
        v-if="isTabletOrSmaller"
        class="header-right-button-section">
        <PlainButton
          v-if="isExpanded"
          class="px-lg py-md custom-fade"
          @click="closeNavbar($event)">
          <SvgX
            width="24"
            height="24"/>
        </PlainButton>
        <PlainButton
          v-else-if="isAuthenticated"
          @click="openMyCaseFlyout()">
          <MyCaseIcon
            class="custom-fade"
            width="24"
            height="24"/>
        </PlainButton>
        <GetStartedButton
          v-if="!isAuthenticated && isCollapsed"
          location="Mobile Navbar"
          :condensed="isTabletOrSmaller"
          class="me-sm my-xxs"
          text="Start"/>
      </div>
    </div>
    <NavigationMenu v-if="showNavMenu"/>
  </nav>
</template>

<script setup>
import { computed, watch } from 'vue'
import { useNavBarStore } from '@shared/stores/navBar.js'
import ArmoireLogo from '@shared/components/ArmoireLogo.vue'
import IconOrigamiDress from '@shared/components/icons/IconOrigamiDress.vue'
import NavigationMenu from './NavigationMenu.vue'
import PlainButton from '@/components/global/sequin/PlainButton.vue'
import GetStartedButton from '@/homepage/components/GetStartedButton.vue'
import SvgHamburger from '@shared/components/icons/SvgHamburger.vue'
import SvgX from '@shared/components/icons/SvgX.vue'
import BaseLink from '@shared/components/BaseLink.vue'
import useScreenSize from '@shared/composables/screenSize.js'
import useNavigation from '@/composables/navigation.js'
import { storeToRefs } from 'pinia'
import useClient from '@/composables/client'
import MyCaseIcon from '@/components/case/common/MyCaseIcon.vue'
import { useFlyouts } from '@/composables/flyouts'
import TrackingLink from '@shared/components/ADORN/TrackingLink.vue'

const { isAuthenticated } = useClient()
const navBarStore = useNavBarStore()
const { isCollapsed, isExpanded, isPanelOpen } = storeToRefs(navBarStore)
const { collapse, expand } = navBarStore
const { isTabletOrSmaller } = useScreenSize()
const { signedOutNavItems: prospectNavItems } = useNavigation()
const { openMyCaseFlyout } = useFlyouts()

const homeUrl = computed(() => isAuthenticated.value ? '/closet' : '/')

const showNavMenu = computed(() => isTabletOrSmaller.value || isAuthenticated.value)

watch(isTabletOrSmaller, (isTabletOrSmaller) => {
  if (!showNavMenu.value && !isTabletOrSmaller && isExpanded.value) {
    collapse('resize')
  }
})

function openNavbar (event) {
  if (event.type === 'mouseenter' && !isAuthenticated.value) {
    return
  }
  if (event.type === 'mouseenter' && isTabletOrSmaller.value) {
    return
  }
  if (isPanelOpen.value) {
    //  do nothing
  } else {
    expand()
  }
}
function closeNavbar (event) {
  if (event.type === 'mouseleave' && !isAuthenticated.value) {
    return
  }
  if (event.type === 'mouseleave' && isTabletOrSmaller.value) {
    // do nothing
  } else if (event.type === 'mouseleave' && isPanelOpen.value) {
    // do nothing
  } else {
    collapse('mouseleave')
  }
}
</script>

<style scoped lang="scss">
@import "./Navigation.scss";
</style>
