<template>
  <div class="px-md-4 py-4 border-top">
    <div>
      <div class="d-flex flex-column">
        <h4 class="group-header">
          Review + Pay
        </h4>
        <div
          v-if="membershipPlanSelected.prepayment && membershipPlanSelected.defaultPromo
            && membershipPlanSelected.defaultPromo.bonusItemsOnSignup"
          class="order-4">
          <div class="row">
            <div class="col text-start small-text py-1">
              {{ membershipPlanSelected.defaultPromo.bonusItemsOnSignup }} bonus items
            </div>
            <div class="col text-end small-text py-1 ">
              FREE
            </div>
          </div>
        </div>

        <div
          v-for="(lineItem, index) in planQuote.lineItems"
          :key="index">
          <div
            class="row order-6">
            <div
              class="col text-start small-text py-1"
              :class="{ 'text-success': lineItem.amount < 0 }">
              {{ lineItem.description }}
              <base-tooltip
                v-if="lineItem.amount < 0"
                max-width="300">
                <template #trigger>
                  <svg-circle-icon
                    class="ms-2"
                    :height="16"
                    :width="16">
                    <svg-question-mark/>
                  </svg-circle-icon>
                </template>
                <template #content>
                  Your prorated credit is based on the number of days remaining in your current billing cycle
                </template>
              </base-tooltip>
            </div>
            <div
              class="col text-end small-text py-1"
              :class="{ 'text-success': lineItem.amount < 0 }">
              {{ formatPrice(lineItem.amount) }}
            </div>
          </div>
        </div>
        <div
          v-if="membershipPlanSelected.prepayment && membershipPlanSelected.defaultPromo && membershipPlanSelected.defaultPromo.dollarAmount"
          class="row order-7">
          <div class="col text-start small-text py-1 text-success-light semi-bold d-flex align-items-center">
            {{ membershipPlanSelected.defaultPromo.promoCode }}
          </div>
          <div class="col text-end small-text py-1 text-success-light">
            -{{ formatPrice(membershipPlanSelected.defaultPromo.dollarAmount) }}
          </div>
        </div>
        <div
          v-if="planQuote.credit"
          class="row order-6">
          <div class="col text-start small-text py-1 text-success">
            Credit
          </div>
          <div class="col text-end small-text py-1 text-success">
            {{ formatPrice(planQuote.credit) }}
          </div>
        </div>
        <div
          v-if="planQuote.gcRedeem || planQuote.activatedGiftCardAmount"
          class="row order-6">
          <div class="col text-start small-text py-1 text-success">
            Gift card amount
          </div>
          <div class="col text-end small-text py-1 text-success">
            {{ formatPrice(planQuote.gcRedeem || planQuote.activatedGiftCardAmount) }}
          </div>
        </div>
        <div
          v-if="planQuote.plan.appliedPromo"
          class="row order-6">
          <div class="col text-start small-text py-1 text-success text-uppercase">
            {{ planQuote.plan.appliedPromo.promoCode }}
          </div>
          <div class="col text-end small-text py-1 text-success">
            {{ formatPrice(planQuote.plan.appliedPromo.dollarAmount) }}
          </div>
        </div>
        <div
          v-for="(gc, index) in planQuote.validGiftCards"
          :key="index"
          class="row order-6">
          <div class="col text-start small-text py-1 text-success text-uppercase">
            {{ gc.code }}
          </div>
          <div class="col text-end small-text py-1 text-success">
            {{ formatPrice(gc.value) }}
          </div>
        </div>
        <div
          class="row order-6">
          <div class="col text-start small-text py-1">
            Sales Tax
          </div>
          <div class="col text-end small-text py-1">
            {{ taxDisplay }}
          </div>
        </div>

        <hr
          class="w-100 order-7">

        <div
          class="row mb-4 order-8">
          <div class="col-5 text-start small-text semi-bold">
            <span v-if="dueToday">
              Due today
            </span>
            <span v-else>
              Due on your next billing date ({{ toLocaleDateString(planQuote.due) }})
            </span>
            <br>
          </div>
          <div class="col-7 text-end">
            <div
              v-if="membershipPlanSelected.prepayment && membershipPlanSelected.defaultPromo && membershipPlanSelected.defaultPromo.dollarAmount"
              class="small-text semi-bold">
              {{ formatPrice(planQuote.chargeAmount - membershipPlanSelected.defaultPromo.dollarAmount) }}
            </div>
            <div
              v-else
              class="small-text semi-bold">
              {{ planQuote.chargeAmount > 0 ? formatPrice(planQuote.chargeAmount) : formatPrice(0) }}
            </div>
          </div>
        </div>

        <div
          v-if="planQuote.remainingCredit"
          class="row order-9">
          <div class="col text-start semi-bold small-text py-1">
            Remaining account credit
          </div>
          <div class="col text-end semi-bold small-text py-1">
            {{ formatPrice(planQuote.remainingCredit) }}
          </div>
        </div>
      </div>
      <slot/>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { toLocaleDateString, formatPrice } from '@/utils/stringParsing.js'

import BaseTooltip from '../global/BaseTooltip'
import SvgCircleIcon from '@/components/global/svg/SvgCircleIcon.vue'
import ReferralsMixin from '../global/mixins/ReferralsMixin'
import SvgQuestionMark from '@/components/global/svg/SvgQuestionMark.vue'

export default {
  components: {
    BaseTooltip,
    SvgCircleIcon,
    SvgQuestionMark
  },
  mixins: [ReferralsMixin],
  computed: {
    ...mapState('client', [
      'membershipStatus'
    ]),
    ...mapState('subscribe', [
      'membershipPlanQuotes'
    ]),
    ...mapGetters('subscribe', [
      'membershipPlanSelected'
    ]),
    dueToday () {
      const today = new Date()
      const dueDate = new Date(Date.parse(this.planQuote.due))
      return dueDate.getDate() === today.getDate() &&
        dueDate.getMonth() === today.getMonth() &&
        dueDate.getFullYear() === today.getFullYear()
    },
    planQuote () {
      return this.membershipPlanQuotes[this.membershipPlanSelected.id]
    },
    taxDisplay () {
      if (this.planQuote.tax === null || this.planQuote.tax === 0) { return '---' }
      return '$' + (this.planQuote.tax / 100).toFixed(2)
    }
  },
  methods: {
    toLocaleDateString,
    formatPrice
  }
}
</script>
