import { useUserAgent } from '@shared/composables/userAgent.js'

export function useAppStoreLinks () {
  const { userAgent } = useUserAgent()
  const platforms = [
    {
      id: 'ios',
      link: 'https://apps.apple.com/us/app/armoire-style/id1560230748',
      icon: `${process.env.VUE_APP_WEBSITE_IMAGES_URL}app-store.png`,
      alt: 'Download on the App Store'
    },
    {
      id: 'android',
      link: 'https://play.google.com/store/apps/details?id=style.armoire.armoiremobile',
      icon: `${process.env.VUE_APP_WEBSITE_IMAGES_URL}google-play.png`,
      alt: 'Get it on Google Play'
    }
  ]
  const showApple = ['web', 'ios'].includes(userAgent.value)
  const showAndroid = ['web', 'android'].includes(userAgent.value)

  return { platforms, showApple, showAndroid }
}
