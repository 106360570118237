/* eslint-disable vue/this-in-template */
<template>
  <div class="d-flex justify-content-center text-center mt-5">
    <div class="px-md-4">
      <div class="d-flex justify-content-center mb-4">
        <svg-circle-icon
          :height="36"
          :width="36"
          class="success-icon"
          variant="success">
          <svg-check/>
        </svg-circle-icon>
      </div>
      <h2
        class="mb-3 thanks-msg"
        :class="{'mobile flex-wrap': isMobile}">
        Thanks for your feedback, {{ firstName }}!
      </h2>
      <review-success-referral-link
        v-if="showReferralLinkMsg"/>
      <sequin-button
        type="router-link"
        :to="{ name: 'closet-sections' }"
        class="my-4">
        Browse My Closet
      </sequin-button>
      <h4
        class="review-success-subtitle">
        or select an additional item to review today
      </h4>
      <div class="grid row mt-xl">
        <div class="wrap d-flex flex-wrap col-8 m-auto p-0 gap-lg">
          <div
            v-for="review in awaitingReviewItems"
            :key="review.id"
            :onClick="() => reviewItem(review)"
            class="card-wrapper p-sm">
            <additional-review-card
              compact
              :item-style="itemStyle(review.item.styleColor)"
              :image-source="imageSource(review.item.styleColor)"
              :size="review.item.trueSize"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgCircleIcon from '../global/svg/SvgCircleIcon'
import SvgCheck from '@/components/global/svg/SvgCheck.vue'
import { mapState } from 'vuex'
import SequinButton from '../global/sequin/SequinButton'
import ReviewSuccessReferralLink from './ReviewSuccessReferralLink'
import AdditionalReviewCard from './AdditionalReviewCard'
import { vuexAccessors } from '../global/helpers/vuex'
import { useReviewStore } from '@/stores/review.js'

export default {
  name: 'ReviewSuccess',
  components: {
    SequinButton,
    SvgCircleIcon,
    SvgCheck,
    ReviewSuccessReferralLink,
    AdditionalReviewCard
  },
  setup () {
    const { navigateToItemReview } = useReviewStore()
    return {
      navigateToItemReview
    }
  },
  computed: {
    ...mapState('client', [
      'firstName',
      'id'
    ]),
    ...mapState('review', [
      'clientReviewsSummary'
    ]),
    ...mapState('closet', [
      'awaitingReviewItems',
      'styleColorsMap'
    ]),
    ...vuexAccessors('review', [
      'reviewItems'
    ]),
    ...vuexAccessors('review', [
      'showReferralLinkMsg'
    ])
  },
  mounted () {
    this.$store.commit('review/CLEAR_REVIEWS')
    this.reviewItems = []
  },
  beforeUnmount () {
    this.showReferralLinkMsg = false
  },
  methods: {
    itemStyle (styleColorId) {
      return this.styleColorsMap[styleColorId].style
    },
    imageSource (styleColorId) {
      return this.styleColorsMap[styleColorId].images.front.url
    },
    reviewItem (review) {
      this.reviewItems = [review]
      this.navigateToItemReview(review)
    }
  }
}
</script>

<style lang="scss" scoped>
  .success-icon {
    box-shadow: 0 0 0 6px lighten($success-light, 20%);
  }
  .thanks-msg {
    &.mobile {
      font-size: $font-size-base;
      font-weight: $font-weight-semibold;
      line-height: $h6-line-height;
    }
  }
  .card-wrapper {
    border: .5px solid $ash;
    border-radius: 2px;
    min-width: 405px;
    min-height: 105px;
    flex-basis: calc(50% - divide($spacing-lg, 2))
  }

  .review-success-subtitle {
    color: $armor;
    font-size: 18px;
    font-family: Montserrat;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.90px;
    word-wrap: break-word
  }
</style>
