<template>
  <div
    class="review-container mt-3 border-bottom justify-content-between"
    :class="{'review-container-desktop' : !isMobile}">
    <div class="review-content row m-0 px-3 justify-content-between">
      <div
        class="p-0"
        :class="{'col-3 me-3' : !isMobile}">
        <div
          v-if="isClient || review.expertReview"
          class="mb-3">
          <sequin-tag
            v-if="isClient"
            variant="primary">
            My Review
          </sequin-tag>
          <sequin-tag
            v-if="review.expertReview"
            variant="success"
            light>
            Expert Review
          </sequin-tag>
        </div>

        <div
          v-if="isMobile"
          class="mb-3">
          <div
            class="d-flex justify-content-between">
            <div
              class="d-flex align-items-center">
              <plain-button
                v-if="!reviewClientIsPrivate"
                class="p-0 "
                type="router-link"
                :to="communityProfileLink">
                <closet-review-avatar
                  :review="review"/>
              </plain-button>
              <plain-button
                v-if="!reviewClientIsPrivate"
                class="p-0 "
                type="router-link"
                :to="communityProfileLink">
                <h4
                  class="member-name mb-0">
                  {{ reviewClientDisplayName }}
                </h4>
              </plain-button>
              <h4
                v-else
                class="member-name mb-0">
                {{ reviewClientDisplayName }}
              </h4>
            </div>
            <closet-review-star-rating
              :review="review"/>
          </div>
          <div>
            <closet-review-height-bust
              :review="review"/>
            <div
              class="pb-2">
              <sequin-tag
                v-if="isHeightMatch"
                variant="tertiary"
                class="me-2 mt-1">
                Height Match
              </sequin-tag>
              <sequin-tag
                v-if="isExactBustMatch"
                variant="tertiary"
                class="me-2 mt-1">
                Bust Match
              </sequin-tag>
              <sequin-tag
                v-if="isExactSizeMatch && isMobile"
                variant="tertiary"
                class="mt-1">
                Size Match
              </sequin-tag>
            </div>
          </div>
          <closet-review-size-list
            class="fit-content-width"
            :review="review"/>
        </div>
        <div v-else>
          <div
            v-if="reviewClientIsPrivate"
            class="d-flex align-items-center mb-2">
            <closet-review-avatar
              :review="review"/>
            <h4
              class="member-name mb-0">
              {{ reviewClientDisplayName }}
            </h4>
          </div>
          <plain-button
            v-else
            class="d-flex align-items-center p-0 mb-2 fit-content-width"
            type="router-link"
            :to="communityProfileLink">
            <closet-review-avatar
              :review="review"/>
            <h4
              class="member-name mb-0">
              {{ reviewClientDisplayName }}
            </h4>
          </plain-button>

          <closet-review-height-bust
            :review="review"/>
          <div class="mt-2">
            <sequin-tag
              v-if="isHeightMatch"
              variant="tertiary"
              class="me-2">
              Height Match
            </sequin-tag>
            <sequin-tag
              v-if="isExactBustMatch"
              variant="tertiary"
              class="me-2">
              Bust Match
            </sequin-tag>
          </div>
          <closet-review-size-list
            :review="review"
            :is-exact-size-match="isExactSizeMatch"/>
        </div>
        <closet-review-action-buttons
          v-if="!isMobile"
          :review="review"
          :style-color-id="styleColorId"/>
      </div>
      <div class="col p-0">
        <div class="review-feedback-text">
          <div
            v-if="!isMobile "
            class="d-flex justify-content-between mb-3">
            <closet-review-star-rating
              :review="review"/>
            <p
              class="fine-print">
              {{ toMonthDayString(review.created, 'short') }}
            </p>
          </div>

          <p class="feedback-text pb-3">
            {{ review.feedbackText }}
          </p>
          <div
            v-if="styleColor.additionalColors.length > 0"
            class="pb-3">
            Color rented:
            <text-link
              v-if="styleColorId != review.styleColorId"
              class="py-0"
              @click="onColorClick(review.styleColorId)">
              {{ review.color }}
            </text-link>
            <span
              v-else>
              {{ review.color }}
            </span>
          </div>
        </div>
        <div
          v-if="review.occasionTags.length > 0"
          :class="{'mb-2' : isMobile}">
          <p class="mb-2">
            Member wore this:
          </p>
          <div class="d-flex flex-wrap">
            <sequin-tag
              v-for="(tag) in review.occasionTags"
              :key="tag.id"
              variant="tertiary"
              class="me-2 mb-2 filter-button">
              {{ tag.tag }}
            </sequin-tag>
          </div>
        </div>
      </div>

      <div
        v-if="!isMobile"
        class="d-flex justify-content-end pe-0 col-3">
        <closet-review-photo-gallery
          v-if="review.images.length"
          :style-color-id="styleColorId"
          :entries="photoAlbumEntries"/>
      </div>
      <div
        v-if="review.images.length && isMobile"
        class="d-flex justify-content-end mt-3 mb-4 w-100">
        <closet-photo-scroll
          :style-color-id="styleColorId"
          :entries="photoAlbumEntries"/>
      </div>
      <div
        v-if="isMobile"
        class="w-100 d-flex justify-content-between align-items-center">
        <p
          class="fine-print whitespace-no-wrap">
          {{ toMonthDayString(review.created) }}
        </p>

        <closet-review-action-buttons
          :review="review"
          :style-color-id="styleColorId"/>
      </div>
    </div>
  </div>
</template>

<script>
import { ReviewMixin } from '../../ReviewMixin'
import { toMonthDayString } from '@/utils/stringParsing.js'
import SequinTag from '../../../global/sequin/SequinTag'
import ClosetReviewPhotoGallery from '../ClosetReviewPhotoGallery'
import ClosetReviewHeightBust from './ClosetReviewHeightBust.vue'
import { mapActions, mapState } from 'vuex'
import { vuexAccessors } from '../../../global/helpers/vuex'
import ClosetReviewAvatar from './ClosetReviewAvatar.vue'
import ClosetReviewStarRating from './ClosetReviewStarRating.vue'
import ClosetReviewSizeList from './ClosetReviewSizeList.vue'
import ClosetPhotoScroll from '../../ClosetPhotoScroll.vue'
import ClosetReviewActionButtons from './ClosetReviewActionButtons.vue'
import PlainButton from '../../../global/sequin/PlainButton.vue'
import TextLink from '../../../global/sequin/TextLink'

export default {
  components: {
    SequinTag,
    ClosetReviewPhotoGallery,
    ClosetReviewHeightBust,
    ClosetReviewAvatar,
    ClosetReviewStarRating,
    ClosetReviewSizeList,
    ClosetPhotoScroll,
    ClosetReviewActionButtons,
    PlainButton,
    TextLink
  },
  mixins: [
    ReviewMixin,
    ClosetPhotoScroll,
    ClosetReviewStarRating
  ],
  props: {
    review: {
      type: Object,
      required: true
    },
    photoAlbumEntries: {
      type: Array,
      default: () => []
    },
    showReviewActions: {
      type: Boolean,
      default: true
    },
    styleColorId: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState('client', [
      'id',
      'styleProfileComplete',
      'loaded',
      'isStaff',
      'isAuthenticated'
    ]),
    ...mapState('closet', [
      'styleColorsMap'
    ]),
    ...mapState('styleProfile', [
      'braSizeBand',
      'braSizeCup'
    ]),
    ...vuexAccessors('review', [
      'reviewForEditPhotos'
    ]),
    isClient () {
      return this.review.clientId === this.id
    },
    isHeightMatch () {
      return this.styleProfileComplete && this.review.sizing?.heightDistance <= 0.5
    },
    isExactSizeMatch () {
      return this.styleProfileComplete && this.review.sizing?.sizeDistance === 0
    },
    usersBustSize () {
      return this.braSizeBand + this.braSizeCup
    },
    isExactBustMatch () {
      return this.usersBustSize === this.review.clientBustSize
    },
    communityProfileLink () {
      const reviewClientId = this.review.clientId
      return { name: 'profile', params: { id: reviewClientId } }
    },
    styleColor () {
      return this.styleColorsMap[this.styleColorId]
    }
  },
  mounted () {
  },
  methods: {
    ...mapActions('closet', [
      'rateReview'
    ]),
    toMonthDayString,
    onColorClick (styleColorId) {
      this.$router.push({
        name: 'closet-detail',
        params: { id: styleColorId, name: this.detailPageSlug(this.styleColorsMap[styleColorId]) }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .review-container {
    margin: 0;
    font-size: 14px;
    padding: 1.5em 0;
    border-radius: 4px;
    background: var(--White, #FFF);
    box-shadow: 0px 1px 8px 0px rgba(23, 23, 35, 0.10);

  }
  .review-container-desktop {
    padding: 2.125em 0;
  }
  .member-name {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: $orchid;
  }
  .feedback-text {
    font-size: 16px;
  }
  .filter-button {
    white-space: nowrap;
    cursor: default;
  }
</style>
