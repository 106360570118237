import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useGenericContentStore } from '@shared/stores/genericContent.js'
import { useClosetStore } from '@shared/stores/closet.js'

export const useHomepageStore = defineStore('homepage', () => {
  const genericContentStore = useGenericContentStore()
  const closetStore = useClosetStore()

  const initialDataLoaded = computed(() => {
    return closetStore.initialSectionsLoaded && genericContentStore.initialSectionsLoaded
  })

  async function loadInitialData () {
    await genericContentStore.loadInitialData()
    await closetStore.loadInitialData()
  }
  const showMobileFooter = ref(null)

  return {
    initialDataLoaded,
    loadInitialData,
    showMobileFooter
  }
})
