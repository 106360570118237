import useVuexModule from '@shared/composables/vuexModule.js'
import { until } from '@vueuse/core'
import { defineStore } from 'pinia'

// TODO: Partial implementation (ENG-2845)
export const useCaseStore = defineStore('case', () => {
  const vuex = useVuexModule('case', {
    defaults: {
      state: [
        ['caseLoading', true]
      ]
    }
  })

  const { caseLoading } = vuex

  async function untilCaseLoaded () {
    await until(() => caseLoading.value).toBe(false)
  }

  return {
    ...vuex,
    untilCaseLoaded
  }
})
