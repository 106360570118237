<template>
  <table class="returns-table">
    <thead>
      <tr class="row py-3 ms-0 me-0">
        <th
          v-for="header in headers"
          :key="header"
          class="col text-gray semi-bold">
          {{ header }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(returnPackage, index) in returnPackages"
        :key="returnPackage.trackingNumber"
        class="row px-2 py-3 ms-0 me-0 border-top align-items-center"
        :class="{ 'bg-origami': index % 2 === 0 }">
        <td class="col">
          {{ toMonthDayYearString(returnPackage.objectCreated) }}
        </td>
        <td class="col">
          <return-status
            :tracking-status="returnPackage.trackingStatus"/>
        </td>
        <td class="col">
          <span
            v-if="returnPackage.trackingStatus === 'DELIVERED'"
            class="text-success font-italic small-text">
            Received at our warehouse
            <br>
            on {{ toMonthDayYearString(returnPackage.objectUpdated) }}
          </span>
          <sequin-button
            v-else
            variant="secondary"
            type="a"
            :href="returnPackage.trackingUrlProvider">
            {{ returnPackage.trackingStatus === 'FAILURE' ? 'Details' : 'Track Return' }}
          </sequin-button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { toMonthDayYearString } from '@/utils/stringParsing.js'
import SequinButton from '../../global/sequin/SequinButton'
import ReturnStatus from './ReturnStatus'

export default {
  name: 'ReturnsList',
  components: { SequinButton, ReturnStatus },
  props: {
    returnPackages: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      headers: [
        'Label generated on',
        'Return status',
        'Tracking information'
      ]
    }
  },
  methods: {
    toMonthDayYearString
  }
}
</script>
