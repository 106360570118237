<template>
  <div class="d-flex justify-content-center pt-lg">
    <Transition
      name="fade"
      mode="out-in">
      <BeTheBuyer v-if="hasBeTheBuyerStylesToRate"/>
      <UpcomingStyles v-else-if="hasUpcomingStylesToRate"/>
      <div
        v-else
        class="d-flex flex-column">
        <h4 class="marketing text-center mb-md">
          Style Games
        </h4>
        <div class="d-flex flex-column flex-grow-1 justify-content-center">
          <p
            v-if="loaded"
            class="background-highlight p-xl text-center">
            You can view the items you liked in your
            <RouterLink :to="routeLocation">
              Favorites
            </RouterLink>
            collection.
          </p>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useFavoritesSection } from '@/composables/closetSection.js'
import BeTheBuyer from '@/components/styleGames/BeTheBuyer.vue'
import UpcomingStyles from '@/components/styleGames/UpcomingStyles.vue'

const store = useStore()

const hasBeTheBuyerStylesToRate = computed(() => store.getters['styleGames/hasBeTheBuyerStylesToRate'])
const hasUpcomingStylesToRate = computed(() => store.getters['styleGames/hasUpcomingStylesToRate'])

const { loaded, routeLocation } = useFavoritesSection({ loadOnMount: true })
</script>

<style lang="scss" scoped>
.background-highlight {
  background-color: $champagne;
}
</style>
