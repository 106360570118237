<template>
  <div
    class="style-game-intro p-2 p-md-4"
    :class="{ 'fade-out': fadeOutIntro }">
    <div class="col-12">
      <div class="row">
        <div
          class="offset-md-2 col-md-1">
          <back-button
            :variant="isMobile ? 'inverse' : 'default' "
            class="back-button"
            aria-label="Back button"
            :with-border="!isMobile"
            :class="{'mobile': isMobile}"
            :back-default-route="{ name: 'closet-sections' }"/>
        </div>
        <div class="col-md-6 d-flex flex-column align-items-center">
          <p class="text-uppercase">
            {{ subhead }}
          </p>
          <h4 class="text-center pb-1 marketing">
            {{ title }}
          </h4>
          <div class="text-center pb-4 description">
            <slot name="description"/>
          </div>
          <div
            v-if="!stylesLoaded || numStylesToRate > 0"
            class="image-container-frame mt-4 cursor-pointer"
            :class="{ 'mobile': isMobile }"
            @click="onStart">
            <Vue3Lottie
              :animation-data="introAnimation"
              auto-play
              :loop="false"
              :height="120"
              :width="120"/>
            <preview-image
              :src="introImageSource"
              class="intro-image"
              :size="isMobile ? 'x-large' : 'xx-large'"/>
            <div
              v-if="numStylesToRate > 1"
              class="circle-bg">
              +{{ numStylesToRate - 1 }}
            </div>
          </div>
          <sequin-button
            v-if="numStylesToRate > 0"
            class="mt-5 intro-button"
            :aria-label="buttonText"
            :class="{ 'mobile': isMobile }"
            variant="secondary"
            @click="onStart">
            {{ buttonText }}
          </sequin-button>
          <div class="text-center mt-4">
            {{ footerText }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackButton from '../global/sequin/BackButton'
import { Vue3Lottie } from 'vue3-lottie'
import SequinButton from '../global/sequin/SequinButton'
import PreviewImage from '@shared/components/ADORN/PreviewImage.vue'

import goBack from '../global/mixins/goBack'

export default {
  components: {
    BackButton,
    Vue3Lottie,
    SequinButton,
    PreviewImage
  },
  mixins: [
    goBack
  ],
  props: {
    subhead: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    buttonText: {
      type: String,
      required: true
    },
    footerText: {
      type: String,
      default: ''
    },
    stylesLoaded: {
      type: Boolean,
      default: false
    },
    introImageSource: {
      type: String || null,
      default: null
    },
    introAnimation: {
      type: Object,
      required: true
    },
    numStylesToRate: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      fadeOutIntro: false
    }
  },
  methods: {
    onStart () {
      if (this.numStylesToRate > 0) {
        this.fadeOutIntro = true
        setTimeout(() => {
          this.$emit('start-game')
        }, 450)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../global/sequin/StyleImage/StyleImage.scss';
@import './StyleGamesStyles.scss';
</style>
