<template>
  <div :class="isMobile ? 'row' : 'container'">
    <action-header
      v-if="isMobile"
      show-confirm
      no-navigation
      :show-back-button="false"
      class="col col-12"
      page-header
      @confirm-click="onSkip">
      Optional: Swap Item{{ deliveredReviewedItems.length === 1 ? '' : 's' }}
      <template #confirm>
        <div>
          <p class="text-primary text-underline mb-0">
            Skip
          </p>
        </div>
      </template>
    </action-header>

    <div
      class="col col-12"
      :class="{ 'list-padding': isMobile }">
      <review-select-items-list
        :show-back-button="false"
        :package-items="deliveredReviewedItems"
        :selected-items="selectedItems"
        :title="isMobile ? '' : 'Optional: Swap Items' "
        @selected="onSelectItem">
        <template #headerSlotDesktop>
          <div>
            <text-link
              variant="primary"
              @click="onSkip">
              Skip
            </text-link>
          </div>
        </template>
        <app-message
          class="text-center"
          variant="success"
          :class="{ 'row border-bottom': isMobile, 'w-100': !isMobile }">
          <p class="semi-bold mb-0">
            Thanks for your feedback, {{ firstName }}!
          </p>
          Would you also like to swap the items you just reviewed?
        </app-message>
      </review-select-items-list>
    </div>

    <div
      :class="isMobile
        ? 'col col-12 mobile-button'
        : 'd-flex justify-content-end mt-3 mb-3 me-3'">
      <app-message
        v-if="errors.length"
        class="text-center"
        variant="danger">
        {{ errors.join('. ') }}
      </app-message>
      <sequin-button
        mobile-block
        :variant="selectedItems.length > 0 ? 'primary' : 'secondary'"
        :disabled="isSubmitting"
        @click="submit">
        {{ selectedItems.length === 0 ?
          'Skip Swap for Now' :
          `Swap ${ selectedItems.length > 0 ? selectedItems.length : '' } Item${ selectedItems.length > 1 ? 's' : '' }` }}
      </sequin-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import goBack from '../global/mixins/goBack'
import ReviewSelectItemsList from './ReviewSelectItemsList.vue'
import SequinButton from '../global/sequin/SequinButton.vue'
import ActionHeader from '../global/sequin/ActionHeader.vue'
import AppMessage from '../global/sequin/AppMessage.vue'
import TextLink from '../global/sequin/TextLink.vue'
import { vuexAccessors } from '../global/helpers/vuex'

export default {
  components: {
    ActionHeader,
    AppMessage,
    ReviewSelectItemsList,
    SequinButton,
    TextLink
  },
  mixins: [goBack],
  data () {
    return {
      selectedItems: [],
      errors: [],
      isSubmitting: false
    }
  },
  computed: {
    ...mapState('client', [
      'firstName'
    ]),
    ...vuexAccessors('review', [
      'itemsToSwap',
      'deliveredReviewedItems'
    ])
  },
  mounted () {
    if (this.deliveredReviewedItems.length === 0) {
      this.$router.replace({ name: 'closet-sections' })
    }
    this.selectedItems = this.deliveredReviewedItems.slice()
  },
  methods: {
    ...mapActions('review', [
      'swapItems'
    ]),
    onSkip () {
      this.$router.push({ name: 'review-success' })
    },
    async submit () {
      if (this.selectedItems.length > 0) {
        this.itemsToSwap = this.selectedItems.slice()
        try {
          this.isSubmitting = true
          await this.swapItems()
          this.$router.replace({ name: 'swap-success' })
        } catch (err) {
          this.isSubmitting = false
          this.errors = err
        }
      } else {
        this.onSkip()
      }
    },
    onSelectItem (packageItem) {
      if (this.selectedItems.includes(packageItem)) {
        this.selectedItems.splice(this.selectedItems.indexOf(packageItem), 1)
      } else {
        this.selectedItems.push(packageItem)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .mobile-button {
    padding: 12px;
    border-top: $default-border;
    background-color: $white;
    z-index: 2;
    position: fixed;
    bottom: 0;
    left: 0;
  }

  .list-padding {
    padding-bottom: 128px;
  }
</style>
